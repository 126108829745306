'use strict'

const Web3 = window.ACryptoSWeb3
const Web3Modal = window.ACryptoSWeb3Modal
const WalletConnectProvider = window.ACryptoSWalletConnectProvider

require('promise.any').shim() // needed for android 4 - browserslist doesn't work for this

class Connect extends EventTarget {
  constructor (options) {
    super()
  }

  async init () {
    this._initWeb3Modal()
    this.web3Ago = new Web3('https://arc2.acryptos.com/')
    await this._initPublicRpcs()
    this._initUserRpc() // note this may error out, so no await
  }

  _initWeb3Modal () {
    this.web3Modal = new Web3Modal({
      theme: 'dark',
      network: 'binance', // optional
      cacheProvider: true, // optional
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            rpc: {
              56: 'https://bsc-dataseed.binance.org/'
            },
            network: 'binance',
            chainId: 56
          }
        },
        binancechainwallet: {
          package: true
        }
      }
    })
  }

  handleAccountsChanged (accounts) {
    console.log('accounts', accounts, this.account)
    if (accounts.length === 0 && this.account) {
      delete this.account
      this.dispatchEvent(new CustomEvent('accountChanged'))
    } else if (this.account !== accounts[0]) {
      this.account = accounts[0]
      this.dispatchEvent(new CustomEvent('accountChanged'))
    }
  }

  async _initPublicRpcs () {
    const web3 = await Promise.any(shuffleArray([
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
      'https://bsc-dataseed2.defibit.io/',
      'https://bsc-dataseed3.defibit.io/',
      'https://bsc-dataseed4.defibit.io/',
      'https://bsc-dataseed2.ninicoin.io/',
      'https://bsc-dataseed3.ninicoin.io/',
      'https://bsc-dataseed4.ninicoin.io/',
      'https://bsc-dataseed1.binance.org/',
      'https://bsc-dataseed2.binance.org/',
      'https://bsc-dataseed3.binance.org/',
      'https://bsc-dataseed4.binance.org/'
    ]).slice(0, 3).map(async rpc => {
      const web3 = new Web3(rpc)
      const validProvider = await checkProvider(web3)
      console.log(rpc, validProvider)
      if (validProvider) {
        return web3
      } else {
        throw new Error('invalid')
      }
    }))
    this.publicWeb3 = web3
    this.web3 ||= web3
  }

  async _initUserRpc () {
    if (this.web3Modal.cachedProvider) {
      this.setUserProvider(await this.web3Modal.connect())
    }
  }

  async connect () {
    if (this.web3Modal.cachedProvider) {
      await this.web3Modal.clearCachedProvider()
      if (this.userProvider && this.userProvider.disconnect) await this.userProvider.disconnect()
    }
    this.setUserProvider(await this.web3Modal.connect())
  }

  async setUserProvider (provider) {
    console.log('setUserProvider', provider.chainId)
    const providerWeb3 = new Web3(provider)
    const validProvider = await checkProvider(providerWeb3)
    if (!validProvider) throw new Error('Invalid provider')

    provider.autoRefreshOnNetworkChange = false
    provider.on('chainChanged', handleChainChanged)
    function handleChainChanged (_chainId) {
      window.location.reload()
    }

    this.userProvider = provider

    if (this.web3) {
      this.web3.setProvider(provider)
    } else {
      this.web3 = providerWeb3
    }

    /***********************************************************/
    /* Handle user accounts and accountsChanged (per EIP-1193) */
    /***********************************************************/
    await provider
      .request({ method: 'eth_accounts' })
      .then((accounts) => this.handleAccountsChanged(accounts))
      .catch((err) => {
        // Some unexpected error.
        // For backwards compatibility reasons, if no accounts are available,
        // eth_accounts will return an empty array.
        console.error(err)
      })

    provider.on('accountsChanged', (accounts) => {
      if (provider === this.web3.currentProvider) this.handleAccountsChanged(accounts)
    })
    provider.on('disconnect', () => {
      if (provider === this.web3.currentProvider) this.handleAccountsChanged([])
    })
  }

  send (tx, options = {}, resolveOnSent = false) {
    console.log('Sending tx', tx)
    return new Promise(async (resolve, reject) => {
      tx.send({
        from: this.account,
        // gasPrice: 5e9,
        gasPrice: parseInt(await this.web3.eth.getGasPrice()) + 1,
        ...options
      })
        .on('transactionHash', function (hash) {
          console.log('tx hash', hash)
        // if(resolveOnSent) resolve(hash) //this doesnt work too well, metamask shows the next transaction as erroring
        })
        .catch(e => {
          console.error(e)
          alert(JSON.stringify(e, null, 2))
          reject(e)
        })
        .then(receipt => {
          console.log('tx receipt', receipt)
          // if(!resolveOnSent)
          resolve(receipt)
        })
    })
  }
}

async function checkProvider (web3) {
  const acsC = new web3.eth.Contract([{ constant: !0, inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], payable: !1, stateMutability: 'view', type: 'function' }], '0x4197C6EF3879a08cD51e5560da5064B773aa1d29') // ACS token
  const validProvider = await acsC.methods.name().call().catch(e => e) === 'ACryptoS'
  return validProvider
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray (array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

const connect = new Connect()

export { connect }
