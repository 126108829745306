import { quoter } from './quoter'
import { Config } from './config.js'

import { bn, min, fw, tw, twf, fwp, np } from './bn.js'
import { i18n } from './i18n.js'
'use strict'

export { HTMLAcsStats }

function htmlToElement (html) {
  const template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html
  return template.content.firstChild
}

class HTMLAcsStats {
  template () {
    return `
        <div class="my-4 p-2 bg-light">
          <div class="row text-center">
            <div class="col col-sm-3">
              <div class="text-secondary"><small><span data-i18n="pricePrefix"></span>ACS | ACSI <span data-i18n="price">Price</span>:</small></div>
              <div><a href="https://www.coingecko.com/en/coins/acryptos" target="_blank" class="text-decoration-none"><span class="text-primary" data-acs-price></span></a></div>
              <div><span class="text-primary" data-acsi-price></span></div>
            </div>
            <div class="col">
              <div class="text-secondary"><small><span data-i18n="supply">Supply</span>:</small></div>
              <div class="text-primary" data-acs-supply></div>
              <div class="text-primary" data-acsi-supply></div>
            </div>
            <div class="col">
              <div class="text-secondary"><small><span data-i18n="marketCap">Market Cap</span>:</small></div>
              <div class="text-primary" data-acs-mcap></div>
              <div class="text-primary" data-acsi-mcap></div>
            </div>
            <div class="col">
              <div class="text-secondary"><small>TVL:</small></div>
              <div class="text-primary" data-tvl></div>
            </div>
            <div class="col">
              <div class="text-secondary"><small><span data-i18n="myTvl">My TVL</span>:</small></div>
              <div class="text-success" data-user-tvl></div>
            </div>
          </div>
        </div>
      `
  }

  constructor (connect, tvl, userTvl) {
    this.tvl = tvl
    this.userTvl = userTvl
    this.e = htmlToElement(this.template())
    i18n.translate(this.e)
    this.contractAcs = new connect.web3.eth.Contract(Config.erc20abi, Config.acs)
    this.contractAcsi = new connect.web3.eth.Contract(Config.erc20abi, Config.acsi)

    connect.poller.add({
      target: Config.acs,
      method: () => this.contractAcs.methods.totalSupply(),
      cb: i => this.acsSupply = bn(i)
    })
    connect.poller.add({
      target: Config.acsi,
      method: () => this.contractAcsi.methods.totalSupply(),
      cb: i => this.acsiSupply = bn(i)
    })

    connect.poller.addEventListener('poll', () => this.update())
  }

  async update () {
    this.e.querySelectorAll('[data-tvl]').forEach(j => j.innerHTML = `
        $${fwp(this.tvl(), 0)}
      `)
    this.e.querySelectorAll('[data-acs-supply]').forEach(j => j.innerHTML = `
        ${fwp(this.acsSupply, 0)}
      `)
    this.e.querySelectorAll('[data-acsi-supply]').forEach(j => j.innerHTML = `
        ${fwp(this.acsiSupply, 0)}
      `)
    if (quoter.q(Config.acs, Config.wbnb) && quoter.q(Config.wbnb, Config.busd)) {
      const acsPriceUsd = quoter.q(Config.acs, Config.wbnb).mul(quoter.q(Config.wbnb, Config.busd)).div(bn(1e18))
      const acsiPriceUsd = quoter.q(Config.acsi, Config.wbnb).mul(quoter.q(Config.wbnb, Config.busd)).div(bn(1e18))

      this.e.querySelectorAll('[data-acs-price]').forEach(j => j.innerHTML = `
          $${fwp(acsPriceUsd, 3)}
          <small class="text-secondary">${fwp(quoter.q(Config.acs, Config.wbnb), 4)} BNB<small>
        `)
      this.e.querySelectorAll('[data-acsi-price]').forEach(j => j.innerHTML = `
          $${fwp(acsiPriceUsd, 3)}
          <small class="text-secondary">${fwp(quoter.q(Config.acsi, Config.wbnb), 4)} BNB<small>
        `)

      this.e.querySelectorAll('[data-acs-mcap]').forEach(j => j.innerHTML = `
          $${fwp(this.acsSupply.mul(acsPriceUsd).div(bn(1e18)), 0)}
        `)
      this.e.querySelectorAll('[data-acsi-mcap]').forEach(j => j.innerHTML = `
          $${fwp(this.acsiSupply.mul(acsiPriceUsd).div(bn(1e18)), 0)}
        `)
    }

    await this.updateUser()
  }

  async updateUser () {
    const userTvl = this.userTvl()
    console.log('HTMLAcsStats.updateUser', userTvl.toString())
    this.e.querySelectorAll('[data-user-tvl]').forEach(j => j.innerHTML = userTvl.gtn(0)
      ? `
        $${fwp(userTvl)}
      `
      : `
        --
      `)
  }
}
