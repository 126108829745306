import { Config } from './config.js'

export { HTMLStableSwap }

import { bn, min, fw, tw, twf, fwp, np } from './bn.js'
import { i18n } from './i18n.js'

function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

class HTMLStableSwap {

  constructor(i) {
    this.stableSwap = i
    i.addEventListener('update', () => this.update())
    this.e = htmlToElement(this.template())

    this.e.querySelectorAll('[data-toggle-collapse]').forEach(i => {
      i.onclick = () => {
        this.e.querySelectorAll(`[data-collapse='${i.dataset.toggleCollapse}']`).forEach(i => {
          i.classList.toggle('show')
        })
        return false
      }
    })

    this.e.querySelectorAll('a[data-max]').forEach(i => {
      i.onclick = () => {
        if (!this.account()) return false
        this.stableSwap.updateUser().then(() => {
          this.e.querySelector(`[data-amount='${i.dataset.max}']`).value = fw(this.stableSwap.userBalances[this.token(i.dataset.max).index])
          this.updateSwap()
        })
        return false
      }
    })

    this.e.querySelectorAll('a[data-set-max-slippage]').forEach(i => {
      i.onclick = () => {
        this.e.querySelector(`input[data-max-slippage]`).value = i.dataset.setMaxSlippage
        this.updateSwap()
        return false
      }
    })

    this.e.querySelectorAll('input[data-amount], input[data-max-slippage]').forEach(i => i.addEventListener('input', debounce(e => {
      this.updateSwap()
    }, 500)))

    this.e.querySelectorAll('select[data-coin]').forEach(i => i.addEventListener('change', e => {
      let other = this.e.querySelector(`select[data-coin='${i.dataset.coin == '1' ? '0' : '1'}']`)
      if (other.value == i.value) {
        let index = parseInt(other.value)
        index += index < this.stableSwap.coins.length - 1 ? 1 : -1 //just some stupid code to change the other coin to something else!!!
        other.value = index.toString()
        console.log(other.value)
      }
      this.update()
      this.updateSwap()
    }))


    //SWAP
    this.e.querySelector('form').onsubmit = () => {
      this.e.classList.add('working')
      this.swap()
        .then(i => {
          this.resetAmounts()
          this.e.querySelector('[data-swap-info]').innerHTML = ``
          this.stableSwap.update()
          this.stableSwap.updateUser()
        })
        .finally(i => {
          this.e.classList.remove('working')
        })
      return false
    }

  }

  resetAmounts() {
    this.e.querySelectorAll(`[data-amount]`).forEach(i => i.value = '0')
  }

  account() {
    return this.stableSwap.connect.account
  }

  async swap() {
    return (await this.updateSwap())()
  }

  async update() {
    if (this.stableSwap.userBalances.length > 0) {
      this.e.querySelector(`[data-user-balance='0']`).innerHTML = `
          ${fwp(this.stableSwap.userBalances[this.token(0).index],this.stableSwap.defaultBaseTokenDisplayDecimals)}
        `
    } else {
      this.e.querySelectorAll(`[data-user-balance]`).forEach(i => i.innerHTML = `
        --
      `)
    }

  }

  tokens() {
    return [0, 1].map(i => this.token(i))
  }
  token(i) {
    return {
      index: parseInt(this.e.querySelector(`select[data-coin='${i}']`).value),
      amount: bn(tw(this.e.querySelector(`input[data-amount='${i}']`).value || "0")),
    }
  }

  async updateSwap() {
    console.log('updateSwap')
    let tokens = this.tokens()
    let amountIn = tokens[0].amount
    let amountOut = amountIn.gtn(0) ? await this.stableSwap.getDy(tokens[0].index, tokens[1].index, tokens[0].amount.toString()) : bn(0)
    let amountSmall = bn(1e12) // was 1e6, but needs to be 1e12 as renBTC decimals is only 8
    let dySmall = await this.stableSwap.getDy(tokens[0].index, tokens[1].index, amountSmall.toString())
    let amountOutSmall = tokens[0].amount.mul(dySmall).div(amountSmall)
    this.e.querySelector('input[data-amount="1"]').value = fw(amountOut)

    let maxSlippage = twf(parseFloat(this.e.querySelector(`input[data-max-slippage]`).value)).divn(100)
    let amountOutMin = amountOut.mul(bn(1e18).sub(maxSlippage)).div(bn(1e18))

    let priceImpact = (amountOutSmall.gtn(0) ? parseFloat(fw(amountOut.mul(bn(1e18)).div(amountOutSmall))) - 1 : 0) * 100

    let swapDisabled = false
    if (priceImpact < -2 || !this.account() || amountOut.lten(0)) {
      swapDisabled = true
    }

    this.e.querySelector('button[type="submit"]').disabled = swapDisabled

    this.e.querySelector('[data-swap-info]').innerHTML = `
      <div class="row">
        <div class="col-5 col-sm-5">
        <span data-i18n="priceImpact">Price impact</span>:
        </div>
        <div class="col text-${priceImpact < -0.2 ? priceImpact < -1 ? priceImpact < -2 ? 'danger' : 'warning' : 'secondary' : 'success'}">
          ${np(priceImpact, 4)}%
        </div>
      </div>
      ${priceImpact < -1 ? `
        <div class="alert alert-info my-2" role="alert">
          <span data-i18n="reducePrice">Reduce price impact by swapping a smaller amount.</span>
        </div>
      `: ``}
      ${priceImpact < -2 ? `
        <div class="alert alert-danger my-2" role="alert">
        <span data-i18n="priceAlert">Price impact too high.</span>
        </div>
      `: ``}
      <div class="row text-secondary" style="font-size:0.9em">
        <div class="col-5 col-sm-5">
        <span data-i18n="receiveMin">Receive min.</span>:
        </div>
        <div class="col">
          ${fwp(amountOutMin, this.stableSwap.defaultBaseTokenDisplayDecimals)} ${Config.tokens[this.stableSwap.coins[this.token(1).index]].symbol}
        </div>
      </div>
    `
    if (localStorage.getItem('language')!=='en'){
      i18n.translate()
    }
    return () => this.stableSwap.exchange(tokens[0].index, tokens[1].index, amountIn.toString(), amountOutMin.toString())
  }

  template() {
    return `
      <div class="">
        <div class="bg-white">
          <form>
            <div class="row g-0">
              <div class="col">
                <div class="m-3">
                  <div class="row">
                    <div class="col-5">
                      <select data-coin="0" class="form-select">
                        ${this.stableSwap.coins.map((address, index) => `
                          <option value="${index}">${Config.tokens[address].symbol}</option>
                        `).join('')}
                      </select>
                    </div>
                    <div class="col text-end text-secondary">
                      <small><span data-i18n="available">Available</span>: <a href="#" data-max="0" class="text-secondary"><span data-user-balance="0">--</span></a></small>
                    </div>
                  </div>
                  <div>
                    <input data-amount="0" type="number" class="form-control" required min="0" step="any" value="0" />
                  </div>
                </div>
                <div class="m-3">
                  <div class="row">
                    <div class="col-5">
                      <select data-coin="1" class="form-select">
                        ${this.stableSwap.coins.map((address, index) => `
                          <option ${index == 1 ? 'selected' : ''} value="${index}">${Config.tokens[address].symbol}</option>
                        `).join('')}
                      </select>
                    </div>
                  </div>
                  <div>
                    <input data-amount="1" readonly type="number" class="form-control bg-light" required min="0" step="any" value="0" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 px-3">
                <div data-swap-info class="my-3">
                </div>
                <div class="row">
                  <div class="col">
                  <span data-i18n="maxSlippage">Max. slippage</span> %<br/>
                    <small>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="0.1">0.1%</a>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="0.5">0.5%</a>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="1">1%</a>
                    </small>
                  </div>
                  <div class="col col-sm-4">
                    <input data-max-slippage type="number" id="max-slippage" class="form-control" required min="0" step="any" value="0.5">
                  </div>
                </div>
                <div class="working-hide my-3">
                  <button class="btn btn-outline-primary btn-lg" disabled type="submit"><span data-i18n="swap">Swap</span></button>
                </div>
                <div class="working-show d-none p-2 my-3">
                  <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
                </div>
                <div data-pool-info class="my-3">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    `
  }
}
