{
  "vaults": {
    "0x8888888818b6e5BBdF1F73c5F3A7F73b2454d2cA": {
      "token": "0x44a9cE69eF2a71A9630697CA5CaB3f4aDaF8f90d",
      "strategy": "0x8888888818b6e5BBdF1F73c5F3A7F73b2454d2cA"
    },
    "0x7a2FB04dfc2B2cA052a769C023F43b81e67F6624": {
      "token": "0xa6df26f8d0Fc91CfD22DF8443b7F2F8586E4c391",
      "strategy": "0x5aaA5a2C704CfdCcb44DeA6a6df10E958A8F7A22"
    },
    "0x7679381507af0c8DE64586A458161aa58D3A4FC3": {
      "token": "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
      "strategy": "0xE4D2eD31FeCd197A1857F6619Cb60F5Fcb7F13B7"
    },
    "0x2b66399AD01be47C5aa11C48fDd6DF689DAE929A": {
      "token": "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
      "strategy": "0x8045DB83f60fe9fC8eb67593140eeEB4a71bDF51"
    },
    "0xAB81911e6B884Ee3d85278F0aB8Cf38Eee31A2d1": {
      "token": "0x478d6c9FFa3609Faa1bfc4afc2770447CA327705",
      "strategy": "0xCAD070F54Eb5b9eB3A0d72999C890aDb94F897b4"
    },
    "0xbba26766b17d774F55963a984265B7Cc5604BEa5": {
      "token": "0x46492B26639Df0cda9b2769429845cb991591E0A",
      "strategy": "0xc807d37f69317Fb57CfBE73570B82624F7d560CD"
    },
    "0xf690AE6cf1f93dA3b56425061D37d24046720494": {
      "token": "0x4B067c22A4eCb993f8E0499deabD4E8455948481",
      "strategy": "0x9AB25286E16591b24D5E7DC6542BE2297607cC71"
    },
    "0x5c2b38bD976619CfC8cC3C7CbF47241E06478684": {
      "token": "0x2b30c317ceDFb554Ec525F85E79538D59970BEb0",
      "strategy": "0xd172ab1a73E936790FF3c572B5eC28f1A60aAE5d"
    },
    "0x6C8a596e777a21c45D07173938d588Ca4CA30d8d": {
      "token": "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1",
      "strategy": "0xFA48586C190A4437C8651B93265b2Ba3bE372Eb8"
    },
    "0x6cC0ef907bC1BEED82954Bd0706e177CdC314A9c": {
      "token": "0x8840C6252e2e86e545deFb6da98B2a0E26d8C1BA",
      "strategy": "0x06271D7d07f38C835C9ED0005Cc4c39Bdc107C1D"
    },
    "0x56e407b9fE49c797eDB073c09e4C4AB5c9CA2946": {
      "token": "0x3530F7d8D55c93D778e17cbd30bf9eB07b884f35",
      "strategy": "0xc12a5BA7762bD40f12565971Eba97b1c0333D8c0"
    },
    "0x1e5EA5427492fE93f22b8e4AA27Dc020FF3Da59A": {
      "token": "0x3d94d03eb9ea2D4726886aB8Ac9fc0F18355Fd13",
      "strategy": "0xBe35a497a340D2D10Bf8C16a824AB83D066B935A"
    },
    "0xB89294e5Bf3D7D0afAaEa10DC4Fb6732749e677b": {
      "token": "0xaCAac9311b0096E04Dfe96b6D87dec867d3883Dc",
      "strategy": "0xfe86E0058C5736CbEdf25165660F7A56acCE7d95"
    },
    "0xd9Ab256c17Cb308Eb3E173aE65C8c7165d2aa824": {
      "token": "0x7bfCd2bda87fd2312A946BD9b68f5Acc6E21595a",
      "strategy": "0xed062edF8f82518f67295C64249ebaBf443b59BB"
    },
    "0x8996A30ba6638DAc082c721C9e9499447D982852": {
      "token": "0x2f3899fFB9FdCf635132F7bb94c1a3A0F906cc6f",
      "strategy": "0x2aA23d513E9a9e6bD22862deBB66161Ab49354C5"
    },
    "0x03E0Df5fb4feb9fE0c1AdFe347372B4A654233C1": {
      "token": "0x3B09e13Ca9189FBD6a196cfE5FbD477C885afBf3",
      "strategy": "0x577B47CBA5673C06BE37cB7397f9C906b6a29d51"
    },
    "0x1Ba8b2E5Adc5722245B6e8330139f8b0E727406B": {
      "token": "0x1fD991fb6c3102873ba68a4e6e6a87B3a5c10271",
      "strategy": "0x45427AeEED3c28Ceda37e07ba35aa0cB657119fC"
    },
    "0x883A0D28dbac2E713e87aa2448595438D8016811": {
      "token": "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
      "strategy": "0x20d1D365834852f1FEa2DfAcD5121935F88A6F78"
    },
    "0xf54D438B63C1680B62418339EC2a4C6e4aF6C73A": {
      "token": "0x9f2023116b8562D352E1E01d8F06e31f07b01905",
      "strategy": "0x02bD617fbce209D974d545fa06236aec3651e6f9"
    },
    "0xD574c6e64863C49f31C577d174BBD808e066db75": {
      "token": "0xAa40Dc3EC6aD76Db3254b54443C4531e3dfe6bDb",
      "strategy": "0xbF5E374A109Fc7bA9706d0b6A84257267d87Bd6E"
    },
    "0x5e4993A09609eBCa7F038e3f66c8F97059563690": {
      "token": "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
      "strategy": "0xD180AB2ecaFd424A92beFBAea655177634913fbB"
    },
    "0x471696DcD1D615aFF82F23ed5835d8bcF0eC1F8d": {
      "token": "0xd9252F088E28636695a061280167CF9594c95Eb6",
      "strategy": "0x74c90158aA44AC58502ec7863006D76aCeD72dD9"
    },
    "0x7ABc5868401eA6A46Ea0D114cafAf63879C8CdDa": {
      "token": "0xBA68d6beE4f433630DeE22C248A236c8f6EAe246",
      "strategy": "0xd25EC338be80B6b95c02F1e181e3EC0a08Cda438"
    },
    "0x1B083711acb6C3673CeDDaFFdF5019429B119b8A": {
      "token": "0x1c0276642f2A7cbcf6624d511F34811cDC65212C",
      "strategy": "0xa18fdBCFA0d260664f06729ba8430514D155d4D0"
    },
    "0x8DC707f2a9A9642f635392af34c0E6a712f1D766": {
      "token": "0x82E8F9e7624fA038DfF4a39960F5197A43fa76aa",
      "strategy": "0xB87A7C493Fc3D185a69De8456A8d6C43a1A238Ff"
    },
    "0xD016df56c65D3Af498D316065138B6BB95416507": {
      "token": "0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2",
      "strategy": "0x5260D88b126280ad767a62528E3464E9D41db4f0"
    },
    "0x4951C3567161d3E632c141B74Ac3781cb99aDd67": {
      "token": "0xda28Eb7ABa389C1Ea226A420bCE04Cb565Aafb85",
      "strategy": "0xE8b2e996FBB4D5f1fFD9bdbE5bC36F016B7D2a96"
    },
    "0x027b514B13B17eB123C88F48fedc14676Bbaac78": {
      "token": "0xAf9Aa53146C5752BF6068A84B970E9fBB22a87bc",
      "strategy": "0xaCc5B6101c77d519d2ab27F261c537A8D5fdF660"
    },
    "0x8835D7fCd6e9cF0ada6eD81a0f9D7694efe4ff31": {
      "token": "0x223740a259E461aBeE12D84A9FFF5Da69Ff071dD",
      "strategy": "0x24A87b844fA46008442186f1362a8123B0adA317"
    },
    "0x40a7dd6502828aE78d12F0F05506a64DCFdCDfEd": {
      "token": "0x76AE2c33bcce5A45128eF2060C6280a452568396",
      "strategy": "0x3aa07f5614172a8d2BB2aad03Fd6035bFcFbf9A0"
    },
    "0x12b05c671E30E9C79C50D8629955A7b9e9eFB2eD": {
      "token": "0x577d005912C49B1679B4c21E334FdB650E92C077",
      "strategy": "0x3468bCB09b554420ceaD20C0Df6bbe93d635d0C6"
    },
    "0x9978992538bD3e70c1Aff101dedd50E9E27CfbdE": {
      "token": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "strategy": "0xB918B4609462c13272fBf9BA48Cb71CF35AeE9Fa"
    },
    "0x10137A821fD5aeA332F682F4CBAfC839E4373104": {
      "token": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "strategy": "0x48b02D49c30a0f02C00F97AB74dFE6D8dD96c4dA"
    },
    "0x52525a9d0c562fc7b685efc754f496fEa055c006": {
      "token": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "strategy": "0xB128374f261B19783Faf0C4538518165eac7bE80"
    },
    "0xFcf924f58fda91190b874547E08DFF069C6d5a48": {
      "token": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "strategy": "0x86363A2EF15cDe348F8bE45B3E93c8bCA6db44D9"
    },
    "0x2875a5B002C2F2116590C70B632F6C28A5B3Ef2c": {
      "token": "0x2bCc1FeF8F31A1E4cf8C85f96F00C543b98dA74e",
      "strategy": "0x94c9BF339B26c8d4bB2D8c66fA7a9326BC24Aa71"
    },
    "0x5F9AAb778447010Ee0121E3460738CF8d1AEdF55": {
      "token": "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "strategy": "0x7635C63768C598b138509d91E3CC8b3AF9d1403E"
    },
    "0xCF69F9e5558D1dafeC4373e3B569F53468bF6317": {
      "token": "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "strategy": "0xD88C09ae4c4Cebe3aDf9B3fCfF4ab29FbD2fB11b"
    },
    "0x3a30E2029578056fA2Cf422AfEe048fE07AB0A06": {
      "token": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "strategy": "0x2B8Faf88bdA9168ff86FBbdF9643B6D79B073861"
    },
    "0x0E3E97653fE81D771a250b03AF2b5cf294a6dE62": {
      "token": "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
      "strategy": "0x3B26349AF7aD09c685D5c4d1bDBCc03688634fB8"
    },
    "0x87D5FD97436cd08024553Eeff98498103264A230": {
      "token": "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
      "strategy": "0x942dAEc3e224c058A58004D3262Bf20623511679"
    },
    "0x14B197CA1A5Aef891e86E1EaE7A110c865c7DCec": {
      "token": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      "strategy": "0x6C8eEEa40aBB24041EEFf08bF6e746f1a918aD08"
    },
    "0x17c9bBa4c84116472309d78B18fB038D4F15E0D6": {
      "token": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      "strategy": "0xEA6FbaC74CEE3241Aa5Bc17568d635398c1E08F6"
    },
    "0x5d15D6c40Ec91940E23Bd6419709D334Aa60EaE2": {
      "token": "0x55d398326f99059fF775485246999027B3197955",
      "strategy": "0xe2bcF6979e739C0312427E78369153E1b14Fb030"
    },
    "0x6Fe6762E9314ad80803fef083c8bB16Af435a628": {
      "token": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "strategy": "0x5c8c5852f3837B37b50497EA11397Ec175B5CcAB"
    },
    "0x82B4C3EdCbA9B754F0eA4A9d043F6F943204BCEd": {
      "token": "0x55d398326f99059fF775485246999027B3197955",
      "strategy": "0x4651a54f8E14B1faEAEb5cb2Af1B1Ee3303D7210"
    },
    "0x35cAdD2DAA782556B7fD90A98663BaCDb78d863e": {
      "token": "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "strategy": "0x97aDD6F966e74776DE2a1eB1dDae175D88448D77"
    },
    "0x675361701b15424Ff94B414eA76AEc1f61ab1ed9": {
      "token": "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "strategy": "0x5405c5aFbF0df5EB5339DD61431eE0d519146aA3"
    },
    "0x0395fCC8E1a1E30A1427D4079aF6E23c805E3eeF": {
      "token": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "strategy": "0xE53cDd2Eb4776B232fe066d24822BA98ccee386F"
    },
    "0xf7FFa2F16684834AF27B77fEA1AcbE35ce7af16b": {
      "token": "0xfb6115445Bff7b52FeB98650C87f44907E58f802",
      "strategy": "0xFdECec212E28556f1C29e5261e842b9fcF676F69"
    },
    "0x03E904a729A6E0eB4B675969D3fe51b5392f5C39": {
      "token": "0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B",
      "strategy": "0xE720aF156e3ed714278AC87d92336a418fA196cC"
    },
    "0xFc698dAE6c5B7e38F8Eb8Fc240f23c598d17e5e2": {
      "token": "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
      "strategy": "0x796b87d0feD110d48821D633db310701823663eB"
    },
    "0x7abbCf9Ac11f65955be8e93Ed7ed64B12E34a58E": {
      "token": "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
      "strategy": "0xE4784868701f4DcE7A0E6CEC4FB1B0b58e9BaF39"
    },
    "0xE0303c65fc9Ce79c53228aE1E8cde3b6b8c02F95": {
      "token": "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
      "strategy": "0x5ff576e31D672255891865A47cfA4fbE51801010"
    },
    "0x5C8C857c89AF070078ab4e72Dc0De8768910E5A8": {
      "token": "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
      "strategy": "0x651Fab792B0c56e561AE74Bbf02f46C524315ABd"
    },
    "0x58d35BC513e46Bd80770c06543071F6A563a360B": {
      "token": "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
      "strategy": "0x060b86242471d7244414eD6F21C19B95010B46DB"
    },
    "0x161Afe47561CfCF9603Ef67C44de95114e78D33F": {
      "token": "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
      "strategy": "0xEfbfc05A3ee143d13793972e1Fe789c17c1E5147"
    },
    "0x90F277c402Ea280E70068049fAb1d123bB6CBA16": {
      "token": "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
      "strategy": "0x4e754CE8E31D5B680c38E2DCB52A48430EA86ce2"
    },
    "0xda6E3B30F7628928ADD9f694AC0D97a00536033f": {
      "token": "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
      "strategy": "0xC64D2522D80378b935f922fadF427E4bd9C5B529"
    },
    "0xAc045258edAB47B62E427E2Fa659e3195CeCbdE2": {
      "token": "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
      "strategy": "0xE1895D5935B7C0937B188C9226697fe27D11C467"
    },
    "0xbB08f867dB0FDA40083D7636ef18bB88e592CC1b": {
      "token": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "strategy": "0x7F1BCE47eeaC94a814bf0EEec794ED5F9d2D5E14"
    },
    "0x32Fe1bE67102c10F6f5E798cb24723E738A31943": {
      "token": "0x55d398326f99059fF775485246999027B3197955",
      "strategy": "0xe6d84B61E21E40c76B4F40832EB7a26bDda431eC"
    },
    "0x9b55e223F16E18c4B9D222968d42F055ea0ED3d1": {
      "token": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "strategy": "0x9b55e223F16E18c4B9D222968d42F055ea0ED3d1"
    },
    "0xA4964271b476B0730Acf86DD9f8D270b5E804126": {
      "token": "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "strategy": "0xAE4A006C954c5039211194816cFBEC30e2521BB5"
    },
    "0x532d5775cE71Cb967B78acbc290f80DF80A9bAa5": {
      "token": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      "strategy": "0x316F6488821E08Ba7f44Ae8E8fe86CA1cd0D0F92"
    },
    "0xe9861F3624B5F7012991ba2762CD1eAD4c622FF5": {
      "token": "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0",
      "strategy": "0x4469Ba4bC9dD89812B5c86E5fa9afd80d5789De0"
    },
    "0xAB819564a1Bb4a7F4142fDC1Be07599fD0c8b24A": {
      "token": "0x14016E85a25aeb13065688cAFB43044C2ef86784",
      "strategy": "0xBBF560b6eD08690c0126F0b7B6e2791E6E552835"
    },
    "0xb9B8370BB74337859ee091ebc8Dfb58d94e84f31": {
      "token": "0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b",
      "strategy": "0x4C3e07381A58571c5325c25b0f882F39AAf5De21"
    },
    "0x4E58b693092e33e46A8734B9c4064B82afdcA14f": {
      "token": "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "strategy": "0xc404757f0eF09f9C4dd1144dc8DA296C91c09958"
    },
    "0xB6eB654FBDc697edD73174a19B074BC67c00a0C0": {
      "token": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      "strategy": "0x4bBfc7eFCd146E3dd1916Da99Fd72D4e5b3A55F1"
    },
    "0x1Db2f258E7f403C1f4b1BC47F686aa3E161DC655": {
      "token": "0x7EB5D86FD78f3852a3e0e064f2842d45a3dB6EA2",
      "strategy": "0x76044EF814F04C8c9A90f357404f013fd495bD24"
    },
    "0x32d5B8867B44762d78e80fFcBF6E956E6A35F3a0": {
      "token": "0xD8E2F8b6Db204c405543953Ef6359912FE3A88d6",
      "strategy": "0xe979bA162fBCbe2c778fbeE9728e6fE3C8e02Ff8"
    },
    "0x9Ce0E88c803672CE672b9b9e66c664B81499cE04": {
      "token": "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
      "strategy": "0xfB74C8F2A315c8FaAb8104E0AA5d2a96D6316aAF"
    },
    "0x0551EA09C83FAFF84d83cfB1c75830EB1229fd31": {
      "token": "0x804678fa97d91B974ec2af3c843270886528a9E6",
      "strategy": "0x81031AC36fb2104561c3E00D490855828707da8A"
    },
    "0x08234f020496ccceEB144f9637A566b936b0EE6F": {
      "token": "0x74E4716E431f45807DCF19f284c7aA99F18a4fbc",
      "strategy": "0x96e38EC1190d49fD6B9bD25ac6800d3516C77fFD"
    },
    "0xbe627707f079e32A54d323BE0c61Da02a28bD0bd": {
      "token": "0xF45cd219aEF8618A92BAa7aD848364a158a24F33",
      "strategy": "0x4dd298Dc6c71964fC9CEcceB4120BB436a30e916"
    },
    "0xED3f6a5Fbe2B4Bf4250f5eCD1eEa9768A9E01765": {
      "token": "0xEa26B78255Df2bBC31C1eBf60010D78670185bD0",
      "strategy": "0x695577Ce7A44bC5E2f9948631b4E4E0Bb97A4845"
    },
    "0x54D01f573017A4e61452cb13432e3a59a79C36bf": {
      "token": "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
      "strategy": "0xbf3ec11527B92FC328c6912c51bE29893f2F33B4"
    },
    "0x1231082D043393f8990861521A10BDc911fEDbBe": {
      "token": "0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082",
      "strategy": "0x39731E0b93cB9a6325A9d9FAA3872cC59c9a4343"
    },
    "0x3DCd4c706EA3c36a1173503ad5C40217Bfbc935E": {
      "token": "0x28415ff2C35b65B9E5c7de82126b4015ab9d031F",
      "strategy": "0x14EEf44F834B1ba0B231d4e1D98C1949B69B2257"
    },
    "0xB18Cf14cCD6a32C87783c2010D52aB39fA98A081": {
      "token": "0x824eb9faDFb377394430d2744fa7C42916DE3eCe",
      "strategy": "0x48eC62F1FFBe599a1043A8fB3c778a9FaFf1B474"
    },
    "0xeE9Ccd9d5c8b07Bb9E6bF1Ab17748C737eC35EA0": {
      "token": "0x014608E87AF97a054C9a49f81E1473076D51d9a3",
      "strategy": "0x2D35344224F120ee9779D34C0BA6db40bEA4C83F"
    },
    "0x5e1D648CF00E31b08ede095170B6764B60D06056": {
      "token": "0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC",
      "strategy": "0x07020F1f18c4b55a38F7df39f2d092969808af43"
    },
    "0x67D288C7599F866880e7486F9E29dedA4749bBB8": {
      "token": "0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86",
      "strategy": "0xdbb259e3e97d6dFEa8895ac787bd7BeB32a35637"
    },
    "0xB8C134eFAfda61a906B657A19b37B3d733362C98": {
      "token": "0xB2678C414ebC63c9CC6d1a0fC45f43E249B50fdE",
      "strategy": "0x2B970F19F5c9cD98ED1e670c05F9a4409a7690bB"
    },
    "0x79980898fB7fCC8950335b173Cc5872A96328b5d": {
      "token": "0xFFd4B200d3C77A0B691B5562D804b3bd54294e6e",
      "strategy": "0x7d3aC947D44EfAf9eDAF75071CBb54bEc2310282"
    },
    "0x22E61e0E445f3a5d2675Cbb20f8c03FA3C55BD95": {
      "token": "0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13",
      "strategy": "0x01Bd7C8ff77b5869Ad3f5cE3BA1E072F47Ea43f4"
    },
    "0xB2c1B30689B8A3fD0916B3a3C6135D0226DeCA7c": {
      "token": "0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF",
      "strategy": "0x748944DaaEcd92F889DB445cFbb35313295Db0Ee"
    },
    "0x14cb72a422bE17372108A2b5f7A2837297948745": {
      "token": "0x942b294e59a8c47a0F7F20DF105B082710F7C305",
      "strategy": "0x65F9Fd38dcf661A38927e8e3aE93927A7C4892d1"
    },
    "0x5A330d3F99Ac2Ef00ac5167707D88E9D3D59620c": {
      "token": "0xcAD7019D6d84a3294b0494aEF02e73BD0f2572Eb",
      "strategy": "0xCa8c49063FE969CE20de07AEA942CFb7C09A9A41"
    },
    "0x7d34CD09953E4f30Cb21494A1ee74eb3F03d996a": {
      "token": "0xE6b421a4408c82381b226Ab5B6F8C4b639044359",
      "strategy": "0x70F97CDe011b1f85097F28acE3784E50f379bB4E"
    },
    "0x61E960dC662c798Dc1646219017542612e35aE8B": {
      "token": "0xF23BAD605E94dE0e3B60c9718a43A94A5aF43915",
      "strategy": "0x848230520B25f9e4B4aA3b8D64Fa71Ad110E50cd"
    },
    "0xcDb35CF4DeD79c991eabcbB03fEcBA6D9af0Bf6e": {
      "token": "0xACF47CBEaab5c8A6Ee99263cfE43995f89fB3206",
      "strategy": "0x38DE02145aa1Ddf9Db35B3e8e95fD32e9B744C8e"
    },
    "0xCbA040A994824572c6827d81A6f5715E163C0F64": {
      "token": "0x6D0c831254221ba121fB53fb44Df289A6558867d",
      "strategy": "0xf0214fE67B0D2d8c716c4c851c5be825b4913a61"
    },
    "0x713dA080C8014111F42FBb76423D52F22e38d8DE": {
      "token": "0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C",
      "strategy": "0x91D5b257f2ec9F56ACB00188154dd6532D83ca8b"
    },
    "0x38e3e6973Ba60daADB3bA8B4DF76Ef3a5A8962b0": {
      "token": "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
      "strategy": "0xa72fc606529426a4ee50c339DA4323157117b1af"
    },
    "0xB1Dc4FEe3248362d54E15192EA771E82dfafd5Bd": {
      "token": "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
      "strategy": "0xa5d0C46a8B29a44F099F034D4068921C5bf899c8"
    },
    "0xCFb96FFbE95C0B7129b57eC4D229F8b9eae2d280": {
      "token": "0x16aFc4F2Ad82986bbE2a4525601F8199AB9c832D",
      "strategy": "0x24390dD6769bb8C8B54Af7c47C7e69791228Cb5D"
    },
    "0x8692858d9DAE5C9E48515531E8d62d2c44E4C453": {
      "token": "0xC6b668548aA4A56792e8002A920d3159728121D5",
      "strategy": "0x0B470DcD7F94C94ED7bdf0Fa79F9790A10c03E34"
    },
    "0x28b4b0fe2FCe360AfF812D2d0B9d88c575314Af9": {
      "token": "0x9Aa8C58101B5884b30D12507ca75a6Bd1CB8f71C",
      "strategy": "0x85Ab61c981f8586c90209aBB6C87041D083D9397"
    },
    "0x2883D6d514D2474B942769B981702526897D74D4": {
      "token": "0x168B273278F3A8d302De5E879aA30690B7E6c28f",
      "strategy": "0xb6cb85Be2FD476ED8e5565Ff302e27A7e9A52dEe"
    },
    "0x4939A789b36C058b5b96D3e24588674d81a9EFBD": {
      "token": "0xCAA662aD41a662b81be2AEa5D59EC0697628665F",
      "strategy": "0xc5703607eC56A273691FF0dA8F4b68C5311C8Afd"
    },
    "0x4c6f2643001669842778D8B6C1878E3EbC1Ea377": {
      "token": "0xd63b5CecB1f40d626307B92706Df357709D05827",
      "strategy": "0x7C98214bd7e191Fd16600eFb21f11388cEF32dc5"
    },
    "0xb00B62da1cd28AB88983960487F2902c64c00bc5": {
      "token": "0xef82bD8287dA9700b004657170746968CF5cA04a",
      "strategy": "0xBAEEaE8B1f096EEaA19cB382250ff2C2346d57Ef"
    },
    "0x49C4b95dc2198F2c4c9516834a8AcBFc4b3e4429": {
      "token": "0xF570d6e751976D0d10aa64ACfa829A5ea4a51727",
      "strategy": "0x1F44Ae08A59De577087F63947fd3e5dADC901a86"
    },
    "0x03f52C3612df0dB3c86a4776a20caece8A194f38": {
      "token": "0xC61FB584DAf69Bedf912768AEdc0658B11A1A75C",
      "strategy": "0xE786bd2D84FBfFDCF5deE7153541c3Fa7e906446"
    },
    "0x2C39de04688D71Cd7d502297dEf53E4be0c420B3": {
      "token": "0x70b31Abf9Be826eDc188A15fC35cc6037103a58F",
      "strategy": "0x70b494be824159AeD8708EFA10E01A75517AB972"
    },
    "0x1DA371DC8127b0cdED8D13fF20F062Bb9e02C1a3": {
      "token": "0xB450606703743D557a1c8384Fffe6b941F8f60F4",
      "strategy": "0x14ec92a222588293a77C273f17d9c44bf3cdd0e3"
    },
    "0x627E6506F26f7ae6250667f4F5A494E0e6443ED5": {
      "token": "0x37908620dEf1491Dd591b5a2d16022A33cDDA415",
      "strategy": "0x7877032077adCDC7ef739a17316aC33Bb218531e"
    },
    "0x7Cc9bc059401964E060f027a027FCF039C954C6b": {
      "token": "0xa12128Bbb1C24Fb851d8BA6EC6836f00916712c2",
      "strategy": "0x0E065f1816B000452Bf7AFbA9145eC8dBe6F901A"
    },
    "0xA7fE1ac962E451312208e09A7c894EBa44833E86": {
      "token": "0x89666d026696660e93Bf6edf57B71A68615768B7",
      "strategy": "0x736654BeF068FCc1a1D46a33BdC29ec21A69fEe2"
    },
    "0xC3eF174A704aB413cA81A5eB3f7eB08B535e91C4": {
      "token": "0x0362ba706DFE8ED12Ec1470aB171d8Dcb1C72B8D",
      "strategy": "0x5b7b647793b576D8c38525875c487913982226a8"
    },
    "0x8383661eCF333FCe4Bf51d498D7c94e2a0c7d5AF": {
      "token": "0x13321AcfF4A27f3d2bcA64b8bEaC6e5FdAAAf12C",
      "strategy": "0x98D51537a0b7326385FBA9fA08e84F64162F7e66"
    },
    "0x46E65055Be06AF470384BcFe140b4ec8a160d15f": {
      "token": "0x460b4193Ec4C1a17372Aa5FDcd44c520ba658646",
      "strategy": "0x300B57e8456EB985908792716DB2e8BD4Fb4A1af"
    },
    "0xC34E0597e50A173E3bE8682512aff80D0DFd49f9": {
      "token": "0x4Fd6D315bEf387fAD2322fbc64368fC443F0886D",
      "strategy": "0x46E90e6DBE47f32eF5c3248E60963f7A1BB1D334"
    },
    "0xAd4BBa0Da4889830fa8C9c7B1b04Ab4faa791F6f": {
      "token": "0xE60B4e87645093A42fa9dcC5d0C8Df6E67f1f9d2",
      "strategy": "0xb57068e5A9f2397d502DCa9055adc8B4AE4764E0"
    },
    "0xAdb783Ca0eeDe47d882A8E1C656A1E681a388adA": {
      "token": "0xEf5212aDa83EC2cc105C409DF10b8806D20E3b35",
      "strategy": "0x1eC76e41adee1A40B6042E8333325D14184631E3"
    },
    "0x3d4bECF8C867d0Ca5C40b5c9449b96C481425334": {
      "token": "0x6A97867a4b7Eb7646ffB1F359ad582e9903aa1C2",
      "strategy": "0x59c663a90C5A3Ea38188acD9000e078548Db36CC"
    },
    "0xE47C98A41Ea2f48C17d6B58420CbCeD5E69F5987": {
      "token": "0x44EA47F2765fd5D26b7eF0222736AD6FD6f61950",
      "strategy": "0x51bED7d3297088A88B0485e0e9Abd08E3c275479"
    },
    "0xdFE0E5992dE7Ca8277A40C37ACCDE36dCb9c94C6": {
      "token": "0xCE383277847f8217392eeA98C5a8B4a7D27811b0",
      "strategy": "0xF911313e3627eEe6c50c601D7CEe14CB5Ca2E5fE"
    },
    "0xFd1EfbAe73E0BAF23fB0Fb394480405609B331b6": {
      "token": "0x752E713fB70E3FA1Ac08bCF34485F14A986956c4",
      "strategy": "0x4Ea2B2acf7286f88622c6a667cc91868e09ee07A"
    },
    "0x68fdCd299E33Bee7695eFBc227Ab948Ae29BFc3c": {
      "token": "0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6",
      "strategy": "0xd6D56824Ede8778A3df216BBF1d68dE88077f39d"
    },
    "0xA0753CC49EB66bb4Bc80E8f042A6dE21fc03e5cD": {
      "token": "0x20bCC3b8a0091dDac2d0BC30F68E6CBb97de59Cd",
      "strategy": "0x8cA3e2b58A12621eB7D784866247A69FA46420e1"
    },
    "0xCfbB1A0522e70fA5688c023b37C58e43c9A6398E": {
      "token": "0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd",
      "strategy": "0x9f8A8e69b20B408a626D4aD6Fff676Ad8887A3B1"
    },
    "0x97391c2A035bdCF537c1ce2a70D14fAA3d44317f": {
      "token": "0x1B96B92314C44b159149f7E0303511fB2Fc4774f",
      "strategy": "0xC726e0b619821FeED849Af8ba43d1da6dCa3C403"
    },
    "0x51d6b8A1d3f6b4aEf8bcaECD8EaD7ff2EFDcbB73": {
      "token": "0x70D8929d04b60Af4fb9B58713eBcf18765aDE422",
      "strategy": "0xb643B69AA380D8842f2a03617328A4a60434Fbb1"
    },
    "0xcd630D39F76C12Af11c2Ed9876ccF976C47A08C3": {
      "token": "0x41182c32F854dd97bA0e0B1816022e0aCB2fc0bb",
      "strategy": "0x725462AaEA2fEA77185763B176bc9e2FFD659b0C"
    },
    "0x161A623c27D20d3717ffe279889e50eEb23962c3": {
      "token": "0x7561EEe90e24F3b348E1087A005F78B4c8453524",
      "strategy": "0xbed61125b65f0B733fB786500b85dC150C7252b2"
    },
    "0x1B5ca4cBf6a2f453506fEC365dc0061d8D127Ec9": {
      "token": "0xb5F6f7dAD23132d40d778085D795BD0FD4B859CD",
      "strategy": "0x88206dba3b99C69C82352091893456650e706022"
    },
    "0x55D2AE06B0904d70d091BC32608F37C5FBE375D4": {
      "token": "0x68Ff2ca47D27db5Ac0b5c46587645835dD51D3C1",
      "strategy": "0x2e51800320691fEbf5eD99C9B8bdF494987884D1"
    },
    "0xDAC0c9b3CaccF7e76D2F238663c98fDd9D07F323": {
      "token": "0xBA51D1AB95756ca4eaB8737eCD450cd8F05384cF",
      "strategy": "0xa1227a3a7566176C2bD1f24Ef7b7ed08ff41Ebc4"
    },
    "0xed08BD00c24197f696A58CAdb3Bca9c67d8110A7": {
      "token": "0x4269e7F43A63CEA1aD7707Be565a94a9189967E9",
      "strategy": "0x0257583cA11906058Eb315b1a55DfB086b367f32"
    },
    "0xA20806fB4fC6dC3434Bba7a8587f0efEB0e69584": {
      "token": "0xc639187ef82271D8f517de6FEAE4FaF5b517533c",
      "strategy": "0xC261EF17231Fbf88666dE2AC705D2a48c6eC4333"
    },
    "0xeF015F747dC06672501Ff0F20c3b94F56FA5427F": {
      "token": "0x496a8b716A3A3410B16e71E3c906968CE4488e52",
      "strategy": "0xC0ceB8D9E60A9a68eFf0C13277cAdac3baCD7694"
    },
    "0x15edF148b5d43684075B77EEa866FF833a54d73c": {
      "token": "0xBc765Fd113c5bDB2ebc25F711191B56bB8690aec",
      "strategy": "0x4c6f66b50E3851018a783881c36e58f8F9e3Abd0"
    },
    "0xEC94dC055d478C97E7C434dd6BC34eAb1c527aB3": {
      "token": "0xbCD62661A6b1DEd703585d3aF7d7649Ef4dcDB5c",
      "strategy": "0xD35eBB6C606E853D426323cB990b6A137853717a"
    },
    "0xe427a9688C0d16eA5d29DB0dcC1D6E1e61Ea9908": {
      "token": "0x610e7a287c27dfFcaC0F0a94f547Cc1B770cF483",
      "strategy": "0x70024a3C18F706ab0105135e0B2F8068C145ffEf"
    },
    "0x6200F22041bDA696D3A639aF1ddb62747E384941": {
      "token": "0x34e821e785A93261B697eBD2797988B3AA78ca33",
      "strategy": "0x5aFa584096DeF0012B5e9b0006549598f6aFa5F7"
    },
    "0x93e2e1e384dC298bDEafaEe9751841EA211f2d42": {
      "token": "0x91589786D36fEe5B27A5539CfE638a5fc9834665",
      "strategy": "0x0dA9141b15BB2658652c777724bfAfD3FE02ca13"
    },
    "0xDB335c7c4AD429A1F53971fd4644b599DC631306": {
      "token": "0xbEA35584b9a88107102ABEf0BDeE2c4FaE5D8c31",
      "strategy": "0xA9Cb9BB2C5C90310DCe36aF484B9d22a4EC5e529"
    },
    "0xD7d38dBcC1cF9DF5f644b23eB19DdeA17105ec25": {
      "token": "0xc746337b5F800a0e19eD4eB3bda03FF1401B8167",
      "strategy": "0xA29489BffF0D59E6415A1788E1F3765D18961e57"
    },
    "0x02AaBF12f7C377916BB828BcE3d45c778d919d0e": {
      "token": "0xcBe2cF3bd012e9C1ADE2Ee4d41DB3DaC763e78F3",
      "strategy": "0x316EbfeDbc6378cB8Fb801ad4Dd783B106B58A13"
    },
    "0x2932568487318969BE7593a27dD5d45aB521e50D": {
      "token": "0x36b7D2e5C7877392Fb17f9219efaD56F3D794700",
      "strategy": "0x4e27E8CC49797F728893eaFAA22803C4f538dABf"
    },
    "0xC109d8B9F89Bd939B81Df4Fe47951f9683207102": {
      "token": "0xdC6C130299E53ACD2CC2D291fa10552CA2198a6b",
      "strategy": "0x603CeEf3ef79E986a1B13A1861632Dc759Cf470f"
    },
    "0x2D8483Bc2a9E2723711888532Fd542483F041137": {
      "token": "0x4576C456AF93a37a096235e5d83f812AC9aeD027",
      "strategy": "0x6da30DF49DeB2ce51B599Fcb3eF2ae994Ed96bc0"
    },
    "0x373561f3119353e50F21EE1181dd8749ae8276b9": {
      "token": "0xf64a269F0A06dA07D23F43c1Deb217101ee6Bee7",
      "strategy": "0x795AA6253DA7CAa9c2d38b2f2a471a0505493225"
    },
    "0x2654346a32D4233B3266AF3C5FD7BAE3C571F345": {
      "token": "0xfEc200A5E3adDD4a7915a556DDe3F5850e644020",
      "strategy": "0xb39959836ce4ce54b2EeD83972507f8B4dBb687B"
    },
    "0xa387beD33E0415302614545eAD370c27778B955E": {
      "token": "0x4db28767D1527bA545CA5bbdA1C96a94ED6ff242",
      "strategy": "0x43f452D9e6129786e6C3dbbBa7D90a30aAD0ECF8"
    },
    "0x3679d4C2752bEef8632fd12c45b005ecB2774EF0": {
      "token": "0x17580340F3dAEDAE871a8C21D15911742ec79e0F",
      "strategy": "0xf46Bedbf171Bc7c661D0e793174B2d3EDc11ae2d"
    },
    "0xa1125B756e0ac05ff5d07Cde4D511E1837aADc88": {
      "token": "0xe022baa3E5E87658f789c9132B10d7425Fd3a389",
      "strategy": "0x8AE66a4Dd15019B925092645351f689d374c3C26"
    },
    "0xED51b5c077B71d5B475E30C88B72632fa679fCE3": {
      "token": "0x0392957571F28037607C14832D16f8B653eDd472",
      "strategy": "0x6C38b68Eae0B5bD2dB80698DD4CD99e82e9BB432"
    },
    "0xe9d9f54Ab89F712ABBdbb3C0F63f2D6eDAa3869c": {
      "token": "0x99d865Ed50D2C32c1493896810FA386c1Ce81D91",
      "strategy": "0x7026071102c0C5a5225A8d009ea2c7c4fD30b0cA"
    }
  },
  "pairs": {
    "0x70650826a8c0b0B3b46cd95667Df79d02b66C002": {
      "tokens": [
        "0x888888883BF208d3b1AcD0052a88b9Fd07bA5851",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x4B067c22A4eCb993f8E0499deabD4E8455948481": {
      "tokens": [
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x478d6c9FFa3609Faa1bfc4afc2770447CA327705": {
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xa6df26f8d0Fc91CfD22DF8443b7F2F8586E4c391": {
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x2b30c317ceDFb554Ec525F85E79538D59970BEb0": {
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1"
      ]
    },
    "0x46492B26639Df0cda9b2769429845cb991591E0A": {
      "tokens": [
        "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x8840C6252e2e86e545deFb6da98B2a0E26d8C1BA": {
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xaCAac9311b0096E04Dfe96b6D87dec867d3883Dc": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x3d94d03eb9ea2D4726886aB8Ac9fc0F18355Fd13": {
      "tokens": [
        "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x3530F7d8D55c93D778e17cbd30bf9eB07b884f35": {
      "tokens": [
        "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x7bfCd2bda87fd2312A946BD9b68f5Acc6E21595a": {
      "tokens": [
        "0xAD29AbB318791D579433D831ed122aFeAf29dcfe",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x3B09e13Ca9189FBD6a196cfE5FbD477C885afBf3": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xCC42724C6683B7E57334c4E856f4c9965ED682bD"
      ]
    },
    "0x2f3899fFB9FdCf635132F7bb94c1a3A0F906cc6f": {
      "tokens": [
        "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xd9252F088E28636695a061280167CF9594c95Eb6": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63"
      ]
    },
    "0x9f2023116b8562D352E1E01d8F06e31f07b01905": {
      "tokens": [
        "0x9A78649501BbAAC285Ea4187299471B7ad4ABD35",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xAf9Aa53146C5752BF6068A84B970E9fBB22a87bc": {
      "tokens": [
        "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xAa40Dc3EC6aD76Db3254b54443C4531e3dfe6bDb": {
      "tokens": [
        "0x1fD991fb6c3102873ba68a4e6e6a87B3a5c10271",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xBA68d6beE4f433630DeE22C248A236c8f6EAe246": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739"
      ]
    },
    "0x1c0276642f2A7cbcf6624d511F34811cDC65212C": {
      "tokens": [
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739"
      ]
    },
    "0x82E8F9e7624fA038DfF4a39960F5197A43fa76aa": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x223740a259E461aBeE12D84A9FFF5Da69Ff071dD": {
      "tokens": [
        "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x76AE2c33bcce5A45128eF2060C6280a452568396": {
      "tokens": [
        "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x577d005912C49B1679B4c21E334FdB650E92C077": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
      ]
    },
    "0xda28Eb7ABa389C1Ea226A420bCE04Cb565Aafb85": {
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
      ]
    },
    "0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x55d398326f99059fF775485246999027B3197955"
      ]
    },
    "0x2bCc1FeF8F31A1E4cf8C85f96F00C543b98dA74e": {
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xFb72D7C0f1643c96c197A98E5f36eBcf7597d0E3": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B"
      ]
    },
    "0xCAABda10a3ac99Fc15f5B636Aa18E6B4Fd8db16D": {
      "tokens": [
        "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
        "0x55d398326f99059fF775485246999027B3197955"
      ]
    },
    "0x3720DAD44398f745067b54ea786BB66CB97f68e9": {
      "tokens": [
        "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xfd1ef328A17A8e8Eeaf7e4Ea1ed8a108E1F2d096": {
      "tokens": [
        "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489": {
      "tokens": [
        "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x05faf555522Fa3F93959F86B41A3808666093210": {
      "tokens": [
        "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1": {
      "tokens": [
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x29A4A3D77c010CE100A45831BF7e798f0f0B325D": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xCC42724C6683B7E57334c4E856f4c9965ED682bD"
      ]
    },
    "0xf13e007e181A8F57eD3a4D4CcE0A9ff9E7241CEf": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xfb6115445Bff7b52FeB98650C87f44907E58f802"
      ]
    },
    "0x3147F98B8f9C53Acdf8F16332eaD12B592a1a4ae": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xf307910A4c7bbc79691fD374889b36d8531B08e3"
      ]
    },
    "0xD8E2F8b6Db204c405543953Ef6359912FE3A88d6": {
      "tokens": [
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xfF17ff314925Dff772b71AbdFF2782bC913B3575": {
      "tokens": [
        "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86": {
      "tokens": [
        "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x824eb9faDFb377394430d2744fa7C42916DE3eCe": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD"
      ]
    },
    "0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC": {
      "tokens": [
        "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    }
  },
  "blps": {
    "0x6e5dbC9aa884F06324AD95E51F931DCf029859dc": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x6e5dbc9aa884f06324ad95e51f931dcf029859dc00020000000000000000002a",
      "type": "weighted",
      "weights": [
        "500000000000000000",
        "500000000000000000"
      ],
      "tokens": [
        "0x361C60b7c2828fCAb80988d00D1D542c83387b50",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x894eD9026De37AfD9CCe1E6C0BE7d6b510e3FfE5": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x894ed9026de37afd9cce1e6c0be7d6b510e3ffe5000100000000000000000001",
      "type": "weighted",
      "weights": [
        "300000000000000000",
        "300000000000000000",
        "300000000000000000",
        "100000000000000000"
      ],
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x44a9cE69eF2a71A9630697CA5CaB3f4aDaF8f90d": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x44a9ce69ef2a71a9630697ca5cab3f4adaf8f90d00010000000000000000001a",
      "type": "weighted",
      "weights": [
        "200000000000000000",
        "600000000000000000",
        "200000000000000000"
      ],
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0x888888883BF208d3b1AcD0052a88b9Fd07bA5851",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x7ea9F435c7CcB2eEF266F5366fe13ea6C9F3e245": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x7ea9f435c7ccb2eef266f5366fe13ea6c9f3e245000100000000000000000000",
      "type": "weighted",
      "weights": [
        "333333333333333334",
        "333333333333333333",
        "333333333333333333"
      ],
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xb338a4ABD2e1f7066810ef0a8cdAdBdA3CEF0B92": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xb338a4abd2e1f7066810ef0a8cdadbda3cef0b92000100000000000000000010",
      "type": "weighted",
      "weights": [
        "200000000000000000",
        "200000000000000000",
        "200000000000000000",
        "200000000000000000",
        "200000000000000000"
      ],
      "tokens": [
        "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0xAD29AbB318791D579433D831ed122aFeAf29dcfe",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xCC42724C6683B7E57334c4E856f4c9965ED682bD"
      ]
    },
    "0x8E1D3C611a4C08D6DA2d257Ed119c1144c1DCAd9": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x8e1d3c611a4c08d6da2d257ed119c1144c1dcad9000100000000000000000019",
      "type": "weighted",
      "weights": [
        "250000000000000000",
        "250000000000000000",
        "250000000000000000",
        "250000000000000000"
      ],
      "tokens": [
        "0x308bfaeAaC8BDab6e9Fc5Ead8EdCb5f95b0599d9",
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xFd7B3A77848f1C2D67E05E54d78d174a0C850335"
      ]
    },
    "0xA56202A0F9BE28fc091D80fa0b7aC20e5495b3BF": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xa56202a0f9be28fc091d80fa0b7ac20e5495b3bf00000000000000000000002f",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
        "0x55d398326f99059fF775485246999027B3197955",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        "0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba"
      ]
    },
    "0x37BF06Fc8F3aF767Cc02448460ef5048bDfEEfFd": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x37bf06fc8f3af767cc02448460ef5048bdfeeffd000100000000000000000014",
      "type": "weighted",
      "weights": [
        "400000000000000000",
        "200000000000000000",
        "400000000000000000"
      ],
      "tokens": [
        "0x4FA7163E153419E0E1064e418dd7A99314Ed27b6",
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xaEcf01c5a659d74Dc33C9C922a4458eAB0b13DeA": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xaecf01c5a659d74dc33c9c922a4458eab0b13dea000100000000000000000012",
      "type": "weighted",
      "weights": [
        "200000000000000000",
        "200000000000000000",
        "200000000000000000",
        "200000000000000000",
        "200000000000000000"
      ],
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
        "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
        "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x62672E4496D870ECfE88706d0e02D4f8f9566f38": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x62672e4496d870ecfe88706d0e02d4f8f9566f3800010000000000000000000d",
      "type": "weighted",
      "weights": [
        "300000000000000000",
        "300000000000000000",
        "300000000000000000",
        "100000000000000000"
      ],
      "tokens": [
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0xB09FE1613fE03E7361319d2a43eDc17422f36B09",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x1f01eEc228f2295858EB60E0C9C59d74D3A927BF": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x1f01eec228f2295858eb60e0c9c59d74d3a927bf000100000000000000000011",
      "type": "weighted",
      "weights": [
        "400000000000000000",
        "200000000000000000",
        "400000000000000000"
      ],
      "tokens": [
        "0x32299c93960bB583A43c2220Dc89152391A610c5",
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xbACa526c79B49a66f7F29123a2F4D7c17d083636": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xbaca526c79b49a66f7f29123a2f4d7c17d08363600000000000000000000002e",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0x14016E85a25aeb13065688cAFB43044C2ef86784",
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x292B9e9e1D2B3F30BFc06aBD79a98F3e11665419": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x292b9e9e1d2b3f30bfc06abd79a98f3e11665419000200000000000000000016",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c"
      ]
    },
    "0x86DdC49F66FA166E72e650A72752b43CE23eCBe5": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x86ddc49f66fa166e72e650a72752b43ce23ecbe500020000000000000000000b",
      "type": "weighted",
      "weights": [
        "500000000000000000",
        "500000000000000000"
      ],
      "tokens": [
        "0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x43FE71651928C01306c1941b574f3e2342bfe477": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x43fe71651928c01306c1941b574f3e2342bfe47700010000000000000000000e",
      "type": "weighted",
      "weights": [
        "333333333333333333",
        "333333333333333334",
        "333333333333333333"
      ],
      "tokens": [
        "0x658A109C5900BC6d2357c87549B651670E5b0539",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xd8a01598399b4D90DFD73a78581Ca26bb09Da32B": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xd8a01598399b4d90dfd73a78581ca26bb09da32b00010000000000000000000c",
      "type": "weighted",
      "weights": [
        "333333333333333333",
        "333333333333333334",
        "333333333333333333"
      ],
      "tokens": [
        "0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x79f819fD095F77c595cd892346dD35763Dc63166": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x79f819fd095f77c595cd892346dd35763dc63166000100000000000000000013",
      "type": "weighted",
      "weights": [
        "220000000000000000",
        "120000000000000000",
        "220000000000000000",
        "220000000000000000",
        "220000000000000000"
      ],
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0x67d66e8Ec1Fd25d98B3Ccd3B19B7dc4b4b7fC493",
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xb978Bd521465f4aa58f79FE99Cb081C9772059eE": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xb978bd521465f4aa58f79fe99cb081c9772059ee000200000000000000000003",
      "type": "weighted",
      "weights": [
        "500000000000000000",
        "500000000000000000"
      ],
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
      ]
    },
    "0xC889543EA115D4403d00f8103980B25f70C107d6": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xc889543ea115d4403d00f8103980b25f70c107d600020000000000000000001e",
      "type": "weighted",
      "weights": [
        "500000000000000000",
        "500000000000000000"
      ],
      "tokens": [
        "0x582C12b30F85162Fa393e5DBe2573f9F601f9D91",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xDaAE64e8F4ecB50A9734c38b2fF395721D5fE588": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xdaae64e8f4ecb50a9734c38b2ff395721d5fe588000200000000000000000002",
      "type": "weighted",
      "weights": [
        "500000000000000000",
        "500000000000000000"
      ],
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    }
  },
  "pairsDeprecated": {
    "0x699c58d77027Ae44608808B3863C394A194d76f6": {
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389"
      ]
    },
    "0x2366eC9dDD1eB27506Fa2Ed48Da8f2D9e99ed3c7": {
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x804678fa97d91B974ec2af3c843270886528a9E6": {
      "tokens": [
        "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b": {
      "tokens": [
        "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        "0x55d398326f99059fF775485246999027B3197955"
      ]
    },
    "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0": {
      "tokens": [
        "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xD8E2F8b6Db204c405543953Ef6359912FE3A88d6": {
      "tokens": [
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE": {
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x74E4716E431f45807DCF19f284c7aA99F18a4fbc": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xEa26B78255Df2bBC31C1eBf60010D78670185bD0": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
      ]
    },
    "0x7EB5D86FD78f3852a3e0e064f2842d45a3dB6EA2": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63"
      ]
    },
    "0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082": {
      "tokens": [
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xF45cd219aEF8618A92BAa7aD848364a158a24F33": {
      "tokens": [
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC": {
      "tokens": [
        "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x014608E87AF97a054C9a49f81E1473076D51d9a3": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1"
      ]
    },
    "0x28415ff2C35b65B9E5c7de82126b4015ab9d031F": {
      "tokens": [
        "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86": {
      "tokens": [
        "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF": {
      "tokens": [
        "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x824eb9faDFb377394430d2744fa7C42916DE3eCe": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD"
      ]
    },
    "0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13": {
      "tokens": [
        "0x4B0F1812e5Df2A09796481Ff14017e6005508003",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xB2678C414ebC63c9CC6d1a0fC45f43E249B50fdE": {
      "tokens": [
        "0x78650B139471520656b9E7aA7A5e9276814a38e9",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x942b294e59a8c47a0F7F20DF105B082710F7C305": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb"
      ]
    },
    "0xE6b421a4408c82381b226Ab5B6F8C4b639044359": {
      "tokens": [
        "0x2222227E22102Fe3322098e4CBfE18cFebD57c95",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xFFd4B200d3C77A0B691B5562D804b3bd54294e6e": {
      "tokens": [
        "0x9f589e3eabe42ebC94A44727b3f3531C0c877809",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00": {
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xACF47CBEaab5c8A6Ee99263cfE43995f89fB3206": {
      "tokens": [
        "0xa1faa113cbE53436Df28FF0aEe54275c13B40975",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xF23BAD605E94dE0e3B60c9718a43A94A5aF43915": {
      "tokens": [
        "0x4FA7163E153419E0E1064e418dd7A99314Ed27b6",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x9Aa8C58101B5884b30D12507ca75a6Bd1CB8f71C": {
      "tokens": [
        "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
        "0x9A78649501BbAAC285Ea4187299471B7ad4ABD35"
      ]
    },
    "0xcAD7019D6d84a3294b0494aEF02e73BD0f2572Eb": {
      "tokens": [
        "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x6D0c831254221ba121fB53fb44Df289A6558867d": {
      "tokens": [
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        "0xECa41281c24451168a37211F0bc2b8645AF45092"
      ]
    },
    "0x16aFc4F2Ad82986bbE2a4525601F8199AB9c832D": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x947950BcC74888a40Ffa2593C5798F11Fc9124C4"
      ]
    },
    "0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C": {
      "tokens": [
        "0x14016E85a25aeb13065688cAFB43044C2ef86784",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xd63b5CecB1f40d626307B92706Df357709D05827": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e"
      ]
    },
    "0xC6b668548aA4A56792e8002A920d3159728121D5": {
      "tokens": [
        "0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xef82bD8287dA9700b004657170746968CF5cA04a": {
      "tokens": [
        "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A"
      ]
    },
    "0xCAA662aD41a662b81be2AEa5D59EC0697628665F": {
      "tokens": [
        "0x758d08864fB6cCE3062667225ca10b8F00496cc2",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x168B273278F3A8d302De5E879aA30690B7E6c28f": {
      "tokens": [
        "0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xC61FB584DAf69Bedf912768AEdc0658B11A1A75C": {
      "tokens": [
        "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xB450606703743D557a1c8384Fffe6b941F8f60F4": {
      "tokens": [
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x70b31Abf9Be826eDc188A15fC35cc6037103a58F": {
      "tokens": [
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
      ]
    },
    "0xa12128Bbb1C24Fb851d8BA6EC6836f00916712c2": {
      "tokens": [
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xF570d6e751976D0d10aa64ACfa829A5ea4a51727": {
      "tokens": [
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x0362ba706DFE8ED12Ec1470aB171d8Dcb1C72B8D": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C"
      ]
    },
    "0x89666d026696660e93Bf6edf57B71A68615768B7": {
      "tokens": [
        "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
        "0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9"
      ]
    },
    "0x37908620dEf1491Dd591b5a2d16022A33cDDA415": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8"
      ]
    },
    "0xE60B4e87645093A42fa9dcC5d0C8Df6E67f1f9d2": {
      "tokens": [
        "0x658A109C5900BC6d2357c87549B651670E5b0539",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x13321AcfF4A27f3d2bcA64b8bEaC6e5FdAAAf12C": {
      "tokens": [
        "0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x460b4193Ec4C1a17372Aa5FDcd44c520ba658646": {
      "tokens": [
        "0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x4Fd6D315bEf387fAD2322fbc64368fC443F0886D": {
      "tokens": [
        "0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x44EA47F2765fd5D26b7eF0222736AD6FD6f61950": {
      "tokens": [
        "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xCE383277847f8217392eeA98C5a8B4a7D27811b0": {
      "tokens": [
        "0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x6A97867a4b7Eb7646ffB1F359ad582e9903aa1C2": {
      "tokens": [
        "0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xEf5212aDa83EC2cc105C409DF10b8806D20E3b35": {
      "tokens": [
        "0x5F84ce30DC3cF7909101C69086c50De191895883",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x752E713fB70E3FA1Ac08bCF34485F14A986956c4": {
      "tokens": [
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x41182c32F854dd97bA0e0B1816022e0aCB2fc0bb": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63"
      ]
    },
    "0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6": {
      "tokens": [
        "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x7561EEe90e24F3b348E1087A005F78B4c8453524": {
      "tokens": [
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd": {
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x1B96B92314C44b159149f7E0303511fB2Fc4774f": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xb5F6f7dAD23132d40d778085D795BD0FD4B859CD": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C"
      ]
    },
    "0x20bCC3b8a0091dDac2d0BC30F68E6CBb97de59Cd": {
      "tokens": [
        "0x55d398326f99059fF775485246999027B3197955",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xBc765Fd113c5bDB2ebc25F711191B56bB8690aec": {
      "tokens": [
        "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x4269e7F43A63CEA1aD7707Be565a94a9189967E9": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1"
      ]
    },
    "0x68Ff2ca47D27db5Ac0b5c46587645835dD51D3C1": {
      "tokens": [
        "0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x70D8929d04b60Af4fb9B58713eBcf18765aDE422": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xBA51D1AB95756ca4eaB8737eCD450cd8F05384cF": {
      "tokens": [
        "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xbCD62661A6b1DEd703585d3aF7d7649Ef4dcDB5c": {
      "tokens": [
        "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xc746337b5F800a0e19eD4eB3bda03FF1401B8167": {
      "tokens": [
        "0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xc639187ef82271D8f517de6FEAE4FaF5b517533c": {
      "tokens": [
        "0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x91589786D36fEe5B27A5539CfE638a5fc9834665": {
      "tokens": [
        "0x78650B139471520656b9E7aA7A5e9276814a38e9",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x496a8b716A3A3410B16e71E3c906968CE4488e52": {
      "tokens": [
        "0x9f589e3eabe42ebC94A44727b3f3531C0c877809",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x610e7a287c27dfFcaC0F0a94f547Cc1B770cF483": {
      "tokens": [
        "0x4B0F1812e5Df2A09796481Ff14017e6005508003",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x34e821e785A93261B697eBD2797988B3AA78ca33": {
      "tokens": [
        "0x2222227E22102Fe3322098e4CBfE18cFebD57c95",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xcBe2cF3bd012e9C1ADE2Ee4d41DB3DaC763e78F3": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb"
      ]
    },
    "0xbEA35584b9a88107102ABEf0BDeE2c4FaE5D8c31": {
      "tokens": [
        "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xdC6C130299E53ACD2CC2D291fa10552CA2198a6b": {
      "tokens": [
        "0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x36b7D2e5C7877392Fb17f9219efaD56F3D794700": {
      "tokens": [
        "0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xfEc200A5E3adDD4a7915a556DDe3F5850e644020": {
      "tokens": [
        "0x658A109C5900BC6d2357c87549B651670E5b0539",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x4576C456AF93a37a096235e5d83f812AC9aeD027": {
      "tokens": [
        "0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0x4db28767D1527bA545CA5bbdA1C96a94ED6ff242": {
      "tokens": [
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        "0xECa41281c24451168a37211F0bc2b8645AF45092"
      ]
    },
    "0xe022baa3E5E87658f789c9132B10d7425Fd3a389": {
      "tokens": [
        "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xf64a269F0A06dA07D23F43c1Deb217101ee6Bee7": {
      "tokens": [
        "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
        "0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9"
      ]
    },
    "0x17580340F3dAEDAE871a8C21D15911742ec79e0F": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x947950BcC74888a40Ffa2593C5798F11Fc9124C4"
      ]
    },
    "0x0392957571F28037607C14832D16f8B653eDd472": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8"
      ]
    },
    "0x99d865Ed50D2C32c1493896810FA386c1Ce81D91": {
      "tokens": [
        "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        "0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B"
      ]
    },
    "0x3720DAD44398f745067b54ea786BB66CB97f68e9": {
      "tokens": [
        "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xCAABda10a3ac99Fc15f5B636Aa18E6B4Fd8db16D": {
      "tokens": [
        "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
        "0x55d398326f99059fF775485246999027B3197955"
      ]
    },
    "0x29A4A3D77c010CE100A45831BF7e798f0f0B325D": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xCC42724C6683B7E57334c4E856f4c9965ED682bD"
      ]
    },
    "0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489": {
      "tokens": [
        "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xf13e007e181A8F57eD3a4D4CcE0A9ff9E7241CEf": {
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xfb6115445Bff7b52FeB98650C87f44907E58f802"
      ]
    },
    "0x05faf555522Fa3F93959F86B41A3808666093210": {
      "tokens": [
        "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1": {
      "tokens": [
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    }
  },
  "blpsDeprecated": {
    "0xa22F3E51939488962AA26d42799dF75F72816cE0": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xa22f3e51939488962aa26d42799df75f72816ce0000000000000000000000007",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
        "0x55d398326f99059fF775485246999027B3197955",
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0xEa20aC7B5E6379cEB44ab0E18C5e8A09Da40EaA0": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xea20ac7b5e6379ceb44ab0e18c5e8a09da40eaa000020000000000000000000f",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0x14016E85a25aeb13065688cAFB43044C2ef86784",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x33aD459E413FF93762f69a156c375ADCF6196f12": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x33ad459e413ff93762f69a156c375adcf6196f1200020000000000000000001c",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        "0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba"
      ]
    },
    "0x16f057CFcd681F754e27cb98ac7Ef7B1099F216C": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x16f057cfcd681f754e27cb98ac7ef7b1099f216c000100000000000000000017",
      "type": "weighted",
      "weights": [
        "400000000000000000",
        "100000000000000000",
        "400000000000000000",
        "100000000000000000"
      ],
      "tokens": [
        "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
        "0xF215A127A196e3988C09d052e16BcFD365Cd7AA3"
      ]
    },
    "0x51441Efb4bEeB1694FcC985d522c120286400061": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x51441efb4beeb1694fcc985d522c12028640006100020000000000000000001b",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      ]
    },
    "0x40e030d42f2417624E950E86a6A94195b2D69f54": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x40e030d42f2417624e950e86a6a94195b2d69f54000200000000000000000018",
      "type": "weighted",
      "weights": [
        "500000000000000000",
        "500000000000000000"
      ],
      "tokens": [
        "0x1b219Aca875f8C74c33CFF9fF98f3a9b62fCbff5",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      ]
    },
    "0xFafF81515aC376b1E07F068f527ed8de31437A9F": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xfaff81515ac376b1e07f068f527ed8de31437a9f000100000000000000000008",
      "type": "weighted",
      "weights": [
        "333333333333333334",
        "333333333333333333",
        "333333333333333333"
      ],
      "tokens": [
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        "0xFd7B3A77848f1C2D67E05E54d78d174a0C850335"
      ]
    },
    "0x56C4F0984Ce2c82e340E697210984Fc9b1532eE6": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x56c4f0984ce2c82e340e697210984fc9b1532ee6000100000000000000000005",
      "type": "weighted",
      "weights": [
        "300000000000000000",
        "80000000000000000",
        "200000000000000000",
        "50000000000000000",
        "310000000000000000",
        "60000000000000000"
      ],
      "tokens": [
        "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
        "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
        "0xAEE4164c1ee46ed0bbC34790f1a3d1Fc87796668",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5"
      ]
    },
    "0xDfd7684dbd0C31a302aBaC3a4b62caAdD1235E7F": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0xdfd7684dbd0c31a302abac3a4b62caadd1235e7f000100000000000000000004",
      "type": "weighted",
      "weights": [
        "270000000000000000",
        "80000000000000000",
        "80000000000000000",
        "300000000000000000",
        "270000000000000000"
      ],
      "tokens": [
        "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
        "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
        "0x5F84ce30DC3cF7909101C69086c50De191895883",
        "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63"
      ]
    },
    "0x49b823Bec2d4E89Cf4368bD743de1bC74871af22": {
      "vault": "0xa82f327BBbF0667356D2935C6532d164b06cEced",
      "poolId": "0x49b823bec2d4e89cf4368bd743de1bc74871af22000000000000000000000006",
      "type": "stable",
      "weights": null,
      "tokens": [
        "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        "0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C",
        "0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c"
      ]
    }
  }
}