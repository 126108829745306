'use strict'

import { Config } from './config.js'
import { Vault } from './vault.js'
import { Farm } from './farm.js'
import { HTMLAcsStats } from './html-acs-stats.js'
import { HTMLVault } from './html-vault.js'
import { Pair } from './pair.ts'
import { Blp } from './blp.js'
import { StableSwap } from './stable-swap.js'
import { Poller } from './poller.js'
import { i18n } from './i18n.js'
import { router } from './router.js'

import { bn, fw } from './bn.js'

import ConfigGenerated from './config/generated.json'

class VaultsController {
  static async resolve (filter, connect, options = {}) {
    // console.log(filter)
    this.connect = connect
    this.options = options
    await this.init()

    if (!this.options.deprecated) {
      this.e.querySelectorAll('[data-nav]').forEach(i => i.classList.remove('active'))
      this.e.querySelector(`[data-nav='${filter}']`).classList.add('active')

      this.farmVaults.forEach(farmVault => {
        if (filter === 'all' || filter === 'x' || farmVault.tags.includes(filter)) {
          farmVault.html.e.classList.remove('d-none')
        } else {
          farmVault.html.e.classList.add('d-none')
        }
      })
      if (filter === 'x') {
        this.e.querySelector('#vaults').classList.add('filter-x')
      } else {
        this.e.querySelector('#vaults').classList.remove('filter-x')
      }
    }
  }

  static async init () {
    this.init = () => { }

    this.connect.poller = new Poller(this.connect.web3)
    this.connect.pairPoller = new Poller(this.connect.web3)
    this.connect.initPoller = new Poller(this.connect.web3)
    // this.connect.poller = new Poller(this.connect.web3Ago)
    // this.connect.initPoller = new Poller(this.connect.web3Ago)

    const initBlockNumber = parseInt(await this.connect.web3.eth.getBlockNumber())
    console.log('initBlockNumber', initBlockNumber)
    const daysAgo = 3
    this.connect.agoPoller = new Poller(this.connect.web3Ago, { blockNumber: initBlockNumber - daysAgo * 28800 })
    const daysAgoShort = 1
    this.connect.agoPollerShort = new Poller(this.connect.web3Ago, { blockNumber: initBlockNumber - daysAgoShort * 28800 })

    this.initHTML()

    Object.entries(this.options.deprecated ? ConfigGenerated.pairsDeprecated : ConfigGenerated.pairs).forEach(([address, pair]) => new Pair({ ...pair, address, web3: this.connect.web3, poller: this.connect.pairPoller, agoPoller: this.connect.agoPoller, fromBlock: Config.contractsFromBlock[address] }))
    Object.entries(this.options.deprecated ? ConfigGenerated.blpsDeprecated : ConfigGenerated.blps).forEach(([address, blp]) => new Blp({ ...blp, address, web3: this.connect.web3, poller: this.connect.pairPoller, agoPoller: this.connect.agoPoller, fromBlock: Config.contractsFromBlock[address] }))

    this.farmVaults = Config[this.options.deprecated ? 'farmVaultsDeprecated' : 'farmVaults'].map(i => {
      const farmVault = i
      if (i.farm) {
        i.farm.token ||= i.vault && i.vault.address
        farmVault.farm = new Farm(i.farm, this.connect)
      }
      if (i.vault) {
        i.vault.token ||= ConfigGenerated.vaults[i.vault.address].token
        i.vault.strategy ||= ConfigGenerated.vaults[i.vault.address].strategy
        farmVault.vault = new Vault(i.vault, this.connect)
      }
      if (farmVault.farm && farmVault.vault) {
        farmVault.farm.vault = farmVault.vault
        farmVault.vault.farm = farmVault.farm
      }
      farmVault.html = new HTMLVault({
        farm: farmVault.farm,
        vault: farmVault.vault,
        tags: i.tags
      })
      this.e.querySelector('#vaults').appendChild(farmVault.html.e)

      return farmVault
    })
    if (!this.options.deprecated) {
      Farm.acsVault = this.farmVaults.find(farmVault => farmVault.vault && farmVault.vault.tokenSymbol == 'ACS').vault
      Farm.acsiVault = this.farmVaults.find(farmVault => farmVault.vault && farmVault.vault.tokenSymbol == 'ACSI').vault
      Farm.wavVault = this.farmVaults.find(farmVault => farmVault.vault && farmVault.vault.title == 'TGW Vault').vault
    }

    // to get stableswap prices
    Config.stableSwaps.forEach(i => {
      const ss = new StableSwap(i, this.connect)
      ss.initPricePollers()
    })

    while (this.connect.initPoller.calls.length > 0) {
      console.log('initPoller')
      await this.connect.initPoller.poll({ clearCalls: true })
    }
    // console.log('agoPoller')
    // await this.connect.agoPoller.poll().catch(e => {
    //   console.error(e)
    //   // alert('Error retrieving APY data from RPC. APY data will show as lower than actual, please refresh to try again. App is still fully functional, we are working on a fix.')
    // })
    // console.log('agoPollerShort')
    // await this.connect.agoPollerShort.poll().catch(e => {
    //   console.error(e)
    //   // alert('Error retrieving APY data from RPC. APY data will show as lower than actual, please refresh to try again. App is still fully functional, we are working on a fix.')
    // })

    if (!this.options.deprecated) {
      this.acsStats = new HTMLAcsStats(this.connect, () => this.tvl(), () => this.userTvl())
      document.getElementById('acs-stats').appendChild(this.acsStats.e)
    }

    this.connect.addEventListener('accountChanged', e => this.connect.poller.poll() && this.acsStats && this.acsStats.updateUser())

    console.log('pairPoll')
    await this.connect.pairPoller.poll()
    console.log('poll')
    await this.connect.poller.poll()
    setInterval(() => {
      console.log('pairPoll')
      this.connect.pairPoller.poll()
    }, 66888)
    setInterval(() => {
      console.log('poll')
      this.connect.poller.poll()
    }, 16888)

    // if (Farm.acsVault && Farm.acsVault.stats.vaultBalance.gt(bn(1e18).muln(1000))) {
    //   const postDefistationTimestampHour = parseInt(Date.now() / (60 * 60 * 1000))
    //   if (parseInt(localStorage.getItem('postDefistationTimestampHour')) != postDefistationTimestampHour) {
    //     localStorage.setItem('postDefistationTimestampHour', postDefistationTimestampHour)
    //     console.log('postDefistation', postDefistationTimestampHour)
    //     // limit this to trigger on >1000 acsACS only
    //     await this.postDefistation()
    //   }
    // }
  }

  static initHTML () {
    // init lang
    if (localStorage.getItem('language') !== 'en') {
      i18n.translate()
    }

    const tags0 = [
      ['all'],
      ['core'],
      ['acs', 'ACS'],
      ['acsi', 'ACSI'],
      ['wav', '🌊']
    ]
    const tags1 = [
      // ['venus'],
      // ['channels'],
      // ['atlantis'],
      ['dsg', 'DSG'],
      ['biswap'],
      ['pancake'],
      ['mdex', 'MDEX'],
      ['unifi'],
      ['usd', 'USD'],
      ['x', '⚔️']
    ]

    this.e = htmlToElement(`
      <div>
        <div class="bg-white text-center p-3 mx-auto" style="max-width:38em">
          <h4 style="color:#fd8e34">⚠️ ACryptoS is now Multi-Chain.</h5>
          Use our <a href="https://app.acryptos.com/" target="_blank">new UI</a> for our full selection of Vaults and Farms.
        </div>
        ${this.options.deprecated
        ? `
        <div class="my-3 alert alert-info" role="alert">NOTICE: These Vaults and/or Farms are deprecated, please unstake/withdraw from here and <a href="/">stake in the current Vaults and Farms</a>.</div>
        `
        : ''}
        <div class="my-3">
          <ul class="nav nav-pills justify-content-center">
          ${this.options.deprecated
            ? ''
            : tags0.map(filter => `
              <li class="nav-item">
                <a data-nav="${filter[0]}" class="nav-link" data-navigo href="/${filter[0] == 'all' ? '' : filter[0] + '/'}">${filter[1] || titleize(filter[0])}</a>
              </li>
            `).join('')}
          </ul>
          <ul class="nav nav-pills justify-content-center">
            ${this.options.deprecated
              ? ''
              : tags1.map(filter => `
                <li class="nav-item">
                  <a data-nav="${filter[0]}" class="nav-link" data-navigo href="/${filter[0] == 'all' ? '' : filter[0] + '/'}">${filter[1] || titleize(filter[0])}</a>
                </li>
              `).join('')}
          </ul>
        </div>
        <div id="vaults"></div>
        ${this.options.deprecated
        ? ''
        : `
          <div class="my-3 text-center text-secondary"><small>Can't find your funds? Check the <a href="/deprecated/" class="text-secondary">Deprecated Vaults/Farms</a>.</small></div>
        `}
      </div>
    `)
    document.getElementById('content').appendChild(this.e)
    router.updatePageLinks()

    HTMLVault.header.querySelectorAll('[data-sort-vaults]').forEach(e => {
      e.onclick = () => {
        this.sortVaults(e.dataset.sortVaults)
        return false
      }
    })

    this.e.querySelector('#vaults').appendChild(HTMLVault.header)
  }

  static sortVaults (by) {
    let sortedVaults
    if (this.sortedBy == by) {
      delete this.sortedBy
      sortedVaults = this.farmVaults
    } else {
      this.sortedBy = by
      sortedVaults = [...this.farmVaults]
      sortedVaults.sort((a, b) => {
        return ((b.html[by] || 0) - (a.html[by] || 0))
      })
    }
    sortedVaults.forEach(farmVault => {
      this.e.querySelector('#vaults').appendChild(farmVault.html.e)
    })
  }

  static tvl () {
    const tvl = bn(0)
    this.farmVaults.forEach(farmVault => {
      if (farmVault.vault && farmVault.vault.stats.vaultBalanceUsd) {
        tvl.iadd(farmVault.vault.stats.vaultBalanceUsd)
      } else if (farmVault.farm && farmVault.farm.stats.farmBalanceUsd) {
        tvl.iadd(farmVault.farm.stats.farmBalanceUsd)
      }
    })
    return tvl
  }

  static userTvl () {
    const tvl = bn(0)
    this.farmVaults.forEach(farmVault => {
      if (farmVault.farm && farmVault.farm.stats.userBalanceFarmUsd) {
        tvl.iadd(farmVault.farm.stats.userBalanceFarmUsd)
      }
      if (farmVault.vault && farmVault.vault.stats.userBalanceVaultUsd) {
        tvl.iadd(farmVault.vault.stats.userBalanceVaultUsd)
      }
    })
    return tvl
  }

  static async postDefistation () {
    // console.log('postDefistation',)
    const data = { data: {} }
    const tvl = bn(0)
    const tbl = bn(0)
    this.farmVaults.forEach(farmVault => {
      if (farmVault.vault && farmVault.vault.stats.vaultBalanceUsd) {
        tvl.iadd(farmVault.vault.stats.vaultBalanceUsd)

        let bnbLocked = bn(0)
        if (farmVault.vault.tokenSymbol.endsWith('-BNB')) {
          bnbLocked = farmVault.vault.stats.vaultBalanceBnb.divn(2)
        }
        if (farmVault.vault.tokenSymbol == 'BNB') {
          bnbLocked = farmVault.vault.stats.vaultBalanceBnb
        }

        tbl.iadd(bnbLocked)

        data.data[farmVault.vault.tokenSymbol + ' Vault'] = {
          address: farmVault.vault.address,
          token: farmVault.vault.token,
          tokenSymbol: farmVault.vault.tokenSymbol,
          tvl: fw(farmVault.vault.stats.vaultBalanceUsd),
          bnbLocked: fw(bnbLocked)
        }
      } else if (farmVault.farm && farmVault.farm.stats.farmBalanceUsd) {
        tvl.iadd(farmVault.farm.stats.farmBalanceUsd)

        let bnbLocked = bn(0)
        if (farmVault.farm.tokenSymbol.endsWith('-BNB')) {
          bnbLocked = farmVault.farm.stats.farmBalanceBnb.divn(2)
        }
        if (farmVault.farm.tokenSymbol == 'BNB') {
          bnbLocked = farmVault.farm.stats.farmBalanceBnb
        }

        tbl.iadd(bnbLocked)

        data.data[farmVault.farm.tokenSymbol + ' Farm'] = {
          address: farmVault.farm.master,
          token: farmVault.farm.token,
          tokenSymbol: farmVault.farm.tokenSymbol,
          tvl: fw(farmVault.farm.stats.farmBalanceUsd),
          bnbLocked: fw(bnbLocked)
        }
      }
    })
    data.tvl = parseFloat(fw(tvl))
    data.bnb = parseFloat(fw(tbl))
    // console.log(JSON.stringify(data))

    await fetch('https://api.defistation.io/dataProvider/tvl', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa('ACryptoS:b2b9984e-b98b-4235-bb10-5bd67fa2aa7a')}`
      },
      body: JSON.stringify(data)
    })
  }
}

function titleize (sentence) {
  if (!sentence.split) return sentence
  const _titleizeWord = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  const result = []
  sentence.split(' ').forEach(function (w) {
    result.push(_titleizeWord(w))
  })
  return result.join(' ')
}

function htmlToElement (html) {
  const template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html
  return template.content.firstChild
}

export { VaultsController }
