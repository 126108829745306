import { Config } from './config.js'

export { HTMLStableSwapWithdraw }

import { bn,min,fw,tw,twf,fwp,np } from './bn.js'
import { i18n } from './i18n.js'


function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

class HTMLStableSwapWithdraw {

  constructor(i) {
    this.stableSwap = i
    i.addEventListener('update',() => this.update())
    this.e=htmlToElement(this.template())

    this.e.querySelectorAll('[data-toggle-collapse]').forEach(i => {
      i.onclick = () => {
        this.e.querySelectorAll(`[data-collapse='${i.dataset.toggleCollapse}']`).forEach(i => {
          i.classList.toggle('show')
        })
        return false
      }
    }) 

    this.e.querySelector('a[data-max-lp-token]').onclick = () => {
      if(!this.account()) return false
      this.stableSwap.updateUser().then(() => {
        this.e.querySelector(`input[data-amount-lp-token]`).value=fw(this.stableSwap.userBalanceLpToken)
        this.updateLpToken()
        // this.updateWithdraw()
      })
      return false
    }
    this.e.querySelectorAll('input[data-amount-lp-token]').forEach(i => i.addEventListener('input', debounce(e => {
      this.updateLpToken()
    },500)))
    this.e.querySelectorAll('input[data-amount]').forEach(i => i.addEventListener('input', debounce(e => {
      this.updateAmounts()
    },500)))

    this.e.querySelectorAll('a[data-max]').forEach(i => {
      i.onclick = () => {
        if(!this.account()) return false
        this.stableSwap.updateUser().then(async () => {
          let o = {}
          o.index = i.dataset.max
          o.receiveAmounts = this.stableSwap.coins.map(()=>bn(0))
          o.receiveAmounts[o.index] = await this.stableSwap.calcWithdrawOneCoin(this.stableSwap.userBalanceLpToken,i.dataset.max)
          o.amountLpToken = this.stableSwap.userBalanceLpToken

          this.e.querySelectorAll(`input[data-amount]`).forEach((j,index)=>{
            j.value=fw(o.receiveAmounts[index])
          })
          this.e.querySelector(`input[data-amount-lp-token]`).value=fw(o.amountLpToken)
          this.updateOneCoin(o)
        })
        return false
      }
    }) 

    this.e.querySelectorAll('a[data-set-max-slippage]').forEach(i => {
      i.onclick = () => {
        this.e.querySelector(`input[data-max-slippage]`).value=i.dataset.setMaxSlippage
        this.lastPreUpdateWithdraw && this.lastPreUpdateWithdraw()
        return false
      }
    }) 
    this.e.querySelectorAll('input[data-max-slippage]').forEach(i => i.addEventListener('input', debounce(e => {
        this.lastPreUpdateWithdraw && this.lastPreUpdateWithdraw()
    },500)))



    //DEPOSIT
    this.e.querySelector('form').onsubmit = () => {
      this.e.classList.add('working')
      this.deposit()
        .then(i => {
          this.resetAmounts()
          this.e.querySelector('[data-withdraw-info]').innerHTML = ''
          this.stableSwap.update()
          this.stableSwap.updateUser()
        })
        .finally(i => {
          this.e.classList.remove('working')
        })
      return false
    }
  }

  resetAmounts() {
    this.e.querySelectorAll(`[data-amount-lp-token]`).forEach(i => i.value='0')
    this.e.querySelectorAll(`[data-amount]`).forEach(i => i.value='0')
  }

  account() {
    return this.stableSwap.connect.account
  }

  async deposit() {
    return (await this.lastPreUpdateWithdraw())()
  }

  async update() {
    if(this.stableSwap.userBalanceLpToken) {
      this.e.querySelector(`[data-user-balance-lp-token]`).innerHTML = `
        ${fwp(this.stableSwap.userBalanceLpToken,this.stableSwap.defaultBaseTokenDisplayDecimals)}
      `
    } else {
      this.e.querySelector(`[data-user-balance-lp-token]`).innerHTML = `
        --
      `
    }
  }

  async updateOneCoin(o) {
    o.amountLpTokenUsd = o.amountLpToken.mul(await this.stableSwap.getVirtualPrice()).div(bn(1e18))

    let maxSlippage = twf(parseFloat(this.e.querySelector(`input[data-max-slippage]`).value)).divn(100)
    o.receiveAmountsMin = o.receiveAmounts.map((amount,index) => 
      amount.mul(bn(1e18).sub(maxSlippage)).div(bn(1e18))
    )

    this.lastPreUpdateWithdraw = () => this.updateOneCoin(o)
    this.updateWithdraw(o)

    return () => this.stableSwap.withdrawOneCoin(o.amountLpToken.toString(),o.index,o.receiveAmountsMin[o.index].toString())
  }
  async updateLpToken() {
    let o = {}

    o.amountLpToken = bn(tw(this.e.querySelector(`[data-amount-lp-token]`).value || "0"))
    o.amountLpTokenUsd = o.amountLpToken.mul(await this.stableSwap.getVirtualPrice()).div(bn(1e18))
    o.receiveAmounts = await this.stableSwap.calcRemoveLiquidity(o.amountLpToken)
    o.receiveAmounts.forEach((amount,index) => {
      this.e.querySelector(`input[data-amount='${index}']`).value = fw(amount)
    })

    let maxSlippage = twf(parseFloat(this.e.querySelector(`input[data-max-slippage]`).value)).divn(100)
    o.receiveAmountsMin = o.receiveAmounts.map((amount,index) => 
      amount.mul(bn(1e18).sub(maxSlippage)).div(bn(1e18))
    )

    this.lastPreUpdateWithdraw = this.updateLpToken
    this.updateWithdraw(o)

    return () => this.stableSwap.withdraw(o.amountLpToken.toString(),o.receiveAmountsMin.map(i => i.toString()))
  }
  async updateAmounts() {
    let o = {}

    o.receiveAmounts = this.amounts()
    o.amountLpToken = await this.stableSwap.calcTokenAmount(o.receiveAmounts,false)
    this.e.querySelector(`input[data-amount-lp-token]`).value = fw(o.amountLpToken)
    o.amountLpTokenUsd = o.amountLpToken.mul(await this.stableSwap.getVirtualPrice()).div(bn(1e18))
    let maxSlippage = twf(parseFloat(this.e.querySelector(`input[data-max-slippage]`).value)).divn(100)
    o.amountLpTokenMax = o.amountLpToken.mul(bn(1e18).add(maxSlippage)).div(bn(1e18))

    this.lastPreUpdateWithdraw = this.updateAmounts
    this.updateWithdraw(o)

    return () => this.stableSwap.withdrawImbalance(o.receiveAmounts,o.amountLpTokenMax.toString())
  }

  updateWithdraw(o) {
    o.receiveAmountsTotal = o.receiveAmounts.reduce((a,v)=>a.add(bn(v)),bn(0))
    o.priceImpact = (o.amountLpTokenUsd.gtn(0) ? parseFloat(fw(o.receiveAmountsTotal.mul(bn(1e18)).div(o.amountLpTokenUsd)))-1 : 0)*100

    o.withdrawDisabled = false
    if(o.priceImpact < -2 || !this.account() || o.amountLpToken.lten(0)) {
      o.withdrawDisabled = true
    }

    this.e.querySelector('button[type="submit"]').disabled = o.withdrawDisabled

    this.e.querySelector('[data-withdraw-info]').innerHTML = `
      <div class="row">
        <div class="col-5 col-sm-5">
          <span data-i18n="withdraw">Withdraw</span>:
        </div>
        <div class="col text-primary">
          ${o.receiveAmountsMin ? '':'~'}${fwp(o.amountLpToken,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.lpTokenSymbol}<br/>
          ≃ ${fwp(o.amountLpTokenUsd,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.baseSymbol}
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-sm-5">
          <span data-i18n="receive">Receive</span>:
        </div>
        <div class="col text-primary">
          ${o.receiveAmounts.map((amount,index) => `
            ${o.receiveAmountsMin ? '~':''}${fwp(amount,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${Config.tokens[this.stableSwap.coins[index]].symbol}<br/>
          `).join('')}
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-sm-5">
          <span data-i18n="total">Total</span>:
        </div>
        <div class="col text-primary">
          ${o.receiveAmountsMin ? '~':''}${fwp(o.receiveAmountsTotal,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.baseSymbol}*
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-sm-5">
        <span data-i18n="priceImpact">Price impact</span>:
        </div>
        <div class="col text-${o.priceImpact < -0.2 ? o.priceImpact < -1 ? o.priceImpact < -2 ? 'danger' : 'warning' : 'secondary' : 'success'}">
          ${np(o.priceImpact,4)}%
        </div>
      </div>
      ${o.priceImpact < -1 ? `
        <div class="alert alert-info my-2" role="alert">
          <span data-i18n="reducePriceWithdraw">Reduce price impact by withdrawing more in proportion to the pool's reserves.</span>
        </div>
      `:``}
      ${o.priceImpact < -2 ? `
        <div class="alert alert-danger my-2" role="alert">
          <span data-i18n="priceAlert">Price impact too high.</span>
        </div>
      `:``}
      <div class="row text-secondary" style="font-size:0.9em">
        ${o.receiveAmountsMin ? `
          <div class="col-5 col-sm-5">
          <span data-i18n="receiveMin">Receive min.</span>:
          </div>
          <div class="col">
            ${o.receiveAmountsMin.map((amount,index) => `
              ${fwp(amount,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${Config.tokens[this.stableSwap.coins[index]].symbol}<br/>
            `).join('')}
          </div>
        `:`
          <div class="col-5 col-sm-5">
            <span data-i18n="withdrawMax">Withdraw max.</span>:
          </div>
          <div class="col">
            ${fwp(o.amountLpTokenMax,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.lpTokenSymbol}
          </div>
        `}
      </div>
    `
    if (localStorage.getItem('language')!=='en'){
      i18n.translate()
    }
    return {amountLpToken:o.amountLpToken, receiveAmountsMin:o.receiveAmountsMin}
  }

  amounts() {
    return this.stableSwap.coins.map((address, index) => tw((this.e.querySelector(`input[data-amount='${index}']`).value || "0")))
  }

  isOneCoin() {
    let amounts = this.amounts()
    let coins = amounts.filter(i => bn(i).gtn(0))
    if(coins.length==1) return amounts.indexOf(coins[0])
    return false
  }

  template() {
    return `
      <div class="">
        <div class="bg-white">
          <form>
            <div class="row g-0">
              <div class="col">
                <div class="m-3">
                  <div class="row">
                    <div class="col-4">
                      ${this.stableSwap.lpTokenSymbol}
                    </div>
                    <div class="col text-end text-secondary">
                      <small><span data-i18n="available">Available</span>: <a href="#" data-max-lp-token class="text-secondary"><span data-user-balance-lp-token>--</span></a></small>
                    </div>
                  </div>
                  <div>
                    <input data-amount-lp-token type="number" class="form-control" required min="0" step="any" value="0" />
                  </div>
                </div>
                <hr/>
                ${this.stableSwap.coins.map((address, index) => `
                  <div class="m-3">
                    <div class="row">
                      <div class="col-4">
                        ${Config.tokens[address].symbol}
                      </div>
                      <div class="col text-end text-secondary">
                        <small><a href="#" data-max="${index}" class="text-secondary"><span data-i18n="max">Max.</span></a></small>
                      </div>
                    </div>
                    <div>
                      <input data-amount="${index}" type="number" class="form-control" required min="0" step="any" value="0" />
                    </div>
                  </div>
                `).join('')}
              </div>
              <div class="col-12 col-sm-6 px-3">
                <div data-withdraw-info class="my-3">
                </div>
                <div class="row">
                  <div class="col">
                  <span data-i18n="maxSlippage">Max. slippage</span> %<br/>
                    <small>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="0.1">0.1%</a>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="0.5">0.5%</a>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="1">1%</a>
                    </small>
                  </div>
                  <div class="col col-sm-4">
                    <input data-max-slippage type="number" id="max-slippage" class="form-control" required min="0" step="any" value="0.5">
                  </div>
                </div>
                <div class="working-hide my-3">
                  <button class="btn btn-outline-primary btn-lg" disabled type="submit"><span data-i18n="withdraw">Withdraw</span></button>
                </div>
                <div class="working-show d-none p-2 my-3">
                  <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
                </div>
                <div data-pool-info class="my-3">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    ` 
  }   
}
