'use strict';


export { HTMLVaultWorker }

import { bn, min, fw, tw, twf, fwp, np } from './bn.js'
import { i18n } from './i18n.js'

function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

class HTMLVaultWorker {
  template() {
    return `
      <div class="vault row my-3 g-0 p-2 bg-white">
        <div class="col-sm-8 col-6 row g-0">
          <div class="col-sm-5 col-5 p-2">
            <h2 class="m-3" style="font-size:1.2em">${this.vault.tokenSymbol} Vault</h2>
          </div>
          <div class="col-sm-7 col-12 p-2">
            <div><span data-i18n="reward">Reward</span>: <span data-reward>--</span> ${this.vault.harvesterRewardSymbol || this.vault.tokenSymbol}</div>
            ${this.vault.showBorrowLimit ? `
              <div><span data-i18n="borrowLimit">Borrow Limit</span>: <span data-borrow-limit>--</span>%</div>
            `: ``}
          </div>
        </div>
        <div class="col-sm-4 col-6 text-end">
          <div class="working-hide">
            <button data-harvest class="m-1 btn btn-outline-primary"><span data-i18n="harvest">Harvest</span></button>
          </div>
          <div class="working-show d-none p-2">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
        </div>
      </div>
    `
  }

  constructor(vault) {
    this.vault = vault
    this.vault.addEventListener('update', () => this.update())
    this.e = htmlToElement(this.template())

    this.e.querySelector('button[data-harvest]').onclick = () => this.workAction({
      action: () => this.vault.harvest(),
      update: () => this.vault.update(),
    })


    if (localStorage.getItem('language') !== 'en') {
      i18n.translate()
    }
  }

  workAction(options) {
    this.e.classList.add('working')
    options.action()
      .then(i => {
        options.update()
      })
      .finally(i => {
        this.e.classList.remove('working')
      })
    return false
  }

  update() {
    this.e.querySelector(`[data-reward]`).innerHTML = parseFloat(fw(this.vault.stats.harvestReward)).toPrecision(4)
    if (this.vault.stats.borrowLimit)
      this.e.querySelector(`[data-borrow-limit]`).innerHTML = fwp(this.vault.stats.borrowLimit.muln(100), 2)

    if (localStorage.getItem('language') !== 'en') {
      i18n.translate()
    }
  }

}






