export { i18n }

const languageDict = {
  en: {
    deposit: 'Deposit ',
    depositTitle: 'Deposit',
    deposit2: 'Deposit ',
    cancel: 'Cancel',
    get: 'Get',
    new: 'New',
    in: 'in ',
    in2: 'in ',
    deprecated: 'Deprecated',
    vaultstx: 'Vaults',
    intro1: ' and grow your assets via automated yield',
    strategies: 'Strategies.',
    strategy: 'Strategy',
    stake: 'Stake ',
    stake2: 'Stake',
    max: 'Max',
    farmstx: 'Farms',
    intro2: ' and earn our native token ',
    fullstop: '.',
    deposit2: 'Deposit',
    intro3a: ' ACS in the ',
    acsVault: 'ACS Vault',
    intro3b: ' and earn a share of protocol ',
    intro3c: 'fees and rewards',
    connectWallet: 'Connect Wallet',
    stableswap: 'StableSwap',
    swap: 'Swap',
    token: 'Token',
    pool: 'Pool',
    controller: 'Controller',
    price: 'Price',
    priceImpact: 'Price Impact',
    receive: 'Receive',
    receiveMin: 'Receive Min.',
    supply: 'Supply',
    marketCap: 'Market Cap',
    staketx2: 'Stake',
    harvest: 'Harvest',
    daily: 'Daily',
    weightage: 'Weight',
    pending: 'Pending',
    wallet: 'Wallet',
    total: 'Total',
    unstake: 'Unstake',
    riskWarning: 'Risk Warning',
    reserves: 'Reserves',
    available: 'Available',
    slippage: 'Slippage',
    maxSlippage: 'Max. Slippage',
    smartContract: 'Smart Contracts',
    footerSCS: 'Smart Contracts/Source',
    footerFees: 'Fees',
    footerHome: 'Home',
    footerVote: 'Vote',
    footerWorkers: 'Workers',
    footerDocs: 'Docs',
    myTvl: 'My TVL',
    reward: 'Reward',
    borrowLimit: 'Borrow Limit',
    hvol: 'H Volume',
    withdraw: 'Withdraw',
    withdrawFee: 'Withdrawal fee',
    withdrawMax: 'Withdraw max.',
    txFee: 'Transaction fee',
    nonTrans: 'is non-transferrable',
    farmEnd: "This farm's rewards end at 6AM UTC on 27 November 2020. Please restake in the new ACSI farm.",
    contractHeader: 'All deployed contracts have verified and published source codes on BscScan',
    timelock: 'Timelock',
    reducePrice: 'Reduce price impact by swapping a smaller amount.',
    reducePriceDeposit: "Reduce price impact by depositing more in proportion to the pool's reserves.",
    reducePriceWithdraw: "Reduce price impact by withdrawing more in proportion to the pool's reserves.",
    priceAlert: 'Price impact too high.',
    walletError: 'Please ensure MetaMask is running on Binance Smart Chain.',
    depositError: 'Get tokens to deposit first.',
    stakeError: 'Get tokens to stake first.'
  },
  cn: {
    deposit: '通过自动收益策略将资金存入',
    depositTitle: '',
    deposit2: '存入 ',
    cancel: '取消',
    get: '获取',
    new: '新',
    deprecated: '退休',
    vaultstx: '资金库',
    intro1: '并增加资产。',
    stake: '加入',
    stake2: '入流动资金池',
    strategy: '策略',
    max: '最多',
    farmstx: '收益农场',
    intro2: '并获得我们的本地代币',
    fullstop: '。',
    intro3a: '将ACS存入',
    acsVault: 'ACS资金库',
    intro3b: '，按比例分享他人支付的',
    intro3c: '手续费分配以及资金库奖励',
    connectWallet: '连接钱包',
    stableswap: '稳定币兑换中心',
    swap: '交换',
    token: '代币',
    pool: '金池',
    controller: '控制者',
    price: '价格',
    priceImpact: '价格影响',
    receive: '收到',
    receiveMin: '最低收到',
    supply: '总量',
    marketCap: '总市值',
    staketx2: '入流动资金池',
    unstake: '退出流动资金池',
    harvest: '收取',
    daily: '日化',
    weightage: '比重',
    pending: '待定',
    wallet: '钱包',
    total: '总额',
    riskWarning: '风险预警',
    reserves: '储备金',
    available: '可用的',
    slippage: '滑移',
    maxSlippage: '最大滑移',
    smartContract: '智能合约',
    footerSCS: '智能合约/原始码',
    footerFees: '费用',
    footerHome: '主页',
    footerVote: '投票',
    footerWorkers: '工人',
    footerDocs: '文件',
    myTvl: '我的TVL',
    reward: '奖励',
    borrowLimit: '借入限额',
    hvol: '小时交量',
    withdraw: '提取',
    withdrawFee: '提款费',
    withdrawMax: '最高提款',
    txFee: '手续费',
    nonTrans: '不可转让',
    farmEnd: '该农场的奖励将于UTC 2020年11月27日上午6点结束。请入新的ACSI流动资金池中。',
    contractHeader: '所有部署的合约均已在BscScan上验证并发布了源代码',
    timelock: '时间锁',
    reducePrice: '通过交换较小的金额来减少价格影响。',
    reducePriceDeposit: '通过按比例增加储备金来减少价格影响。',
    reducePriceWithdraw: '通过按比例增加储备金的比例来减少价格影响。',
    priceAlert: '价格影响太大。',
    walletError: '请确保MetaMask在Binance Smart Chain上运行。',
    depositError: '获取代币以先存入。',
    stakeError: '获取代币以先入流动资金池。'
  },
  vn: {
    deposit: 'Nạp tiền ',
    depositTitle: 'Nạp',
    deposit2: 'Nạp tiền',
    cancel: 'Huỷ bỏ',
    get: 'Mua',
    new: 'Mới',
    deprecated: 'Không được chấp nhận',
    vaultstx: 'Vaults',
    in: 'vào ',
    in2: 'vào ',
    vaultstx: 'Vaults',
    intro1: '  và phát triển tài sản của bạn thông qua Chiến lược lãi gộp tự động.',
    strategies: ' ',
    strategy: 'Chiến lược',
    stake: 'Ký thác ',
    stake2: 'Ký thác',
    unstake: 'Hủy ký thác',
    max: 'Tối đa',
    farmstx: 'Farms',
    intro2: ' để nhận được token ',
    intro2b: '',
    fullstop: '.',
    intro3a: ' ACS vào ',
    acsVault: 'ACS Vault',
    intro3b: '  để nhận cổ phần lợi nhuận thu được từ ',
    intro3c: 'phí và thưởng',
    intro3d: ' mà ACS protocol thu được',
    connectWallet: 'Kết nối ví',
    stableswap: 'StableSwap',
    swap: 'Hoán đổi',
    token: 'Token',
    pool: 'Pool',
    controller: 'Bộ điều khiển',
    pricePrefix: 'Giá ',
    priceImpact: 'Tác động lên giá',
    receive: 'Nhận được',
    receiveMin: 'Nhận được tối thiểu',
    supply: 'Lượng cung',
    marketCap: 'Vốn hóa thị trường',
    harvest: 'Rút lãi',
    daily: 'Hằng ngày',
    weightage: 'Trọng số',
    pending: 'Chờ rút',
    wallet: 'Ví',
    total: 'Tổng',
    riskWarning: 'Cảnh báo rủi ro',
    reserves: 'Dự trữ',
    available: 'Khả dụng',
    slippage: 'Trượt giá',
    maxSlippage: 'Trượt giá tối đa',
    smartContract: 'SmartContract',
    footerSCS: 'SmartContract/Mã nguồn',
    footerFees: 'Phí',
    footerHome: 'Trang chủ',
    footerVote: 'Bỏ phiếu',
    footerWorkers: 'Công nhân',
    footerDocs: 'Thông tin',
    myTvl: 'TVL của tôi',
    reward: 'Phần thưởng',
    borrowLimit: 'Hạn mức vay',
    volPrefix: 'Khối lượng giao dịch ',
    hvol: 'h qua',
    withdraw: 'Rút tiền',
    withdrawFee: 'Phí rút tiền',
    withdrawMax: 'Rút tiền tối đa',
    txFee: 'Phí giao dịch',
    nonTrans: 'không thể chuyển nhượng',
    farmEnd: 'Phần thưởng của farm này đã kết thúc lúc 6AM UTC ngày 27 tháng 11 năm 2020. Vui lòng tham gia farm ACSI mới.',
    contractHeader: 'Tất cả các smart contract đã triển khai đều đã xác minh và xuất bản mã nguồn trên BscScan',
    timelock: 'Timelock',
    reducePrice: 'Giảm tác động lên giá bằng cách hoán đổi một lượng nhỏ hơn.',
    reducePriceDeposit: 'Giảm tác động lên giá bằng cách gửi theo tỷ lệ tương ứng với dự trữ của các pool.',
    reducePriceWithdraw: 'Giảm tác động lên giá bằng cách rút theo tỷ lệ tương ứng với lượng dự trữ của các pool.',
    priceAlert: 'Giá bị ảnh hưởng quá cao',
    walletError: 'Vui lòng đảm bảo rằng MetaMask đang chạy trên Binance Smart Chain.',
    depositError: 'Bạn chưa có token phù hợp để thực hiện hành động này.',
    stakeError: 'Bạn chưa có token phù hợp để thực hiện hành động này.'
  },
  es: {
    deposit: 'Deposite ',
    deposit2: 'Depositar ',
    depositTitle: 'Deposite ',
    cancel: 'Cancelar ',
    get: 'Obtener ',
    new: 'Nuevo ',
    in: ' en las ',
    in2: ' en las ',
    deprecated: 'Obsoleto ',
    vaultstx: 'Bóvedas',
    intro1: ' y aumente sus activos a través de estrategias automáticas.',
    strategies: ' ',
    strategy: 'Estrategia',
    stake: 'Participe ',
    stake2: 'Participe ',
    max: 'Máximo',
    farmstx: 'las granjas',
    intro2: ' y gane nuestro token nativo ',
    fullstop: '.',
    intro3a: 'Deposite ACS en la',
    acsVault: ' bóveda de ACS',
    intro3b: ' y obtenga una parte de las ',
    intro3c: 'comisiones y recompensas',
    intro3d: ' recolectadas por el protocolo',
    connectWallet: 'Conectar billetera',
    stableswap: 'StableSwap',
    swap: 'Intercambiar',
    token: 'Token',
    pool: 'Pool',
    controller: 'Controlador',
    price: 'Precio',
    priceImpact: 'Impacto de precio',
    receive: 'Recibido',
    receiveMin: 'Mínimo recibido',
    supply: 'Cantidad',
    marketCap: 'Cap. de mercado',
    staketx2: 'Participar',
    harvest: 'Cosechar',
    daily: 'Diario',
    weightage: 'Peso',
    pending: 'Pendiente',
    wallet: 'Billetera',
    total: 'Total',
    unstake: 'Unstake',
    riskWarning: 'Advertencia de riesgo',
    reserves: 'Reservas',
    available: 'Disponible',
    slippage: 'Deslizamiento',
    maxSlippage: 'Max. Deslizamiento',
    smartContract: 'Contrato inteligente',
    footerSCS: 'Contrato inteligente',
    footerFees: 'Tarifa',
    footerHome: 'Inicio',
    footerVote: 'Votar',
    footerWorkers: 'Workers',
    footerDocs: 'Documentación',
    myTvl: 'Mi TVL',
    reward: 'Recompensa',
    borrowLimit: 'Límite borrow',
    hvol: 'Alto volumen',
    withdraw: 'Retiro',
    withdrawFee: 'Tarifas de retiro',
    withdrawMax: 'Retiro máximo',
    txFee: 'Tarifas de transacción',
    nonTrans: 'es intransferible',
    farmEnd: "This farm's rewards end at 6AM UTC on 27 November 2020. Please restake in the new ACSI farm.",
    contractHeader: 'Todos los contratos inteligentes desplegados tienen su código fuente verificado y publicado en BscScan',
    timelock: 'Timelock',
    reducePrice: 'Reduzca el impacto en el precio intercambiando una cantidad menor.',
    reducePriceDeposit: 'Reduzca el impacto en el precio depositando en proporción a las reservas del Pool.',
    reducePriceWithdraw: 'Reduzca el impacto en el precio retirando en proporción a las reservas del Pool.',
    priceAlert: 'Impacto en el precio demasiado elevado.',
    walletError: 'Asegúrese de que MetaMask se esté ejecutando en Binance Smart Chain.',
    depositError: 'Primero obtenga tokens para depositar.',
    stakeError: 'Primero obtenga tokens para apostar.'
  },
  de: {
    deposit: 'Füge Assets ',
    deposit2: 'Hinzufügen ',
    depositTitle: ' ',
    cancel: 'Abbrechen ',
    get: 'Erhalte ',
    new: 'Neu ',
    in: 'unseren ',
    in2: ' ',
    deprecated: 'Veraltet ',
    vaultstx: 'Vaults',
    intro1: ' hinzu, um diese automatisch durch Zinseszins Strategien zu reinvestieren.',
    strategy: 'Strategie',
    strategies: ' ',
    stake: 'Füge zu den ',
    stake2: 'Hinzufügen ',
    max: 'Max',
    farmstx: 'Farmen',
    intro2: ' hinzu um zusätzlich zum automatischen reinvestieren, unseren nativen Token ',
    intro2b: ' zu erhalten',
    fullstop: '.',
    intro3a: 'Füge ACS zum ',
    acsVault: 'ACS Vault',
    intro3b: ' hinzu um an ',
    intro3c: 'Gebühren und Rewards',
    intro3d: ' des Protokolls teilzuhaben',
    connectWallet: 'Verbinde Wallet',
    stableswap: 'StableSwap',
    swap: 'Swap',
    token: 'Token',
    pool: 'Pool',
    controller: 'Controller',
    price: 'Preis',
    priceImpact: 'Preisauswirkung',
    receive: 'Erhalten',
    receiveMin: 'Erhalte Min.',
    supply: 'Supply',
    marketCap: 'Marktkapitalisierung',
    staketx2: 'Stake',
    harvest: 'Ernte',
    daily: 'Täglich',
    weightage: 'Gewichtung',
    pending: 'ausstehend',
    wallet: 'Wallet',
    total: 'Gesamt',
    unstake: 'Unstake',
    riskWarning: 'Risiko Warnung',
    reserves: 'Reserven',
    available: 'verfügbar',
    slippage: 'Abweichung',
    maxSlippage: 'Max. Abweichung',
    smartContract: 'Smart Contracts',
    footerSCS: 'Smart Contracts/Quellcodes',
    footerFees: 'Gebühren',
    footerHome: 'Home',
    footerVote: 'Wählen',
    footerWorkers: 'Workers',
    footerDocs: 'Docs',
    myTvl: 'Mein TVL',
    reward: 'Belohnung',
    borrowLimit: 'Kreditlimit',
    hvol: 'H Volume',
    withdraw: 'Abheben',
    withdrawFee: 'Abhebegebühren',
    withdrawMax: 'Max. abheben',
    txFee: 'Transaktionsgebühr',
    nonTrans: 'ist nicht übertragbar',
    farmEnd: 'Die Erträge in dieser Farm enden am 27 November 2020 um 6 Uhr UTC. Bitte übertrage deine Einlagen in unsere neue ACSI Farm.',
    contractHeader: 'Alle Smart Contracts wurden vielfach geprüft',
    timelock: 'Timelock',
    reducePrice: 'Reduziere die Preisschwankung durch tauschen eines kleineren Betrages.',
    reducePriceDeposit: 'Reduziere die Preisauswirkung',
    reducePriceWithdraw: 'Reduziere die Preisauswirkung',
    priceAlert: 'Preisauswirkung zu hoch.',
    walletError: 'Bitte stelle sicher dass Metamask mit dem Binance Smart Chain Netzwerk verbunden ist.',
    depositError: 'Hole erst Token um diese einzuzahlen.',
    stakeError: 'Hole erst Token'
  },

  tr: {
    deposit: ' ',
    deposit2: ' Para Yatır ',
    depositTitle: ' ',
    cancel: ' İptal Et ',
    get: ' Al ',
    new: ' Yeni ',
    in: ' ',
    in2: ' ',
    deprecated: ' Kullanımdan kaldırıldı ',
    vaultstx: ' ',
    intro1: ' Kasalara  para yatirin ve otomatik getiri Stratejileri ile varliklarinizi büyütün ',
    strategies: ' ',
    strategy: ' Strateji',
    stake: ' ',
    stake2: ' Stake ',
    max: ' Maksimum',
    farmstx: ' Çiftliklerde ',
    intro2: " stake yapin ve yerel tokenimiz ACS'yi kazanin. ( ",
    intro2b: ' )',
    fullstop: '.',
    intro3a: ' ',
    acsVault: ' ',
    intro3b: ' ACS Kasasina ACS yatirin ve protokol ücretleri ve ödüllerinden pay kazanin.',
    intro3c: ' ',
    intro3d: ' ',
    connectWallet: ' Cüzdan Bağlayın',
    stableswap: ' Sabit Takas',
    swap: ' Takas',
    token: ' Token',
    pool: ' Havuz',
    controller: ' Kontrolör',
    price: ' Fiyat',
    priceImpact: ' Fiyat Etkisi',
    receive: ' Teslim al',
    receiveMin: ' Minimum al',
    supply: ' Arz',
    marketCap: ' Piyasa Değeri',
    staketx2: ' Stake',
    harvest: ' Hasat Et',
    daily: ' Günlük',
    weightage: ' Ağırlık',
    pending: ' Beklemede',
    wallet: ' Cüzdan',
    total: ' Toplam',
    unstake: 'Unstake',
    riskWarning: ' Risk Uyarısı',
    reserves: ' Rezervler',
    available: ' Mevcut',
    slippage: '  Fiyat Kayması',
    maxSlippage: ' Maksimum Fiyat Kayması',
    smartContract: ' Akıllı Sözleşmeler',
    footerSCS: ' Akıllı Sözleşmeler / Kaynak',
    footerFees: ' Ücretler',
    footerHome: ' Ana Sayfa',
    footerVote: ' Oy',
    footerWorkers: ' İşçiler',
    footerDocs: ' Dokümanlar',
    myTvl: ' Benim TVL',
    reward: ' Ödül',
    borrowLimit: ' Borçlanma Limiti',
    hvol: ' H Hacmi',
    withdraw: ' Para Çek',
    withdrawFee: ' Para çekme ücreti',
    withdrawMax: ' Maksimum para çekme',
    txFee: ' İşlem ücreti',
    nonTrans: ' transfer edilemez',
    farmEnd: " Bu çiftliğin ödülleri 27 Kasım 2020 saat 06 00'da sona eriyor. Lütfen yeni ACSI çiftliğinde yeniden stake ediniz.",
    contractHeader: ' Dağıtılan tüm sözleşmeler doğrulandı ve BscScan üzerinde kaynak kodları yayınladı.',
    timelock: ' Zaman kilidi',
    reducePrice: ' Daha küçük bir miktar takas ederek fiyat etkisini azaltın.',
    reducePriceDeposit: ' Havuz rezervleriyle orantılı olarak daha fazla para yatırarak fiyat etkisini azaltın.',
    reducePriceWithdraw: 'Havuz rezervleriyle orantılı olarak daha fazla para çekerek fiyat etkisini azaltın.',
    priceAlert: ' Fiyat etkisi çok yüksek.',
    walletError: " Lütfen MetaMask'ın Binance Akıllı Zincir ile çalıştığından emin olun.",
    depositError: ' Para yatırımı öncesinde tokenleri alınız.',
    stakeError: ' Stake öncesinde tokenleri alınız.'
  },
  it: {
    deposit: 'Deposita ',
    depositTitle: 'Deposita',
    deposit2: 'Deposita ',
    cancel: 'Annulla',
    get: 'Ottieni',
    new: 'Nuovo',
    in: 'in ',
    in2: 'in ',
    deprecated: 'Deprecato',
    vaultstx: 'Vaults',
    intro1: ' e fai crescere i tuoi assets tramite yield automatizzato',
    strategies: 'Strategie.',
    strategy: 'Strategia',
    stake: 'Stake ',
    stake2: 'Stake',
    max: 'Max',
    farmstx: 'Farm',
    intro2: ' e guadagna il nostro token nativo ',
    fullstop: '.',
    deposit2: 'Deposita',
    intro3a: " ACS nell'",
    acsVault: 'ACS Vault',
    intro3b: ' e guadagna una parte delle ',
    intro3c: 'fee e ricompense del protocollo',
    connectWallet: 'Connetti Wallet',
    stableswap: 'StableSwap',
    swap: 'Swap',
    token: 'Token',
    pool: 'Pool',
    controller: 'Controller',
    price: 'Prezzo',
    priceImpact: 'Impatto sul prezzo',
    receive: 'Ricevi',
    receiveMin: 'Ricevi min.',
    supply: 'Offerta',
    marketCap: 'Capitalizzazione',
    staketx2: 'Stake',
    harvest: 'Raccogli',
    daily: 'Giorn.',
    weightage: 'Peso',
    pending: 'In sospeso',
    wallet: 'Wallet',
    total: 'Totale',
    unstake: 'Unstake',
    riskWarning: 'Avviso di rischio',
    reserves: 'Riserve',
    available: 'Disponibile',
    slippage: 'Slippage',
    maxSlippage: 'Slippage max.',
    smartContract: 'Smart Contract',
    footerSCS: 'Smart Contract/Codice sorgente',
    footerFees: 'Fee',
    footerHome: 'Home',
    footerVote: 'Vota',
    footerWorkers: 'Worker',
    footerDocs: 'Documentazione',
    myTvl: 'Mio TVL',
    reward: 'Ricompensa',
    borrowLimit: 'Limite di prestito',
    hvol: 'H-volume',
    withdraw: 'Preleva',
    withdrawFee: 'Fee di prelievo',
    withdrawMax: 'Prelievo max.',
    txFee: 'Fee di transazione',
    nonTrans: 'non è trasferibile',
    farmEnd: 'Le ricompense di questa farm finiscono alle 6AM UTC il 27 Novembre 2020. Fai lo stake nella nuova farm ACSI.',
    contractHeader: 'Tutti i contratti deployati hanno il codice sorgente verificato e pubblicato su BscScan',
    timelock: 'Time-lock',
    reducePrice: "Riduci l'impatto sul prezzo scambiando una quantità minore.",
    reducePriceDeposit: "Riduci l'impatto sul prezzo depositando di più rispetto alle riserve della pool.",
    reducePriceWithdraw: "Riduci l'impatto sul prezzo prelevando di più rispetto alle riserve della pool.",
    priceAlert: "L'impatto sul prezzo è troppo alto.",
    walletError: 'Assicurati che MetaMask stia girando sulla Binance Smart Chain.',
    depositError: 'Prima di depositare, devi ottenere i token.',
    stakeError: 'Prima di mettere in stake, devi ottenere i token.'
  },
  pt: {
    deposit: 'Depósito ',
    depositTitle: 'Depósito',
    deposit2: 'Depósito ',
    cancel: 'Cancelar',
    get: 'Obter',
    new: 'Novo',
    in: 'no',
    in2: 'no',
    deprecated: 'Descontinuado',
    vaultstx: 'Cofres',
    intro1: 'e faça crescer os seus ativos através de rendimento automatizado',
    strategies: 'Estratégias.',
    strategy: 'Estratégia',
    stake: 'Stake ',
    stake2: 'Stake',
    max: 'Máximo',
    farmstx: 'Fazenda',
    intro2: ' e ganhe o nosso token nativo ',
    fullstop: '.',
    deposit2: 'Deposite',
    intro3a: ' ACS no ',
    acsVault: 'Cofre de ACS',
    intro3b: ' e ganhe parte do nosso protocolo ',
    intro3c: 'taxas  e recompensas',
    connectWallet: 'Conectar carteira',
    stableswap: 'StableSwap',
    swap: 'Swap',
    token: 'Token',
    pool: 'Pool',
    controller: 'Controlador',
    price: 'Preço',
    priceImpact: 'Impacto no preço',
    receive: 'Receber',
    receiveMin: 'Min a Receber',
    supply: 'Fornecer',
    marketCap: 'Capitalização do Mercado',
    staketx2: 'Stake',
    harvest: 'Colher',
    daily: 'Diário',
    weightage: 'Peso',
    pending: 'Pendente',
    wallet: 'Carteira',
    total: 'Total',
    unstake: 'Unstake',
    riskWarning: 'Aviso de Risco',
    reserves: 'Reservas',
    available: 'Disponível',
    slippage: 'Slippage',
    maxSlippage: ' Slippage Máximo',
    smartContract: 'Smart Contracts',
    footerSCS: 'Smart Contracts/Source',
    footerFees: 'Taxas',
    footerHome: 'Início',
    footerVote: 'Votar',
    footerWorkers: 'Trabalhadores',
    footerDocs: 'Documentos',
    myTvl: 'O meu TVL',
    reward: 'Recompensa',
    borrowLimit: 'Limite de empréstimo',
    hvol: 'Volume de H',
    withdraw: 'Levantar',
    withdrawFee: 'Taxa de levantamento',
    withdrawMax: 'Levantamento máximo.',
    txFee: 'Taxa de Transação',
    nonTrans: 'é intransferível',
    farmEnd: 'As recompensas desta fazenda acabaram às 06:00 UTC no dia 27 de November de 2020. Por favor volte a fazer Stake na nova fazenda de ACSI.',
    contractHeader: 'Todos os contractos usados são verificados e os seus códigos fonte foram publicados na BscScan ',
    timelock: 'Timelock',
    reducePrice: ' Reduza o impacto no preço ao trocar uma quantidade mais pequena.',
    reducePriceDeposit: ' Reduza o impacto no preço ao depositar mais em proporção com as reservas da pool.',
    reducePriceWithdraw: 'Reduza o impacto no preço ao levantar mais em proporção com as reservas da pool. ',
    priceAlert: 'Impacto no preço demasiado alto.',
    walletError: ' Por favor verifique que o MetaMask está a correr na Binance Smart Chain',
    depositError: 'Obtenha tokens primeiro para depositar.',
    stakeError: 'Obtenha tokens primeiro para fazer stake.'
  },
  fr: {
    deposit: 'Déposer ',
    depositTitle: 'Déposer',
    deposit2: 'Déposer ',
    cancel: 'Annuler',
    get: 'Obtenir',
    new: 'Nouveau',
    in: 'dans ',
    in2: 'dans ',
    deprecated: 'Obsolète',
    vaultstx: 'Coffres',
    intro1: ' et faites croître vos actifs grâce à un rendement automatisé',
    strategies: 'Stratégies.',
    strategy: 'Stratégie',
    stake: 'Stake ',
    stake2: 'Stake',
    max: 'Max',
    farmstx: 'Fermes',
    intro2: ' et obtenez notre token natif ',
    fullstop: '.',
    deposit2: 'Déposer',
    intro3a: ' ACS dans le ',
    acsVault: 'Coffre ACS',
    intro3b: ' et gagnez une part du protocole ',
    intro3c: 'frais et récompenses',
    connectWallet: 'Connecter le portefeuille',
    stableswap: 'StableSwap',
    swap: 'Échanger',
    token: 'Token',
    pool: 'Pool',
    controller: 'Controller',
    price: 'Prix',
    priceImpact: 'Impact sur le prix',
    receive: 'Recevoir',
    receiveMin: 'Min. Reçu',
    supply: 'Approvisionnement',
    marketCap: 'Capitalisation',
    staketx2: 'Stake',
    harvest: 'Collecter',
    daily: 'Journalier',
    weightage: 'Poids',
    pending: 'En attente',
    wallet: 'Portefeuille',
    total: 'Total',
    unstake: 'Unstake',
    riskWarning: 'Avertissement sur les risques',
    reserves: 'Réserves',
    available: 'Disponible',
    slippage: 'Slippage',
    maxSlippage: 'Slippage Max.',
    smartContract: 'Smart contracts',
    footerSCS: 'Smart contracts/Source',
    footerFees: 'Frais',
    footerHome: 'Accueil',
    footerVote: 'Voter',
    footerWorkers: 'Workers',
    footerDocs: 'Documentation',
    myTvl: 'Ma TVL',
    reward: 'Récompense',
    borrowLimit: 'Limite d’emprunt',
    hvol: 'H Volume',
    withdraw: 'Retrait',
    withdrawFee: 'Frais de retrait',
    withdrawMax: 'Retirer max.',
    txFee: 'Frais de transaction',
    nonTrans: 'est non-transférable',
    farmEnd: 'Les récompenses de cette ferme se terminent à 6AM UTC le 27 novembre 2020. Veuillez restake dans la nouvelle ferme ACSI.',
    contractHeader: 'Tous les contrats déployés ont des codes sources vérifiés et publiés sur BscScan.',
    timelock: 'Verrouillage temporel',
    reducePrice: "Réduisez l'impact sur le prix en échangeant une plus petite quantité.",
    reducePriceDeposit: "Réduisez l'impact sur le prix en déposant davantage en proportion des réserves du pool.",
    reducePriceWithdraw: "Réduisez l'impact sur le prix en retirant davantage en proportion des réserves du pool.",
    priceAlert: 'Impact sur le prix trop élevé.',
    walletError: 'Veuillez vous assurer que MetaMask est connecté à Binance Smart Chain.',
    depositError: "Obtenez d'abord des tokens pour déposer.",
    stakeError: "Obtenez d'abord des tokens pour stake."
  }

}

class i18n {
  static translate (_el = document) {
    const _language = localStorage.getItem('language')
    const elements = _el.querySelectorAll(`[data-i18n]:not([data-i18n-lang='${_language}'])`)
    elements.forEach(el => {
      const _key = el.dataset.i18n
      el.innerText = this.t(_key, _language)
      el.dataset.i18nLang = _language
    })
  }

  static t (_key, _language) {
    _language ||= localStorage.getItem('language')
    return (languageDict[_language] && languageDict[_language][_key]) || languageDict.en[_key] || ''
  }

  static getAvailableLanguages () {
    return Object.keys(languageDict)
  }
}
