import { Config } from './config.js'

export { HTMLStableSwapInfo }

import { bn,min,fw,tw,twf,fwp,np } from './bn.js'

function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}


class HTMLStableSwapInfo {

  constructor(i) {
    this.stableSwap = i
    i.addEventListener('update',() => this.update())
    this.e=htmlToElement(this.template())
  }

  account() {
    return this.stableSwap.connect.account
  }

  async update() {
    if(this.stableSwap.totalBalance) {
      this.e.querySelector('[data-pool-info]').innerHTML = `
        <div class="text-secondary">
          <span data-i18n="reserves">Reserves</span>:
          ${this.stableSwap.coins.map((address, index) => `
            <div class="row">
              <div class="col col-sm-3">
                ${Config.tokens[address].symbol}
              </div>
              <div class="col">
                ${fwp(this.stableSwap.balances[index],this.stableSwap.defaultBaseTokenDisplayDecimals)}
              </div>
              <div class="col col-sm-3">
                ${fwp(this.stableSwap.balances[index].mul(bn(1e20)).div(this.stableSwap.totalBalance),2)}%
              </div>
            </div>
          `).join('')}
          <div class="row">
            <div class="col col-sm-3">
              <span data-i18n="total">Total</span>:
            </div>
            <div class="col">
              ${fwp(this.stableSwap.totalBalance,4)} ${this.stableSwap.baseSymbol}*<br/>
              ≃ ${fwp(this.stableSwap.totalBalanceBase,4)} ${this.stableSwap.baseSymbol}
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col col-sm-5">
              <span data-i18n="volPrefix"></span>24<span data-i18n="hvol">H Volume</span>:
            </div>
            <div class="col">
              ~${this.stableSwap.volume && this.stableSwap.volume.toLocaleString(undefined, { maximumFractionDigits: 4 })} ${this.stableSwap.baseSymbol}
            </div>
          </div>         
          <div class="row">
            <div class="col col-sm-5">
              APY:
            </div>
            <div class="col">
              ${showApr(this.stableSwap.apyDay*100)}%
            </div>
          </div>         
        </div>
      `
    }
    function showApr(i) { return i < 100 ? i < 10 ? np(i) : np(i,1) : np(i,0) }
  }


  template() {
    return `
      <div>
        <div data-pool-info class="">
        </div>
      </div>
    ` 
  }   
}
