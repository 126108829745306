import { Config } from './config.js'
import ConfigGenerated from './config/generated.json'
import { i18n } from './i18n.js'

export default function showContracts () {
  document.getElementById('content').innerHTML = `
    <div class="card my-4 mx-sm-5">
      <div class="card-body">
        <h2 class="card-title"><span data-i18n="smartContract">Smart Contracts</span></h2>
        <p class="card-text"><span data-i18n="contractHeader">All deployed contracts have verified and published source codes on BscScan</span>:</p>
      </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <span data-i18n="timelock">Timelock</span> 48H: ${a(Config.timelockLong)}
          </li>
          <li class="list-group-item">
            <span data-i18n="timelock">Timelock</span> 6H: ${a(Config.timelockShort)}
          </li>
          <li class="list-group-item">
            ProxyAdmin: ${a(Config.proxyAdmin)}
          </li>
          <li class="list-group-item">
            ACS <span data-i18n="token">Token</span>: ${a(Config.acs)}
          </li>
          <li class="list-group-item">
            ACSI <span data-i18n="token">Token</span>: ${a(Config.acsi)}
          </li>
          <li class="list-group-item">
            ACS <span data-i18n="farmstx">Farms</span>: ${a(Config.acsMasterFarm)}
          </li>
          <li class="list-group-item">
            ACS <span data-i18n="farmstx">Farms</span> V2: ${a(Config.acsMasterFarmV2)}
          </li>
          <li class="list-group-item">
            ACSI <span data-i18n="farmstx">Farms</span>: ${a(Config.acsiMasterFarm)}
          </li>
          <li class="list-group-item">
            ACSI <span data-i18n="farmstx">Farms</span> V2: ${a(Config.acsiMasterFarmV2b)}
          </li>
          <li class="list-group-item">
            Vaults <span data-i18n="controller">Controller</span>: ${a(Config.vaultsController)}
          </li>
          <li class="list-group-item">
            Vaults <span data-i18n="controller">Controller</span> V2: ${a(Config.vaultsControllerV2)}
          </li>
          <li class="list-group-item">
            Vaults <span data-i18n="controller">Controller</span> V2 Venus: ${a(Config.vaultsControllerV2_2)}
          </li>
          <li class="list-group-item">
            Vaults <span data-i18n="controller">Controller</span> V2 Pancake: ${a(Config.vaultsControllerV2_3)}
          </li>
          <li class="list-group-item">
            Vaults <span data-i18n="controller">Controller</span> V2 Atlantis: ${a(Config.vaultsControllerV2_4)}
          </li>
          ${Config.farmVaults.map(farmVault => farmVault.vault && `
            <li class="list-group-item">
              <h5>${farmVault.vault.tokenSymbol} Vault</h5>
              Vault: ${a(farmVault.vault.address)}<br/>
              <span data-i18n="strategy">Strategy</span>: ${a(ConfigGenerated.vaults[farmVault.vault.address].strategy)}
            </li>
          `).join('')}
          ${Config.stableSwaps.map(ss => `
            <li class="list-group-item">
              <h5>${ss.lpTokenSymbol} StableSwap</h5>
              <span data-i18n="pool">Pool</span>: ${a(ss.address)}<br/>
              ${ss.metaDeposit
? `
              <span data-i18n="deposit2">Deposit</span>: ${a(ss.metaDeposit)}<br/>
              `
 : ''}
              <span data-i18n="token">Token</span>: ${a(ss.lpToken)}
            </li>
          `).join('')}
        </ul>
      </div>
    </div>
  `
  function a (address) {
    return `<a href="https://bscscan.com/address/${address}">${address}</a>`
  }

  i18n.translate()
}
