import assert from 'assert'
import { BN, bn, fw } from './bn'

const PRICING_TOKEN = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' // wbnb
const MAX_HOPS = 3

type Quote = {
  path: string[]
  quote: () => BN,
  liquidity: () => BN,
  pools: any[]
}

class Quoter {
  _quotes: Quote[] = []
  _pricingQuotes: {
    [token:string]: Quote[]
  } = {}

  _bestPricingQuotes: {
    [token:string]: Quote
  } = {}

  add (newQuotes: Quote[]) {
    // TODO: this doesn't really cover all permutations of quotes - if A, B, C are added, it is not possible to get route A, C, B
    // instead, to get all permutations - which might not be necesarry here - we should
    // store all 0, 1, 2, 3 hop routes
    // add new 0 hop routes
    // new 0 hop routes permutate with old 0 hop routes to get new 1 hop routes
    // new 1 hop routes permutate with old 0 hop routes to get new 2 hop routes...
    // new 2 hop routes permutate with old 0 hop routes to get new 3 hop routes...
    // TODO: this needs to be cleaned up....

    this._quotes.forEach(quote => {
      if (quote.path.length <= MAX_HOPS &&
      quote.path[quote.path.length - 1] !== quote.path[0]) {
        newQuotes.forEach(newQuote => {
          if (quote.path[quote.path.length - 1] !== PRICING_TOKEN &&
          quote.path[quote.path.length - 1] === newQuote.path[0]) {
            this._addQuote(joinQuotes([quote, newQuote]))
          }
          if (newQuote.path[newQuote.path.length - 1] !== PRICING_TOKEN &&
          newQuote.path[newQuote.path.length - 1] === quote.path[0]) {
            this._addQuote(joinQuotes([newQuote, quote]))
          }
        })
      }
    })

    newQuotes.forEach(newQuote => {
      this._addQuote(newQuote)
    })

    // console.log(this._pricingQuotes)
  }

  _addQuote (quote: Quote) {
    this._quotes.push(quote)
    if (quote.path[quote.path.length - 1] === PRICING_TOKEN) {
      this._pricingQuotes[quote.path[0]] ||= []
      this._pricingQuotes[quote.path[0]].push(quote)
    }
  }

  q (token0: string, token1: string) {
    const q0 = this._pricingQuote(token0)
    const q1 = this._pricingQuote(token1)
    if (q0 && q1) {
      return q0.mul(bn(1e18)).div(q1)
    }
  }

  _pricingQuote (token: string) {
    if (token === PRICING_TOKEN) return bn(1e18)

    if (this._pricingQuotes[token]) {
      this._bestPricingQuotes[token] ||= _quoteBestLiquidity(this._pricingQuotes[token])
      return this._bestPricingQuotes[token].quote()
    }
  }
}

function _quoteBestLiquidity (quotes: Quote[]) {
  let _maxLiquidity = bn(0)
  let _quote = quotes[0]

  quotes.forEach(quote => {
    const _liquidity = quote.liquidity()
    // if (_quote.path[0] === '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c') console.log(quote, fw(quote.quote()), fw(_liquidity))
    if (_liquidity && _liquidity.gt(_maxLiquidity)) {
      _maxLiquidity = _liquidity
      _quote = quote
    }
  })

  // if (_quote.path[0] === '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c') console.log('selected', _quote, fw(_quote.quote()), fw(_quote.liquidity()))
  return _quote
}

function joinQuotes (quotes: Quote[]) {
  const joinedQuotes = [quotes[0]]
  for (let i = 1; i < quotes.length; i++) {
    const quote = joinedQuotes[i - 1]
    assert(quote.path[quote.path.length - 1] === quotes[i].path[0], 'test')
    joinedQuotes[i] = {
      path: [...quote.path, ...quotes[i].path.slice(1)],
      quote: () => { return quote.quote().mul(quotes[i].quote()).div(bn(1e18)) },
      liquidity: () => BN.min(quote.liquidity().mul(quotes[i].quote()).div(bn(1e18)), quotes[i].liquidity()),
      pools: [...quote.pools, ...quotes[i].pools]
    }
  }

  return joinedQuotes[joinedQuotes.length - 1]
}

// function invertQuote (quote: Quote) {
//   return ({
//     path: quote.path.reverse(),
//     quote: () => {
//       const i = quote.quote()
//       return ({
//         quote: bn(1e18).div(i.quote),
//         liquidity: i.liquidity.mul(bn(1e18)).div(i.quote)
//       })
//     }
//   })
// }

const quoter = new Quoter()

export { quoter }
