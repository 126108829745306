'use strict'

import { Config } from './config.js'
import { bn, min, fw, tw, twf, fwp, np } from './bn.js'
import { i18n } from './i18n.js'
import { Pair } from './pair.ts'
import { Blp } from './blp.js'
import { StableSwap } from './stable-swap.js'
import { unrektApi } from './unrekt-api'

import images from 'url:./../images/*/*'

export { HTMLVault }

function htmlToElement (html) {
  const template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html
  return template.content.firstChild
}

const defaultVaultTransactionInfo = '<a class="wallet-info" href="https://docs.acryptos.com/fees#all-vaults-except-acs-and-acsi-vault" target="_blank"><span data-i18n="withdrawFee">Withdrawal fee</span>: 0.1%</a>'
const defaultFarmTransactionInfo = `<a class="wallet-info" href="https://docs.acryptos.com/fees#acs-farms" target="_blank"><span>Harvest fee</span>: ${Config.acsHarvestFee} ACS</a>`

class HTMLVault {
  static header = htmlToElement(`
    <div class="px-2 d-flex text-secondary" style="font-size:88%;font-weight:bold">
      <div style="width:2em" class="me-2">
      </div>
      <div class="flex-grow-1 row g-0">
        <div class="col-sm-6 col-5">
        </div>
        <div class="col-3">
          <div class="row g-0">
            <div class="col-sm-6 col-12 text-center">
              <a href="#" data-sort-vaults="apy" class="text-decoration-none text-secondary">APY</a>
            </div>
            <div class="col-sm-6 col-12 text-center">
              <a href="#" data-sort-vaults="apy" class="text-decoration-none text-secondary"><span data-i18n="daily">Daily</span></a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row g-0">
            <div class="col-sm-5 col-12 text-center">
              <a href="#" data-sort-vaults="tvl" class="text-decoration-none text-secondary">TVL</a>
            </div>
            <div class="col-sm-7 col-12 text-center">
              <a href="#" data-sort-vaults="balance" class="text-decoration-none text-secondary">Balance</a>              
            </div>
          </div>
        </div>
      </div>
      <div style="width:0.5em" class="">
        <a href="#" data-sort-vaults="pending"><img style="width:100%" src="${images.plants['plant4.svg']}" /></a>
      </div>
    </div>
  `)

  template () {
    const tags = {
      core: '<span class="badge bg-info">Core</span>',
      venusProtocol: '<span class="badge bg-info">Venus</span>',
      valas: '<span class="badge bg-info">Valas</span>',
      channels: '<span class="badge bg-info">Channels</span>',
      swipeswap: '<span class="badge bg-info">Swipe</span>',
      mdex: '<span class="badge bg-info">MDEX</span>',
      unifi: '<span class="badge bg-info">Unifi</span>',
      wav: '🌊',
      atlantis: '<span class="badge bg-info">Atlantis</span>',
      dsg: '<span class="badge bg-info">DSG</span>',
      biswap: '<span class="badge bg-info">Biswap</span>',
      pancakeV2: '<span class="badge bg-info">PancakeV2</span>',
      stableswap: '<span class="badge bg-info">StableSwap</span>',
      acsfi: '<span class="badge bg-info">ACS</span>',
      acsifi: '<span class="badge bg-info">ACSI</span>',
      // acs: `<span class="badge bg-secondary">ACS</span>`,
      // acsi: `<span class="badge bg-secondary">ACSI</span>`,
      new: '<span class="badge bg-success"><span data-i18n="new">New</span></span>',
      newFees: '<span class="badge bg-success">New Fees</span>',
      beta: '<span class="badge bg-warning">Beta</span>',
      v2: '<span class="badge bg-warning">V2</span>',
      boost: '🔥',
      onsen: '♨️',
      test: '<span class="badge bg-warning" style="color:#333">Test</span>',
      deprecated: '<span class="badge bg-warning" style="color:#333"><span data-i18n="deprecated">Deprecated</span></span>',
      upgrading: '<span class="badge bg-secondary" style="color:#000">Upgrading</span>',
      pancakeV1: '<span class="badge bg-warning">PancakeV1</span>',
      acsV1: '<span class="badge bg-warning">AcsFarmV1</span>',
      acsiV1: '<span class="badge bg-warning">AcsiFarmV1</span>'
    }
    const tagsHtml = this.tags && this.tags.map(i => tags[i]).join(' ')
    const depositAlert = i18n.t('depositError')
    const stakeAlert = i18n.t('stakeError')

    const tokenImage = images.tokens[`${this.tokenImage || `${this.tokenSymbol.toLowerCase()}.svg`}`]

    return `
      <div class="vault my-1 p-2 bg-white wallet-empty vault-empty wallet-farm-empty farm-empty farm-rewards-empty">
        ${this.vault && this.vault.alert || ''}${this.farm && this.farm.alert || ''}
        <div class="d-flex">
          <div style="width:2em" class="me-2">
            <img style="width:100%" src="${tokenImage}" style="width:2em" />
          </div>
          <div class="flex-grow-1 row g-0" data-toggle-collapse="vault-detail" style="cursor:pointer">
            <div class="col-sm-6 col-5">
              <div class="row g-0">
                <div class="col-sm-6 col-12">
                  ${this.title || this.tokenSymbol} 
                </div>
                ${this.subtitle
? `
                  <div class="col-12 order-sm-last">
                    <div><small class="text-secondary">${this.subtitle}</small></div>
                  </div>
                `
: ''} 
                <div class="col-sm-6 col-12">
                  ${tagsHtml}
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="row g-0">
                <div class="col-sm-6 col-12 text-center">
                  <div data-header-apy>--</div>
                  <div data-header-apy-range data-collapse="vault-detail" class="collapse pb-1" style="line-height:1"></div>
                </div>
                <div class="col-sm-6 col-12 text-center">
                  <div data-header-daily>--</div>
                  <div data-header-daily-range data-collapse="vault-detail" class="collapse pb-1" style="line-height:1"></div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row g-0">
                <div class="col-sm-5 col-12 text-center">
                  <div data-tvl-header>--</div>
                </div>
                <div class="col-sm-7 col-12 text-center">
                  <div data-balance-header>--</div>
                </div>
              </div>
            </div>
          </div>
          <div style="width:0.5em">
            <span data-plant-header></span>
          </div>
        </div>
        <div class="row g-0 collapse" data-collapse="vault-detail">
          <div class="col-sm-8 col-6 row g-0">
            <div class="col-sm-6 col-12 p-2">
              <div data-balance-wallet></div>
              ${this.vault
? `
                <div data-balance-vault ></div>
              `
: ''}
              ${this.farm
? `
                <div data-balance-farm></div>
                <div data-reward-pending></div>
                <div data-boost data-collapse="boost-detail" class="collapse show"></div>
                <div data-boost-detail data-collapse="boost-detail" class="collapse"></div>
              `
: ''}
            </div>
            <div class="col p-2 order-sm-first">
              <div class="">
                <div data-swap-apy></div>
                <div data-vault-apy></div>
                <div data-farm-apy></div>
                <a class="wallet-info" target="_blank" href="https://docs.acryptos.com/security-and-risks#aprs-returns"><span data-i18n="riskWarning">Risk warning</span></a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="working-hide">
              <div data-action-view="none" class="text-end">
                ${(this.farm && this.farm.deprecated) || (this.vault && this.vault.deprecated)
? ''
: this.vault
? `
                  <div><button onclick="alert('${depositAlert}')" class="fallback account-hide btn btn-outline-secondary m-2"><span data-i18n="deposit2">Deposit</span></button></div>
                `
: `
                  <div><button onclick="alert('${stakeAlert}')" class="fallback account-hide btn btn-outline-secondary m-2"><span data-i18n="stake2">Stake</span></button></div>
                `}
                <div class="no-account-hide">
                  ${this.vault && !this.vault.showFarmDeprecatedOnly
? `
                    ${this.vault.deprecated ? '' : `<div><button data-action="deposit" ${this.vault && this.vault.disableDeposit ? 'disabled' : ''} class="btn btn-outline-primary m-2"><span data-i18n="deposit2">Deposit</span></button></div>`}
                    <div><button data-action="withdraw" class="btn btn-outline-primary m-2"><span data-i18n="withdraw">Withdraw</span></button></div>
                  `
: ''}
                  ${this.farm
? `
                    ${this.farm.deprecated ? '' : '<div><button data-action="stake" class="btn btn-outline-success m-2"><span data-i18n="staketx2">Stake</span></button></div>'}
                    <div><button data-action="unstake" class="btn btn-outline-primary m-2"><span data-i18n="unstake">Unstake</span></button></div>
                    <div><button data-action="harvest" class="btn btn-outline-success btn-sm m-2"><span data-i18n="harvest">Harvest</span></button></div>
                  `
: ''}
                </div>
                <div class="me-2">
                  ${this.vault && this.vault.walletInfo || ''}
                  ${this.farm && this.farm.walletInfo || ''}
                  ${this.vault && this.vault.showWatchAsset
? `
                    <a class="ml-1 text-muted text-decoration-none" href="#" data-watch-asset>⊕</a>
                  `
: ''}
                  <a class="ml-1 text-muted text-decoration-none" href="#" data-refresh>⟳</a>
                </div>
              </div>
              <div data-action-view="deposit" class="d-none">
                <form class="deposit">
                  <div class="m-1 mb-2">
                    <a class="max" href="#"><span data-i18n="max">Max</span></a>
                    <input class="my-1 form-control" name="amount" type="number" placeholder="0" required min="0" step="any">
                    ${this.farm
? `
                      <input class="form-check-input" type="checkbox" name="stakeAll" checked> <span class="text-secondary">and stake all</span>
                    `
: ''}
                  </div>
                  <div>
                    <button class="m-1 btn btn-outline-secondary btn-sm" data-action="none"><span data-i18n="cancel">Cancel</span></button>
                    <button class="m-1 btn btn-outline-primary btn-sm" type="submit"><span data-i18n="deposit2">Deposit</span></button>
                  </div>
                </form>
                <div>${this.vault && this.vault.transactionInfo || defaultVaultTransactionInfo}</div>
              </div>
              <div data-action-view="withdraw" class="d-none">
                <form class="withdraw">
                  <div class="m-1 mb-2">
                    <a class="max" href="#"><span data-i18n="max">Max</span></a>
                    <input class="my-1 form-control" name="amount" type="number" placeholder="0" required min="0" step="any">
                  </div>
                  <div>
                    <button class="m-1 btn btn-outline-secondary btn-sm" data-action="none"><span data-i18n="cancel">Cancel</span></button>
                    <button class="m-1 btn btn-outline-primary btn-sm" type="submit">Withdraw</button>
                  </div>                    
                </form>
                <div>${this.vault && this.vault.transactionInfo || defaultVaultTransactionInfo}</div>
              </div>
              <div data-action-view="stake" class="d-none">
                <form class="stake">
                  <div class="m-1 mb-2">
                    <a class="max" href="#"><span data-i18n="max">Max</span></a>
                    <input class="my-1 form-control" name="amount" type="number" value="0" required min="0" step="any">
                  </div>
                  <div>
                    <button class="m-1 btn btn-outline-secondary btn-sm" data-action="none"><span data-i18n="cancel">Cancel</span></button>
                    <button class="m-1 btn btn-outline-primary btn-sm" type="submit"><span data-i18n="stake2">Stake</span></button>
                  </div>
                  <!--<div>
                    <button data-harvest class="btn btn-outline-success btn-sm m-2">Harvest</button>
                  </div>-->
                </form>
                <div>${this.farm && this.farm.transactionInfo || defaultFarmTransactionInfo}</div>
              </div>
              <div data-action-view="unstake" class="d-none">
                <form class="unstake">
                  <div class="m-1 mb-2">
                    <a class="max" href="#"><span data-i18n="max">Max</span></a>
                    <input class="my-1 form-control" name="amount" type="number" placeholder="0" required min="0" step="any">
                  </div>
                  <div>
                    <button class="m-1 btn btn-outline-secondary btn-sm" data-action="none"><span data-i18n="cancel">Cancel</span></button>
                    <button class="m-1 btn btn-outline-primary btn-sm" type="submit"><span data-i18n="unstake">Unstake</span></button>
                  </div>
                </form>
                <div>${this.farm && this.farm.transactionInfo || defaultFarmTransactionInfo}</div>
              </div>
              <div data-action-view="harvest" class="d-none">
                <button class="m-1 btn btn-outline-secondary btn-sm" data-action="none"><span data-i18n="cancel">Cancel</span></button>
                <button class="m-1 btn btn-outline-primary btn-sm" data-harvest><span data-i18n="harvest">Harvest</span></button>
                <div>${this.farm && this.farm.transactionInfo || defaultFarmTransactionInfo}</div>
              </div>
            </div>
            <div class="working-show d-none p-2">
              <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  constructor (i) {
    this.vault = i.vault
    this.farm = i.farm
    this.tags = i.tags
    this.farm && this.farm.addEventListener('update', () => this.farmUpdate())
    this.vault && this.vault.addEventListener('update', () => this.vaultUpdate())
    this.tokenSymbol = this.vault ? this.vault.tokenSymbol : this.farm.tokenSymbol
    this.title = this.vault ? this.vault.title : this.farm.title
    this.subtitle = this.vault ? this.vault.subtitle : this.farm.subtitle
    this.tokenImage = this.vault ? this.vault.tokenImage : this.farm.tokenImage
    this.e = htmlToElement(this.template())

    this.e.querySelectorAll('[data-action]').forEach(i => {
      i.onclick = () => {
        this.action(i.dataset.action)
        return false
      }
    })

    this.e.querySelectorAll('[data-refresh]').forEach(j => {
      j.onclick = () => {
        this.triggerUpdate()
        return false
      }
    })

    this.e.querySelectorAll('[data-watch-asset]').forEach(j => {
      j.onclick = () => {
        this.watchAsset()
        return false
      }
    })

    if (this.vault) {
      this.actionForm({
        form: this.e.querySelector('form.deposit'),
        max: () => this.vault.stats.userBalanceNormalized,
        action: (i) => {
          return this.vault.deposit(this.vault.unapplyTokenDecimals(i)).then(i => {
            if (this.farm && this.e.querySelector("form.deposit input[name='stakeAll']").checked) {
              i = this.farm.depositAll()
            }
            return i
          })
        }
      }, this)

      this.actionForm({
        form: this.e.querySelector('form.withdraw'),
        max: () => this.vault.stats.userBalanceVaultInTokenNormalized,
        action: (i) => this.vault.withdrawInToken(this.vault.unapplyTokenDecimals(i))
      }, this)

      this.actionForm({
        form: this.e.querySelector('form.stake'),
        max: () => this.vault.stats.userBalanceVaultInTokenNormalized,
        action: (i) => this.farm.depositInVaultToken(this.vault.unapplyTokenDecimals(i))
      }, this)

      this.actionForm({
        form: this.e.querySelector('form.unstake'),
        max: () => this.farm.stats.userBalanceFarmInVaultTokenNormalized,
        action: (i) => this.farm.withdrawInVaultToken(this.vault.unapplyTokenDecimals(i))
      }, this)
    } else {
      this.actionForm({
        form: this.e.querySelector('form.stake'),
        max: () => this.farm.stats.userBalance,
        action: (i) => this.farm.deposit(i)
      }, this)

      this.actionForm({
        form: this.e.querySelector('form.unstake'),
        max: () => this.farm.stats.userBalanceFarm,
        action: (i) => this.farm.withdraw(i)
      }, this)
    }

    this.e.querySelector('button[data-harvest]').onclick = () => this.workAction({
      action: () => this.farm.harvest()
    })
  }

  async watchAsset () {
    this.vault.connect.web3.currentProvider.sendAsync({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: this.vault.token,
          symbol: await this.vault.tokenContract.methods.symbol().call(),
          decimals: await this.vault.tokenContract.methods.decimals().call()
        }
      }
    })
  }

  async triggerUpdate () {
    await (this.vault || this.farm).connect.poller.poll()
    // await Promise.all([this.vault && this.vault.update(), this.farm && this.farm.update()])
  }

  actionForm (options) {
    const input = options.form.querySelector("input[name='amount']")
    options.form.querySelector('a.max').onclick = () => {
      this.triggerUpdate().then(() => input.value = fw(options.max()))
      return false
    }
    options.form.onsubmit = () => this.workAction({
      action: () => options.action(bn(tw(input.value))),
      update: options.update
    })
  }

  workAction (options) {
    this.e.classList.add('working')
    this.action('none')
    options.action()
      .then(i => {
        this.triggerUpdate()
      })
      .finally(i => {
        this.e.classList.remove('working')
      })
    return false
  }

  account () {
    return (this.vault || this.farm).connect.account
  }

  action (action) {
    if (action === 'withdraw' && this.vault.withdrawAlert) alert(this.vault.withdrawAlert)
    this.e.querySelectorAll('[data-action-view]').forEach(i => i.classList.add('d-none'))
    this.e.querySelectorAll(`[data-action-view='${action}']`).forEach(i => i.classList.remove('d-none'))
  }

  vaultUpdate () {
    if (this.account()) {
      if (this.vault.stats.userBalanceNormalized.lten(0)) {
        this.e.classList.add('wallet-empty')
      } else {
        this.e.classList.remove('wallet-empty')
      }
      if (this.vault.stats.userBalanceVault.lten(0)) {
        this.e.classList.add('vault-empty')
      } else {
        this.e.classList.remove('vault-empty')
      }
      this.e.querySelectorAll('[data-balance-wallet]').forEach(j => j.innerHTML = `
        <div class="row mb-3">
          <div class="col-5">
            <span class="text-secondary" style="font-size:0.9em"><span data-i18n="wallet">Wallet</span>:</span> 
          </div>
          <div class="col">
            ${this.vault.stats.userBalanceNormalized.gtn(0)
? `
              <span class="text-success">
                ${showTokenBalance(this.vault.stats.userBalanceNormalized)} ${this.vault.tokenSymbol}
              </span> 
              <span class="text-secondary"><small>
                ${this.vault.stats.userBalanceUsd ? `($${fwp(this.vault.stats.userBalanceUsd)})` : ''}
              </small></span> 
            `
: `
              <span class="text-secondary">
                --
              </span> 
            `}
          </div>
        </div>
      `)
      // this.e.querySelectorAll(`[data-balance-vault]`).forEach(j => j.innerHTML = fwp(this.vault.stats.userBalanceVault))
      this.e.querySelectorAll('[data-balance-vault]').forEach(j => j.innerHTML = this.vault.stats.userBalanceVaultInTokenNormalized.lten(0)
        ? ''
        : `
        <div class="row mb-3">
          <div class="col-5">
            <span class="text-secondary" style="font-size:0.9em">Vault:</span> 
          </div>
          <div class="col">
            <span class="text-${this.farm ? 'success' : 'primary'}">
              ${showTokenBalance(this.vault.stats.userBalanceVaultInTokenNormalized)} ${this.vault.tokenSymbol}
            </span> 
            <span class="text-secondary"><small>
              ${this.vault.stats.userBalanceVaultUsd ? `($${fwp(this.vault.stats.userBalanceVaultUsd)})` : ''}
            </small></span> 
          </div>
        </div>
      `)
    }

    this.updateBoth()
  }

  updateBoth () {
    if (!this.account()) {
      this.e.querySelectorAll('[data-balance-wallet]').forEach(j => j.innerHTML = `
        <div class="row">
          <div class="col-5">
            <span class="text-secondary" style="font-size:0.9em"><span data-i18n="wallet">Wallet</span>:</span> 
          </div>
          <div class="col">
            <span class="text-primary">
              --
            </span> 
          </div>
        </div>        
      `)
    }

    this.updateTvl()
    this.updateApy()
    this.updateTranslate()
    this.updateHTML()
  }

  updateHTML () {
    this.e.querySelectorAll('[data-toggle-collapse]').forEach(i => {
      i.onclick = () => {
        this.e.querySelectorAll(`[data-collapse='${i.dataset.toggleCollapse}']`).forEach(i => {
          i.classList.toggle('show')
        })
        return false
      }
    })
  }

  updateTvl () {
    const tvl = this.vault && !this.vault.showFarmDeprecatedOnly ? this.vault.stats.vaultBalance : this.farm.stats.farmBalance
    const tvlUsd = this.vault && !this.vault.showFarmDeprecatedOnly ? this.vault.stats.vaultBalanceUsd : this.farm.stats.farmBalanceUsd

    this.tvl = tvlUsd

    // calculate header tvls
    if (tvlUsd) {
      let _tvl = parseFloat(fw(tvlUsd))
      _tvl = _tvl < 100000 ? `${np(_tvl / 1000, 1)}K` : _tvl < 1000000 ? `${np(_tvl / 1000, 0)}K` : `${np(_tvl / 1000000, 2)}M`
      this.e.querySelectorAll('[data-tvl-header]').forEach(j => j.innerHTML = `
        <small><span class="text-secondary">$${_tvl}</span></small>
      `)

      let userBalance = bn(0)
      const userBalanceWallet = bn(0)
      if (this.vault) {
        this.vault.stats.userBalanceUsd && userBalance.iadd(this.vault.stats.userBalanceUsd) && userBalanceWallet.iadd(this.vault.stats.userBalanceUsd)
        this.vault.stats.userBalanceVaultUsd && userBalance.iadd(this.vault.stats.userBalanceVaultUsd)
      } else {
        this.farm.stats.userBalanceUsd && userBalance.iadd(this.farm.stats.userBalanceUsd) && userBalanceWallet.iadd(this.farm.stats.userBalanceUsd)
      }
      if (this.farm) {
        this.farm.stats.userBalanceFarmUsd && userBalance.iadd(this.farm.stats.userBalanceFarmUsd)
      }
      userBalance = parseFloat(fw(userBalance))
      this.balance = userBalance
      userBalance = userBalance <= 0 ? '--' : userBalance < 1000 ? `${np(userBalance, 0)}` : userBalance < 100000 ? `${np(userBalance / 1000, 1)}K` : userBalance < 1000000 ? `${np(userBalance / 1000, 0)}K` : `${np(userBalance / 1000000, 2)}M`
      this.e.querySelectorAll('[data-balance-header]').forEach(j => j.innerHTML =
        userBalance == '--'
          ? `
          <small><span class="text-secondary">--</span></small>
        `
          : this.farm && this.vault && this.vault.stats.userBalanceVault.gtn(0)
            ? `
          <span class="text-warning">$${userBalance}</span>
        `
            : userBalanceWallet.gtn(0)
              ? `
          <span class="text-success">$${userBalance}</span>
        `
              : `
          <span class="text-primary">$${userBalance}</span>
        `
      )
    }

    // this.e.querySelectorAll(`[data-tvl]`).forEach(j => j.innerHTML = `
    //   <div class="row" style="font-size:0.9em">
    //     <div class="col-4">
    //       <span class="text-secondary" style="font-size:0.9em">TVL:</span>
    //     </div>
    //     <div class="col">
    //       <span class="text-secondary">
    //         ${tvlUsd ? `
    //           $${fwp(tvlUsd,2)}
    //         ` : `
    //           ${tvl && fwp(tvl,4)} ${this.tokenSymbol}
    //         `}
    //       </span>
    //     </div>
    //   </div>
    // `)
  }

  async updateApy () {
    const token = (this.vault && this.vault.token) || this.farm.token
    // let swapApy = StableSwap.stableSwapsByLpToken[token] || Pair.pairs[token] || Blp.blps[token]
    // swapApy = swapApy && swapApy.apyDay > 0.001 && swapApy
    const data = (await unrektApi).assets[56][this.vault && this.vault.address.toLowerCase()] || (await unrektApi).assets[56][token.toLowerCase()]
    let swapApy
    if (data && data.apyswap != null) {
      swapApy = {}
      swapApy.apyDay = parseFloat(data.apyswap) / 100
      swapApy.roiDay = (1 + swapApy.apyDay) ** (1 / 365) - 1
      swapApy.aprDay = swapApy.roiDay * 365
      // console.log(swapApy.apyDay, data.apyswap, data.platform, data.tokensymbol)
    }

    const isBoostedApy = this.farm && this.farm.stats.roiDayBoosted

    let daily = (swapApy && swapApy.roiDay || 0) + (this.vault && this.vault.stats.roiDay || 0) + (this.farm && (this.farm.stats.roiDayBoosted || this.farm.stats.roiDay) || 0)
    const apy = ((daily + 1) ** 365 - 1) * 100
    daily = daily * 100
    this.apy = apy // used for sorting vaults list

    this.e.querySelectorAll('[data-header-apy]').forEach(j => j.innerHTML = `
      <span class="text-${isBoostedApy ? 'success' : 'primary'}">${showApr(apy)}%</span>
    `)
    this.e.querySelectorAll('[data-header-daily]').forEach(j => j.innerHTML = `
      <small class="text-${isBoostedApy ? 'success' : 'primary'}">${daily.toFixed(2)}%</small>
    `)

    if (this.farm && this.farm.stats.roiDayMin) {
      let dailyMin = (swapApy && swapApy.roiDay || 0) + (this.vault && this.vault.stats.roiDay || 0) + (this.farm && this.farm.stats.roiDayMin || 0)
      let dailyMax = (swapApy && swapApy.roiDay || 0) + (this.vault && this.vault.stats.roiDay || 0) + (this.farm && this.farm.stats.roiDayMax || 0)
      const apyMin = ((dailyMin + 1) ** 365 - 1) * 100
      const apyMax = ((dailyMax + 1) ** 365 - 1) * 100
      dailyMin = dailyMin * 100
      dailyMax = dailyMax * 100
      this.e.querySelectorAll('[data-header-apy-range]').forEach(j => j.innerHTML = `
        <small class="text-secondary">${showApr(apyMin)}-${showApr(apyMax)}%</small>
      `)
      this.e.querySelectorAll('[data-header-daily-range]').forEach(j => j.innerHTML = `
        <small class="text-secondary">${dailyMin.toFixed(2)}-${dailyMax.toFixed(2)}%</small>
      `)
    }

    if (swapApy && swapApy.aprDay) {
      const apr = swapApy.aprDay * 100
      const apy = swapApy.apyDay * 100
      const daily = swapApy.roiDay * 100
      this.e.querySelectorAll('[data-swap-apy]').forEach(j => j.innerHTML = `
        <div class="mb-3">
          <div class="row">
            <div class="col-6">
              <span class="text-secondary" style="font-size:0.9em">Swap APY:</span> 
            </div>
            <div class="col">
              <span class="text-primary">${showApr(apy)}%</span> 
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <span class="text-secondary" style="font-size:0.9em">Swap APR:</span> 
            </div>
            <div class="col">
              <span class="text-primary">${showApr(apr)}%</span> 
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <small><span class="text-secondary" style="font-size:0.9em">Swap <span data-i18n="daily">Daily</span>:</span></small>
            </div>
            <div class="col">
              <small><span class="text-primary">${daily.toPrecision(3)}%</span></small>
            </div>
          </div>
        </div>
      `)
    }
    if (this.vault && this.vault.stats.apyDay && this.vault.stats.apyDay > 0) {
      const apr = this.vault.stats.aprDay * 100
      const apy = this.vault.stats.apyDay * 100
      const daily = this.vault.stats.roiDay * 100
      this.e.querySelectorAll('[data-vault-apy]').forEach(j => j.innerHTML = `
        <div class="mb-3">
          <div class="row">
            <div class="col-6">
              <span class="text-secondary" style="font-size:0.9em">Vault APY:</span> 
            </div>
            <div class="col">
              <span class="text-primary">${showApr(apy)}%</span> 
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <span class="text-secondary" style="font-size:0.9em">Vault APR:</span> 
            </div>
            <div class="col">
              <span class="text-primary">${showApr(apr)}%</span> 
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <small><span class="text-secondary" style="font-size:0.9em">Vault <span data-i18n="daily">Daily</span>:</span></small>
            </div>
            <div class="col">
              <small><span class="text-primary">${daily.toPrecision(3)}%</span></small>
            </div>
          </div>
        </div>
      `)
    }
    if (this.farm && this.farm.stats.apyDay) {
      const apy = this.farm.stats.apyDay * 100
      const apr = this.farm.stats.aprDay * 100
      const daily = this.farm.stats.roiDay * 100
      const apyMin = this.farm.stats.apyDayMin && this.farm.stats.apyDayMin * 100
      const aprMin = this.farm.stats.aprDayMin && this.farm.stats.aprDayMin * 100
      const dailyMin = this.farm.stats.roiDayMin && this.farm.stats.roiDayMin * 100
      const apyMax = this.farm.stats.apyDayMax && this.farm.stats.apyDayMax * 100
      const aprMax = this.farm.stats.aprDayMax && this.farm.stats.aprDayMax * 100
      const dailyMax = this.farm.stats.roiDayMax && this.farm.stats.roiDayMax * 100
      const apyBoosted = this.farm.stats.apyDayBoosted && this.farm.stats.apyDayBoosted * 100
      const aprBoosted = this.farm.stats.aprDayBoosted && this.farm.stats.aprDayBoosted * 100
      const dailyBoosted = this.farm.stats.roiDayBoosted && this.farm.stats.roiDayBoosted * 100

      this.e.querySelectorAll('[data-farm-apy]').forEach(j => j.innerHTML = `
        <div class="mb-3">
          <div class="row">
            <div class="col-6">
              <span class="text-secondary" style="font-size:0.9em">Farm APY:</span> 
            </div>
            <div class="col">
              <span class="text-${isBoostedApy ? 'success' : 'primary'}">${apyBoosted ? showApr(apyBoosted) : apyMin ? `${showApr(apyMin)}-${showApr(apyMax)}` : showApr(apy)}%</span> 
              ${apyBoosted
? `
                <div class="pb-1" style="line-height:1">
                <small class="text-secondary">${showApr(apyMin)}-${showApr(apyMax)}%</small> 
                </div>
              `
: ''}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <span class="text-secondary" style="font-size:0.9em">Farm APR:</span> 
            </div>
            <div class="col">
              <span class="text-${isBoostedApy ? 'success' : 'primary'}">${aprBoosted ? showApr(aprBoosted) : aprMin ? `${showApr(aprMin)}-${showApr(aprMax)}` : showApr(apr)}%</span> 
              ${aprBoosted
? `
                <div class="pb-1" style="line-height:1">
                <small class="text-secondary">${showApr(aprMin)}-${showApr(aprMax)}%</small> 
                </div>
              `
: ''}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <small><span class="text-secondary" style="font-size:0.9em">Farm <span data-i18n="daily">Daily</span>:</span></small>
            </div>
            <div class="col">
              <small><span class="text-${isBoostedApy ? 'success' : 'primary'}">${dailyBoosted ? dailyBoosted.toPrecision(3) : dailyMin ? `${dailyMin.toPrecision(3)}-${(dailyMax).toPrecision(3)}` : daily.toPrecision(3)}%</span></small>
              ${dailyBoosted
? `
                <div class="pb-1" style="line-height:1">
                <small class="text-secondary">${dailyMin.toPrecision(3)}-${(dailyMax).toPrecision(3)}%</small> 
                </div>
              `
: ''}
            </div>
          </div>
          <div class="row" style="font-size:0.9em">
            <div class="col-6">
              <span class="text-secondary" style="font-size:0.9em">${this.farm.masterFarm.rewardTokenSymbol} <span data-i18n="weightage">Weight</span>:</span> 
            </div>
            <div class="col">
              <span class="text-primary">${this.farm.allocPoint.toNumber() / 100}X</span> 
            </div>
          </div>
        </div>
      `)
    }

    // function showApy(i) { return i < 10000000 ? i < 1000000 ? i < 100000 ? i < 100 ? np(i) : np(i,0) : np(i/1000,0)+"K" : np(i/1000000,2)+"M" : i.toExponential(1) }
    function showApr (i) { return i < 10000 ? i < 100 ? i < 10 ? np(i) : np(i, 1) : np(i, 0) : i.toExponential(2) }
  }

  updateTranslate () {
    i18n.translate(this.e)
  }

  farmUpdate () {
    if (this.account()) {
      if (this.farm.stats.userBalance.lten(0)) {
        this.e.classList.add('wallet-farm-empty') // same as vault-empty, but let's keep it separate for now
      } else {
        this.e.classList.remove('wallet-farm-empty')
      }
      if (this.farm.stats.userBalanceFarm.lten(0)) {
        this.e.classList.add('farm-empty')
      } else {
        this.e.classList.remove('farm-empty')
      }
      if (
        this.farm.masterFarm.rewardTokenSymbol == 'WAV' && this.farm.stats.rewardPending.lte(bn(Config.wavHarvestFee * 1e18)) ||
        this.farm.masterFarm.rewardTokenSymbol == 'ACSI' && this.farm.stats.rewardPending.lte(bn(Config.acsiHarvestFee * 1e18)) ||
        this.farm.masterFarm.rewardTokenSymbol == 'ACS' && this.farm.stats.rewardPending.lte(bn(Config.acsHarvestFee * 1e18))
      ) {
        this.e.classList.add('farm-rewards-empty')
      } else {
        this.e.classList.remove('farm-rewards-empty')
      }
      if (!this.vault) {
        this.e.querySelectorAll('[data-balance-wallet]').forEach(j => j.innerHTML = `
          <div class="row mb-3">
            <div class="col-5">
              <span class="text-secondary" style="font-size:0.9em"><span data-i18n="wallet">Wallet</span>:</span> 
            </div>
            <div class="col">
              ${this.farm.stats.userBalance.gtn(0)
? `
                <span class="text-success">
                  ${showTokenBalance(this.farm.stats.userBalance)} ${this.farm.tokenSymbol}
                </span> 
                <span class="text-secondary"><small>
                  ${this.farm.stats.userBalanceUsd ? `($${fwp(this.farm.stats.userBalanceUsd)})` : ''}
                </small></span> 
              `
: `
                <span class="text-secondary">
                  --
                </span> 
              `}
            </div>
          </div>
        `)
      }
      this.e.querySelectorAll('[data-balance-farm]').forEach(j => j.innerHTML = this.farm.stats.userBalanceFarm.lten(0)
        ? ''
        : `
          <div class="row mb-3">
            <div class="col-5">
              <span class="text-secondary" style="font-size:0.9em">${this.vault ? 'Vault&Farm' : 'Farm'}:</span> 
            </div>
            <div class="col">
              <span class="text-primary">
                ${showTokenBalance(this.farm.stats.userBalanceFarmInVaultTokenNormalized ? this.farm.stats.userBalanceFarmInVaultTokenNormalized : this.farm.stats.userBalanceFarm)} ${this.farm.stats.userBalanceFarmInVaultTokenNormalized ? this.vault.tokenSymbol : this.farm.tokenSymbol}
              </span> 
              
              ${this.farm.stats.userBalanceFarmUsd ? `<span class="text-secondary"><small>($${fwp(this.farm.stats.userBalanceFarmUsd)})</small></span> ` : ''}
              
            </div>
          </div>
        `)
      this.e.querySelectorAll('[data-reward-pending]').forEach(j => {
        j.innerHTML = this.farm.stats.rewardPending.lten(0)
          ? ''
          : `
            <div class="row mb-3">
              <div class="col-5">
                <span class="text-secondary" style="font-size:0.9em"><span data-i18n="pending">Pending</span>:</span> 
              </div>
              <div class="col">
                <span class="text-success">
                  ${showTokenBalance(this.farm.stats.rewardPending)} ${this.farm.masterFarm.rewardTokenSymbol}
                  ${this.farm.stats.rewardPendingUsd ? `<span class="text-secondary"><small>($${fwp(this.farm.stats.rewardPendingUsd)})</small></span> ` : ''}

                  ${(this.farm.additionalPoolRewards && this.farm.additionalPoolRewards.map(additionalPoolReward => (additionalPoolReward.rewardPending.gtn(0) && `
                    <div>
                      ${showTokenBalance(additionalPoolReward.rewardPending.mul(bn(10 ** (18 - (Config.tokens[additionalPoolReward.rewardToken].decimals || 18)))))} ${Config.tokens[additionalPoolReward.rewardToken].symbol}
                      ${additionalPoolReward.rewardPendingUsd ? `<span class="text-secondary"><small>($${fwp(additionalPoolReward.rewardPendingUsd)})</small></span> ` : ''}
                    </div>
                  `) || '').join('')) || ''}
                </span> 
              </div>
            </div>
          `
      })

      const boostDocs = `<small><a href="${this.farm.masterFarm.rewardTokenSymbol === 'WAV' ? 'https://docs.thegreatwave.io/tokenomics#farm-boost' : 'https://docs.acryptos.com/acryptos-farms#user-interface'}" target="_blank">Docs</a></small>`
      const acsBoost = `<span data-toggle-collapse="boost-detail" class="text-secondary" style="text-decoration:underline;text-decoration-style:dotted;font-size:0.9em;cursor:pointer">${this.farm.masterFarm.rewardTokenSymbol === 'WAV' ? 'TGW' : this.farm.masterFarm.rewardTokenSymbol} Boost:</span> `
      this.e.querySelectorAll('[data-boost]').forEach(j => {
        j.innerHTML = this.farm.totalWeight && !this.farm.deprecated
          ? `
          <div class="mb-3" style="font-size:0.875em">
            <div class="row">
              <div class="col-5">
                ${acsBoost}
              </div>
              <div class="col">
                ${this.farm.stats.currentBoostX
? `
                  ${fwp(this.farm.stats.currentBoostX)}X <span class="text-secondary">(${fwp(this.farm.stats.futureBoostX)}X)</span>
                `
: `
                  <a href="https://docs.acryptos.com/acryptos-farms#acs-rewards-boost" target="_blank" class="text-success">2.5X your APR</a>
                `}
              </div>
            </div>
          </div>
        `
          : ''
      })

      this.e.querySelectorAll('[data-boost-detail]').forEach(j => {
        j.innerHTML = this.farm.totalWeight && !this.farm.deprecated
          ? `
          <div class="mb-3" style="font-size:0.875em">
            ${this.farm.stats.currentBoostX
? `
              <div class="row">
                <div class="col-5">
                  ${acsBoost}
                </div>
                <div class="col">
                  <span class="">
                    ${fwp(this.farm.stats.currentBoostX)}X
                  </span> 
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <span class="text-secondary" style="font-size:0.9em">Future Boost:</span> 
                </div>
                <div class="col">
                  <span class="text-secondary">
                    ${fwp(this.farm.stats.futureBoostX)}X &nbsp; <a href="#" data-action-force-boost><small>Force</small></a>
                  </span> 
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <span class="text-secondary" style="font-size:0.9em">For 2.5X:</span> 
                </div>
                <div class="col">
                  <span class="text-secondary">
                    ${fwp(this.farm.stats.maxBoostAcsNeeded)} ${this.farm.masterFarm.rewardTokenSymbol === 'WAV' ? 'WAV-ACS-BNB' : this.farm.masterFarm.rewardTokenSymbol}
                  </span> 
                </div>
              </div>
            `
: `
              ${acsBoost}
            `}
            <div class="row">
              <div class="col-5">
                <span class="text-secondary" style="font-size:0.9em">2.5X On First:</span>
                <br />${boostDocs}
              </div>
              <div class="col">
                <span class="text-secondary">
                  ${showTokenBalance(this.farm.stats.maxBoostOnFirstInVaultToken ? this.farm.stats.maxBoostOnFirstInVaultToken : this.farm.stats.maxBoostOnFirst)} ${this.farm.stats.maxBoostOnFirstInVaultToken ? this.vault.tokenSymbol : this.farm.tokenSymbol}
                </span> 
                <span class="text-secondary"><small>
                  ${this.farm.stats.maxBoostOnFirstUsd ? `($${fwp(this.farm.stats.maxBoostOnFirstUsd)})` : ''}
                </small></span> 
              </div>
            </div>
          </div>
        `
          : ''
      })
      this.e.querySelectorAll('[data-action-force-boost]').forEach(j => j.onclick = () => {
        this.farm.deposit(0).then(() => this.triggerUpdate())
        return false
      })

      this.pending = parseFloat(fw(this.farm.stats.rewardPendingUsd || this.farm.stats.rewardPending))
      const harvestFee = this.farm.masterFarm.rewardTokenSymbol == 'ACS' ? Config.acsHarvestFee : Config.acsiHarvestFee

      this.e.querySelectorAll('[data-plant-header]').forEach(j => j.innerHTML =
        this.farm.stats.rewardPending.lten(0)
          ? ''
          : this.farm.stats.rewardPending.lt(bn(harvestFee * 4 * 1e18))
            ? `<img style="width:100%" src="${images.plants['plant0.svg']}" />`
            : this.farm.stats.rewardPending.lt(bn(harvestFee * 8 * 1e18))
              ? `<img style="width:100%" src="${images.plants['plant1.svg']}" />`
              : this.farm.stats.rewardPending.lt(bn(harvestFee * 16 * 1e18))
                ? `<img style="width:100%" src="${images.plants['plant2.svg']}" />`
                : this.farm.stats.rewardPending.lt(bn(harvestFee * 32 * 1e18))
                  ? `<img style="width:100%" src="${images.plants['plant3.svg']}" />`
                  : `<img style="width:100%" src="${images.plants['plant4.svg']}" />`
      )
    }
    this.updateBoth()
  }
}

function showTokenBalance (i) {
  if (!i) return
  i = parseFloat(fw(i))
  return i > 10000 ? i.toLocaleString(undefined, { maximumFractionDigits: 2 }) : i.toLocaleString(undefined, { maximumSignificantDigits: 5 })
}
