'use strict';

import { Config } from './config.js'
import ConfigGenerated from './config/generated.json'
import { Vault, VaultWorker } from './vault.js'
import { Farm } from './farm.js'
import { HTMLAcsStats } from './html-acs-stats.js'
import { HTMLVault } from './html-vault.js'
import { HTMLVaultWorker } from './html-vault-worker.js'
import { StableSwap } from './stable-swap.js'

import { bn } from './bn.js'

export { initVaultWorkers }





async function initVaultWorkers(connect) {
  let vaults = []

  Config.farmVaults.forEach(i => {
    if(i.vault) {
      i.vault.token ||= ConfigGenerated.vaults[i.vault.address].token
      i.vault.strategy ||= ConfigGenerated.vaults[i.vault.address].strategy
      let vault = new VaultWorker(i.vault,connect)
      vaults.push( vault )
      let html = new HTMLVaultWorker(vault)
      document.getElementById("content").appendChild(html.e)
    }
  })

  connect.addEventListener('accountChanged',e => {
    console.log('accountChanged')
    poll()
  })

  poll()
  setInterval(() => poll(),15000)


  async function poll() {
    console.log('poll')
    await updateVaults()
  }

  async function updateVaults() {
    await Promise.all(vaults.map(vault => vault.update()))
  }
}





