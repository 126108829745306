import { Config } from './config.js'
import { StableSwap } from './stable-swap.js'
import { HTMLStableSwap } from './html-stable-swap.js'
import { HTMLStableSwapInfo } from './html-stable-swap-info.js'
import { HTMLStableSwapDeposit } from './html-stable-swap-deposit.js'
import { HTMLStableSwapWithdraw } from './html-stable-swap-withdraw.js'
import { i18n } from './i18n.js'
import { router } from './router.js'

let stableSwaps
// export default (i,connect) => {
//   if(stableSwaps[i.address]) return stableSwaps[i.address]
//   return stableSwaps[i.address] = new StableSwapController(i,connect)
// }

export { StableSwapController }

class StableSwapController {
  static init (connect) {
    this.init = () => {} // run only once

    stableSwaps = Config.stableSwaps.map(i => {
      const stableSwap = new StableSwap(i, connect)
      stableSwap.htmlSwap = new HTMLStableSwap(stableSwap)
      stableSwap.htmlInfo = new HTMLStableSwapInfo(stableSwap)
      stableSwap.htmlDeposit = new HTMLStableSwapDeposit(stableSwap)
      stableSwap.htmlWithdraw = new HTMLStableSwapWithdraw(stableSwap)
      connect.addEventListener('accountChanged', e => stableSwap.updateUser())
      return stableSwap
    })
    stableSwaps.forEach(stableSwap => {
      if (stableSwap.basePool) {
        stableSwap.basePool = stableSwaps.find(i => i.address === stableSwap.basePool)
      }
    })

    this.e = htmlToElement(this.template())
    document.getElementById('content').innerHTML = ''
    document.getElementById('content').appendChild(this.e)
    // translate in init
    if (localStorage.getItem('language') !== 'en') {
      i18n.translate()
    }
    router.updatePageLinks()
  }

  static async resolve (action, id, connect) {
    this.init(connect)

    if (!id) id = 'acs4'

    const stableSwap = stableSwaps.find(i => i.id === id)

    this.e.querySelectorAll('[data-nav-deprecated-toggle]').forEach(i => {
      i.onclick = () => {
        this.e.querySelectorAll('[data-nav-deprecated]').forEach(i => i.classList.toggle('d-none'))
        return false
      }
    })


    this.e.querySelectorAll('[data-nav]').forEach(i => i.classList.remove('active'))
    this.e.querySelectorAll(`[data-nav='${action}']`).forEach(i => i.classList.add('active'))
    this.e.querySelectorAll('[data-sub-nav]').forEach(i => i.classList.add('d-none'))
    const subNav = this.e.querySelector(`[data-sub-nav='${id}']`)
    if (subNav) subNav.classList.remove('d-none')
    this.e.querySelectorAll('[data-nav-id]').forEach(i => i.classList.remove('active'))
    this.e.querySelectorAll(`[data-nav-id='${id}']`).forEach(i => i.classList.add('active'))

    this.e.querySelector('.stable-swap-main').innerHTML = ''
    if (action === 'swap') {
      this.e.querySelector('.stable-swap-main').appendChild(stableSwap.htmlSwap.e)
    } else if (action === 'deposit') {
      this.e.querySelector('.stable-swap-main').appendChild(stableSwap.htmlDeposit.e)
    } else if (action === 'withdraw') {
      this.e.querySelector('.stable-swap-main').appendChild(stableSwap.htmlWithdraw.e)
    }

    this.e.querySelector('[data-pool-info]').appendChild(stableSwap.htmlInfo.e)

    await stableSwap.update()
    await stableSwap.updateUser()
  }

  static template () {
    return `
      <div>
        <div>
          <ul class="nav nav-pills justify-content-center my-3">
            ${stableSwaps.map(stableSwap => `
              <li class="nav-item">
                <a data-nav-id="${stableSwap.id}" ${stableSwap.deprecated ? 'data-nav-deprecated' : ''} class="nav-link ${stableSwap.deprecated ? 'd-none' : ''}" data-navigo href="/stableswap/${stableSwap.id === 'acs4' ? '' : stableSwap.id + '/'}">${stableSwap.title}</a>
              </li>
            `).join('')}            
            <li class="nav-item">
              <a class="nav-link" data-nav-deprecated-toggle href="#">&gt;</a>
            </li>
          </ul>
        </div>
        ${stableSwaps.map(stableSwap => `
          <div data-sub-nav="${stableSwap.id}" class="d-none bg-white py-3">
            <ul class="nav nav-pills justify-content-center">
              <li class="nav-item">
                <a data-nav="swap" class="nav-link" data-navigo href="/stableswap/${stableSwap.id === 'acs4' ? '' : stableSwap.id + '/'}">Swap</a>
              </li>
              <li class="nav-item">
                <a data-nav="deposit" class="nav-link" data-navigo href="/stableswap/deposit/${stableSwap.id === 'acs4' ? '' : stableSwap.id + '/'}">Deposit</a>
              </li>
              <li class="nav-item">
                <a data-nav="withdraw" class="nav-link" data-navigo href="/stableswap/withdraw/${stableSwap.id === 'acs4' ? '' : stableSwap.id + '/'}">Withdraw</a>
              </li>
            </ul>
          </div>
        `).join('')}
        <div class="stable-swap-">
        </div>
        <div class="stable-swap-main">
        </div>
      </div>
    `
  }
}

function htmlToElement (html) {
  const template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html

  return template.content.firstChild
}
