// import Web3 from './web3.min.js'
const Web3 = window.ACryptoSWeb3
// const Web3 = require('web3')

const BN = Web3.utils.BN
const bn = Web3.utils.toBN
const min = Web3.utils.BN.min
const fw = Web3.utils.fromWei
const tw = Web3.utils.toWei
const twf = i => bn(i * 1e18)
const fwp = (i, n = 2) => np(fw(i), n)
const np = (i, n = 2) => parseFloat(i).toLocaleString(undefined, { maximumFractionDigits: n })

export { BN, bn, min, fw, tw, twf, fwp, np }
