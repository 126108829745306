import { Config } from './config.js'

export { HTMLStableSwapDeposit }

import { bn,min,fw,tw,twf,fwp,np } from './bn.js'
import { i18n } from './i18n.js'

function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

class HTMLStableSwapDeposit {

  constructor(i) {
    this.stableSwap = i
    i.addEventListener('update',() => this.update())
    this.e=htmlToElement(this.template())

    this.e.querySelectorAll('[data-toggle-collapse]').forEach(i => {
      i.onclick = () => {
        this.e.querySelectorAll(`[data-collapse='${i.dataset.toggleCollapse}']`).forEach(i => {
          i.classList.toggle('show')
        })
        return false
      }
    }) 

    this.e.querySelectorAll('a[data-max]').forEach(i => {
      i.onclick = () => {
        if(!this.account()) return false
        this.stableSwap.updateUser().then(() => {
          this.e.querySelector(`[data-amount='${i.dataset.max}']`).value=fw(this.stableSwap.userBalances[i.dataset.max])
          this.updateDeposit()
        })
        return false
      }
    }) 

    this.e.querySelectorAll('a[data-set-max-slippage]').forEach(i => {
      i.onclick = () => {
        this.e.querySelector(`input[data-max-slippage]`).value=i.dataset.setMaxSlippage
        this.updateDeposit()
        return false
      }
    }) 

    this.e.querySelectorAll('input[data-amount], input[data-max-slippage]').forEach(i => i.addEventListener('input', debounce(e => {
      this.updateDeposit()
    },500)))


    //DEPOSIT
    this.e.querySelector('form').onsubmit = () => {
      this.e.classList.add('working')
      this.deposit()
        .then(i => {
          this.resetAmounts()
          this.e.querySelector('[data-deposit-info]').innerHTML = ''
          this.stableSwap.update()
          this.stableSwap.updateUser()
        })
        .finally(i => {
          this.e.classList.remove('working')
        })
      return false
    }

  }

  resetAmounts() {
    this.e.querySelectorAll(`[data-amount]`).forEach(i => i.value='0')
  }

  account() {
    return this.stableSwap.connect.account
  }

  async deposit() {
    let {amounts, amountTokenMin} = await this.updateDeposit()
    console.log('deposit',amounts,amountTokenMin.toString())
    return this.stableSwap.deposit(amounts,amountTokenMin.toString())
  }

  async update() {
    if(this.account()) {
      this.stableSwap.userBalances.forEach((amount,index)=>{
        this.e.querySelector(`[data-user-balance='${index}']`).innerHTML = `
          ${fwp(amount,this.stableSwap.defaultBaseTokenDisplayDecimals)}
        `
      })      
    } else {
      this.e.querySelectorAll(`[data-user-balance]`).forEach(i => i.innerHTML = `
        --
      `)
    }

  }

  async updateDeposit() {
    console.log('updateDeposit')
    let depositDisabled = false
    let amounts = this.stableSwap.coins.map((address, index) => tw((this.e.querySelector(`input[data-amount='${index}']`).value || "0")))
    let amountToken = await this.stableSwap.calcTokenAmount(amounts,true)
    let maxSlippage = twf(parseFloat(this.e.querySelector(`input[data-max-slippage]`).value)).divn(100)
    let amountTokenMin = amountToken.mul(bn(1e18).sub(maxSlippage)).div(bn(1e18))
    let amountTokenBase = amountToken.mul(await this.stableSwap.getVirtualPrice()).div(bn(1e18))

    let amountsTotal = amounts.reduce((a,v)=>a.add(bn(v)),bn(0))
    let priceImpact = (amountsTotal.gtn(0) ? parseFloat(fw(amountTokenBase.mul(bn(1e18)).div(amountsTotal)))-1 : 0)*100

    if(priceImpact < -2 || !this.account() || amountsTotal.lten(0)) {
      depositDisabled = true
    }

    this.e.querySelector('button[type="submit"]').disabled = depositDisabled

    this.e.querySelector('[data-deposit-info]').innerHTML = `
      <div class="row">
        <div class="col-5 col-sm-5">
          <span data-i18n="deposit2">Deposit</span>:
        </div>
        <div class="col text-primary">
          ${fwp(amountsTotal,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.baseSymbol}*
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-sm-5">
          <span data-i18n="receive">Receive</span>:
        </div>
        <div class="col text-primary">
          ~${fwp(amountToken,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.lpTokenSymbol}<br/>
          ≃ ${fwp(amountTokenBase,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.baseSymbol}
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-sm-5">
          <span data-i18n="priceImpact">Price impact</span>:
        </div>
        <div class="col text-${priceImpact < -0.2 ? priceImpact < -1 ? priceImpact < -2 ? 'danger' : 'warning' : 'secondary' : 'success'}">
          ${np(priceImpact,4)}%
        </div>
      </div>
      ${priceImpact < -1 ? `
        <div class="alert alert-info my-2" role="alert">
          <span data-i18n="reducePriceDeposit"> Reduce price impact by depositing more in proportion to the pool's reserves.</span>
        </div>
      `:``}
      ${priceImpact < -2 ? `
        <div class="alert alert-danger my-2" role="alert">
          <span data-i18n="priceAlert">Price impact too high.</span>
        </div>
      `:``}
      <div class="row text-secondary" style="font-size:0.9em">
        <div class="col-5 col-sm-5">
          <span data-i18n="receiveMin">Receive min.</span>:
        </div>
        <div class="col">
          ${fwp(amountTokenMin,this.stableSwap.defaultBaseTokenDisplayDecimals)} ${this.stableSwap.lpTokenSymbol}
        </div>
      </div>
    `
    if (localStorage.getItem('language')!=='en'){
      i18n.translate()
    }
    return {amounts, amountTokenMin}
  }

  template() {
    return `
      <div class="">
        <div class="bg-white">
          <form>
            <div class="row g-0">
              <div class="col">
                ${this.stableSwap.coins.map((address, index) => `
                  <div class="m-3">
                    <div class="row">
                      <div class="col-4">
                        ${Config.tokens[address].symbol}
                      </div>
                      <div class="col text-end text-secondary">
                        <small><span data-i18n="available">Available</span>: <a href="#" data-max="${index}" class="text-secondary"><span data-user-balance="${index}">--</span></a></small>
                      </div>
                    </div>
                    <div>
                      <input data-amount="${index}" type="number" class="form-control" required min="0" step="any" value="0" />
                    </div>
                  </div>
                `).join('')}
              </div>
              <div class="col-12 col-sm-6 px-3">
                <div data-deposit-info class="my-3">
                </div>
                <div class="row">
                  <div class="col">
                    <span data-i18n="maxSlippage">Max. slippage</span> %<br/>
                    <small>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="0.1">0.1%</a>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="0.5">0.5%</a>
                      <a class="text-secondary mx-1" href="#" data-set-max-slippage="1">1%</a>
                    </small>
                  </div>
                  <div class="col col-sm-4">
                    <input data-max-slippage type="number" id="max-slippage" class="form-control" required min="0" step="any" value="0.5">
                  </div>
                </div>
                <div class="working-hide my-3">
                  <button class="btn btn-outline-primary btn-lg" disabled type="submit"><span data-i18n="deposit2">Deposit</span></button>
                </div>
                <div class="working-show d-none p-2 my-3">
                  <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
                </div>
                <div data-pool-info class="my-3">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    ` 
  }   
}
