export { Poller }

class Poller extends EventTarget {
  // static latestBlock = 0

  constructor (web3, options = {}) {
    super()

    this.web3 = web3
    this.options = options

    this.multicall = new this.web3.eth.Contract([{ inputs: [{ components: [{ internalType: 'address', name: 'target', type: 'address' }, { internalType: 'bytes', name: 'callData', type: 'bytes' }], internalType: 'struct Multicall.Call[]', name: 'calls', type: 'tuple[]' }], name: 'aggregate', outputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }, { internalType: 'bytes[]', name: 'returnData', type: 'bytes[]' }], stateMutability: 'nonpayable', type: 'function' }, { inputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }], name: 'getBlockHash', outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }], stateMutability: 'view', type: 'function' }, { inputs: [], name: 'getCurrentBlockCoinbase', outputs: [{ internalType: 'address', name: 'coinbase', type: 'address' }], stateMutability: 'view', type: 'function' }, { inputs: [], name: 'getCurrentBlockDifficulty', outputs: [{ internalType: 'uint256', name: 'difficulty', type: 'uint256' }], stateMutability: 'view', type: 'function' }, { inputs: [], name: 'getCurrentBlockGasLimit', outputs: [{ internalType: 'uint256', name: 'gaslimit', type: 'uint256' }], stateMutability: 'view', type: 'function' }, { inputs: [], name: 'getCurrentBlockTimestamp', outputs: [{ internalType: 'uint256', name: 'timestamp', type: 'uint256' }], stateMutability: 'view', type: 'function' }, { inputs: [{ internalType: 'address', name: 'addr', type: 'address' }], name: 'getEthBalance', outputs: [{ internalType: 'uint256', name: 'balance', type: 'uint256' }], stateMutability: 'view', type: 'function' }, { inputs: [], name: 'getLastBlockHash', outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }], stateMutability: 'view', type: 'function' }],
      '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb')

    this.calls = []
  }

  // {target, method, cb, outputs}
  // connect.initPoller.add({target: masterFarm.address,
  //   method: () => masterFarm.contract.methods.sushi(),
  //   cb: i => {
  //     masterFarm.rewardToken = i
  //     masterFarm.rewardTokenSymbol = Config.tokens[i].symbol
  //   }
  // })
  add (call) {
    this.calls.push(call)
  }

  async poll (options = {}) {
    if (!this.calls) return
    const calls = []; const callsO = []

    // console.log(this.calls)
    this.calls.forEach(call => {
      const method = call.method()
      if (method) {
        call.outputs = method._method.outputs.length > 1 ? method._method.outputs : method._method.outputs[0]
        callsO.push([call.target, method.encodeABI()])
        calls.push(call)
      }
    })

    if (options.clearCalls) this.calls = []

    // console.log(callsO)
    const o = await this.multicall.methods.aggregate(callsO).call({}, this.options.blockNumber).then(i => {
      // const block = parseInt(i[0])
      // if (Poller.latestBlock < block) Poller.latestBlock = block
      return i[1].map((j, index) => {
        const outputs = calls[index].outputs
        if (Array.isArray(outputs)) {
          j = this.web3.eth.abi.decodeParameters(outputs, j)
        } else {
          j = this.web3.eth.abi.decodeParameter(outputs, j)
        }
        return calls[index].cb(j, i[0])
      })
    })

    this.dispatchEvent(new CustomEvent('poll'))

    return o
  }
}
