import { Config } from './config.js'
import { BN, bn, fw } from './bn.js'

import { quoter } from './quoter'

const wbnb = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
const busd = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
const usdt = '0x55d398326f99059fF775485246999027B3197955'
const acs = '0x4197C6EF3879a08cD51e5560da5064B773aa1d29'
const mdx = '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
const eth = '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
const sxp = '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A'
const ust = '0x23396cF899Ca06c4472205fC903bDB4de249D6fC'

class Pair {
  static pairs={}

  config: any
  contract: any
  reserves: BN[2] = [bn(0), bn(0)]
  totalSupply: BN
  agoReserves: BN[2] = [bn(0), bn(0)]
  agoTotalSupply: BN

  constructor (config: {
    address: string
    tokens: string[2]
  }) {
    Pair.pairs[config.address] = this

    this.config = config

    this.contract = new this.config.web3.eth.Contract(
      Config.abis.uniswapV2Pair,
      this.config.address
    )

    this.config.poller.add({
      target: this.config.address,
      method: () => this.contract.methods.totalSupply(),
      cb: i => { this.totalSupply = bn(i) }
    })
    this.config.poller.add({
      target: this.config.address,
      method: () => this.contract.methods.getReserves(),
      cb: (i, b) => {
        this.currentBlock = b
        this.reserves[0] = bn(i[0])
        this.reserves[1] = bn(i[1])
        this._updateApy()
      }
    })

    if (!this.config.fromBlock || this.config.fromBlock < this.config.agoPoller.options.blockNumber) {
      this.config.agoPoller.add({
        target: this.config.address,
        method: () => this.contract.methods.getReserves(),
        cb: (i, b) => {
          this.agoBlock = b
          this.agoReserves[0] = bn(i[0])
          this.agoReserves[1] = bn(i[1])
        }
      })
      this.config.agoPoller.add({
        target: this.config.address,
        method: () => this.contract.methods.totalSupply(),
        cb: i => {
          this.agoTotalSupply = bn(i)
        }
      })
    }

    this.config.poller.addEventListener('poll', () => this.handlePolled())

    quoter.add([
      {
        path: this.config.tokens,
        quote: () => this.reserves[1].mul(bn(1e18)).div(this.reserves[0]),
        liquidity: () => this.reserves[1],
        pools: [this]
      },
      {
        path: [this.config.tokens[1], this.config.tokens[0]],
        quote: () => this.reserves[0].mul(bn(1e18)).div(this.reserves[1]),
        liquidity: () => this.reserves[0],
        pools: [this]
      },
      {
        path: [this.config.address, wbnb],
        quote: () => {
          const r = this._reservesBnb()
          return r && r.mul(bn(1e18)).div(this.totalSupply)
        },
        liquidity: () => this._reservesBnb(),
        pools: [this]
      }
    ])
  }

  _reservesBnb () {
    const q0 = quoter.q(this.config.tokens[0], wbnb)
    const q1 = quoter.q(this.config.tokens[1], wbnb)
    if (!q0 && !q1) return
    const bnb0 = q0 && this.reserves[0].mul(q0).div(bn(1e18))
    const bnb1 = q1 && this.reserves[1].mul(q1).div(bn(1e18))
    return (bnb0 || bnb1).add(bnb1 || bnb0)
  }

  _updateApy () {
    if (!this.agoTotalSupply) return
    const k = this.reserves[0].mul(this.reserves[1]).div(this.totalSupply).mul(bn(1e18)).div(this.totalSupply).mul(bn(1e18))
    // console.log(this.tokens[0]Symbol,this.tokens[1]Symbol,fw(this.agoTotalSupply))
    const agoK = this.agoReserves[0].mul(this.agoReserves[1]).div(this.agoTotalSupply).mul(bn(1e18)).div(this.agoTotalSupply).mul(bn(1e18))
    const daysAgo = (this.currentBlock - this.agoBlock) / 28800
    this.roiDay = (fw(k) ** 0.5 / fw(agoK) ** 0.5) ** (1 / daysAgo) - 1
    this.apyDay = (this.roiDay + 1) ** 365 - 1
    this.aprDay = this.roiDay * 365
    // console.log(this.tokens[0]Symbol, this.tokens[1]Symbol, parseFloat(fw(k))**0.5, parseFloat(fw(agoK))**0.5)
    // console.log(this.tokens[0]Symbol, this.tokens[1]Symbol, this.roiDay*100, this.apyDay*100, this.aprDay*100)
  }

  async handlePolled () {
    // console.log(this.reserves)
  }
}

export { Pair }
