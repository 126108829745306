const _temp0 = require("url:../plants/plant0.svg");
const _temp1 = require("url:../plants/plant1.svg");
const _temp2 = require("url:../plants/plant2.svg");
const _temp3 = require("url:../plants/plant3.svg");
const _temp4 = require("url:../plants/plant4.svg");

const _temp5 = require("url:../tokens/aave.svg");
const _temp6 = require("url:../tokens/acs-bnb.svg");
const _temp7 = require("url:../tokens/acs.svg");
const _temp8 = require("url:../tokens/acs3btc.svg");
const _temp9 = require("url:../tokens/acs4qusd.svg");
const _temp10 = require("url:../tokens/acs4usd.svg");
const _temp11 = require("url:../tokens/acs4ust.svg");
const _temp12 = require("url:../tokens/acs4vai.svg");
const _temp13 = require("url:../tokens/acsi-acs.svg");
const _temp14 = require("url:../tokens/acsi.svg");
const _temp15 = require("url:../tokens/ada-bnb.svg");
const _temp16 = require("url:../tokens/ada.svg");
const _temp17 = require("url:../tokens/alice-bnb.svg");
const _temp18 = require("url:../tokens/alpha-bnb.svg");
const _temp19 = require("url:../tokens/asr-bnb.svg");
const _temp20 = require("url:../tokens/atl.svg");
const _temp21 = require("url:../tokens/atm-bnb.svg");
const _temp22 = require("url:../tokens/atom-bnb.svg");
const _temp23 = require("url:../tokens/bake-bnb.svg");
const _temp24 = require("url:../tokens/balbt-bnb.svg");
const _temp25 = require("url:../tokens/band-bnb.svg");
const _temp26 = require("url:../tokens/bch-bnb.svg");
const _temp27 = require("url:../tokens/bch.svg");
const _temp28 = require("url:../tokens/bdo-bnb.svg");
const _temp29 = require("url:../tokens/beth-eth.svg");
const _temp30 = require("url:../tokens/beth.svg");
const _temp31 = require("url:../tokens/blk-bnb.svg");
const _temp32 = require("url:../tokens/bnb.svg");
const _temp33 = require("url:../tokens/broobee-cake.svg");
const _temp34 = require("url:../tokens/bry-bnb.svg");
const _temp35 = require("url:../tokens/bscx-bnb.svg");
const _temp36 = require("url:../tokens/bsw.svg");
const _temp37 = require("url:../tokens/btc-bnb.svg");
const _temp38 = require("url:../tokens/btc-busd.svg");
const _temp39 = require("url:../tokens/btc.svg");
const _temp40 = require("url:../tokens/btcst-bnb.svg");
const _temp41 = require("url:../tokens/burger-bnb.svg");
const _temp42 = require("url:../tokens/busd-bnb.svg");
const _temp43 = require("url:../tokens/busd.svg");
const _temp44 = require("url:../tokens/cake-bnb.svg");
const _temp45 = require("url:../tokens/cake-busd.svg");
const _temp46 = require("url:../tokens/cake.svg");
const _temp47 = require("url:../tokens/comp-eth.svg");
const _temp48 = require("url:../tokens/ctk-bnb.svg");
const _temp49 = require("url:../tokens/dai-bnb.svg");
const _temp50 = require("url:../tokens/dai-busd.svg");
const _temp51 = require("url:../tokens/dai.svg");
const _temp52 = require("url:../tokens/ditto-bnb.svg");
const _temp53 = require("url:../tokens/doge.svg");
const _temp54 = require("url:../tokens/dot-bnb.svg");
const _temp55 = require("url:../tokens/dot.svg");
const _temp56 = require("url:../tokens/dsg.svg");
const _temp57 = require("url:../tokens/egld-bnb.svg");
const _temp58 = require("url:../tokens/eos-bnb.svg");
const _temp59 = require("url:../tokens/eth-beth.svg");
const _temp60 = require("url:../tokens/eth-bnb.svg");
const _temp61 = require("url:../tokens/eth-usdc.svg");
const _temp62 = require("url:../tokens/eth.svg");
const _temp63 = require("url:../tokens/fil-bnb.svg");
const _temp64 = require("url:../tokens/fil.svg");
const _temp65 = require("url:../tokens/for-busd.svg");
const _temp66 = require("url:../tokens/front-bnb.svg");
const _temp67 = require("url:../tokens/hard-bnb.svg");
const _temp68 = require("url:../tokens/helmet-bnb.svg");
const _temp69 = require("url:../tokens/hget-bnb.svg");
const _temp70 = require("url:../tokens/inj-bnb.svg");
const _temp71 = require("url:../tokens/juv-bnb.svg");
const _temp72 = require("url:../tokens/lina-busd.svg");
const _temp73 = require("url:../tokens/link-bnb.svg");
const _temp74 = require("url:../tokens/link.svg");
const _temp75 = require("url:../tokens/lit-bnb.svg");
const _temp76 = require("url:../tokens/ltc-bnb.svg");
const _temp77 = require("url:../tokens/ltc.svg");
const _temp78 = require("url:../tokens/mamzn-ust.svg");
const _temp79 = require("url:../tokens/matic.svg");
const _temp80 = require("url:../tokens/mdx.png");
const _temp81 = require("url:../tokens/mgoogl-ust.svg");
const _temp82 = require("url:../tokens/mir-ust.svg");
const _temp83 = require("url:../tokens/mnflx-ust.svg");
const _temp84 = require("url:../tokens/mtsla-ust.svg");
const _temp85 = require("url:../tokens/nar-cake.svg");
const _temp86 = require("url:../tokens/nya-cake.svg");
const _temp87 = require("url:../tokens/og-bnb.svg");
const _temp88 = require("url:../tokens/pbtc-bnb.svg");
const _temp89 = require("url:../tokens/psg-bnb.svg");
const _temp90 = require("url:../tokens/reef-bnb.svg");
const _temp91 = require("url:../tokens/sfp-bnb.svg");
const _temp92 = require("url:../tokens/sote-bnb.svg");
const _temp93 = require("url:../tokens/stax-cake.svg");
const _temp94 = require("url:../tokens/sushi-eth.svg");
const _temp95 = require("url:../tokens/swgb-bnb.svg");
const _temp96 = require("url:../tokens/swingby-bnb.svg");
const _temp97 = require("url:../tokens/sxp-bnb.svg");
const _temp98 = require("url:../tokens/sxp.svg");
const _temp99 = require("url:../tokens/ten-bnb.svg");
const _temp100 = require("url:../tokens/tko-bnb.svg");
const _temp101 = require("url:../tokens/tlm-bnb.svg");
const _temp102 = require("url:../tokens/tpt-busd.svg");
const _temp103 = require("url:../tokens/tusd-busd.svg");
const _temp104 = require("url:../tokens/tusd.svg");
const _temp105 = require("url:../tokens/twt-bnb.svg");
const _temp106 = require("url:../tokens/unfi-bnb.svg");
const _temp107 = require("url:../tokens/unfi.png");
const _temp108 = require("url:../tokens/uni-bnb.svg");
const _temp109 = require("url:../tokens/usdc-bnb.svg");
const _temp110 = require("url:../tokens/usdc-busd.svg");
const _temp111 = require("url:../tokens/usdc.svg");
const _temp112 = require("url:../tokens/usdt-bnb.svg");
const _temp113 = require("url:../tokens/usdt-busd.svg");
const _temp114 = require("url:../tokens/usdt.svg");
const _temp115 = require("url:../tokens/usdx-bnb.svg");
const _temp116 = require("url:../tokens/ust-busd.svg");
const _temp117 = require("url:../tokens/vai-busd.svg");
const _temp118 = require("url:../tokens/vai.svg");
const _temp119 = require("url:../tokens/vrt-bnb.svg");
const _temp120 = require("url:../tokens/watch-bnb.svg");
const _temp121 = require("url:../tokens/wav.png");
const _temp122 = require("url:../tokens/wsote-bnb.svg");
const _temp123 = require("url:../tokens/xrp-bnb.svg");
const _temp124 = require("url:../tokens/xrp.svg");
const _temp125 = require("url:../tokens/xtz-bnb.svg");
const _temp126 = require("url:../tokens/xvs-bnb.svg");
const _temp127 = require("url:../tokens/xvs.svg");
const _temp128 = require("url:../tokens/yfi-bnb.svg");
const _temp129 = require("url:../tokens/yfii-bnb.svg");
const _temp130 = require("url:../tokens/zee-bnb.svg");
const _temp131 = require("url:../tokens/zil-bnb.svg");

module.exports = {
  "plants":   {
    "plant0.svg": _temp0,
    "plant1.svg": _temp1,
    "plant2.svg": _temp2,
    "plant3.svg": _temp3,
    "plant4.svg": _temp4
  },
  "tokens":   {
    "aave.svg": _temp5,
    "acs-bnb.svg": _temp6,
    "acs.svg": _temp7,
    "acs3btc.svg": _temp8,
    "acs4qusd.svg": _temp9,
    "acs4usd.svg": _temp10,
    "acs4ust.svg": _temp11,
    "acs4vai.svg": _temp12,
    "acsi-acs.svg": _temp13,
    "acsi.svg": _temp14,
    "ada-bnb.svg": _temp15,
    "ada.svg": _temp16,
    "alice-bnb.svg": _temp17,
    "alpha-bnb.svg": _temp18,
    "asr-bnb.svg": _temp19,
    "atl.svg": _temp20,
    "atm-bnb.svg": _temp21,
    "atom-bnb.svg": _temp22,
    "bake-bnb.svg": _temp23,
    "balbt-bnb.svg": _temp24,
    "band-bnb.svg": _temp25,
    "bch-bnb.svg": _temp26,
    "bch.svg": _temp27,
    "bdo-bnb.svg": _temp28,
    "beth-eth.svg": _temp29,
    "beth.svg": _temp30,
    "blk-bnb.svg": _temp31,
    "bnb.svg": _temp32,
    "broobee-cake.svg": _temp33,
    "bry-bnb.svg": _temp34,
    "bscx-bnb.svg": _temp35,
    "bsw.svg": _temp36,
    "btc-bnb.svg": _temp37,
    "btc-busd.svg": _temp38,
    "btc.svg": _temp39,
    "btcst-bnb.svg": _temp40,
    "burger-bnb.svg": _temp41,
    "busd-bnb.svg": _temp42,
    "busd.svg": _temp43,
    "cake-bnb.svg": _temp44,
    "cake-busd.svg": _temp45,
    "cake.svg": _temp46,
    "comp-eth.svg": _temp47,
    "ctk-bnb.svg": _temp48,
    "dai-bnb.svg": _temp49,
    "dai-busd.svg": _temp50,
    "dai.svg": _temp51,
    "ditto-bnb.svg": _temp52,
    "doge.svg": _temp53,
    "dot-bnb.svg": _temp54,
    "dot.svg": _temp55,
    "dsg.svg": _temp56,
    "egld-bnb.svg": _temp57,
    "eos-bnb.svg": _temp58,
    "eth-beth.svg": _temp59,
    "eth-bnb.svg": _temp60,
    "eth-usdc.svg": _temp61,
    "eth.svg": _temp62,
    "fil-bnb.svg": _temp63,
    "fil.svg": _temp64,
    "for-busd.svg": _temp65,
    "front-bnb.svg": _temp66,
    "hard-bnb.svg": _temp67,
    "helmet-bnb.svg": _temp68,
    "hget-bnb.svg": _temp69,
    "inj-bnb.svg": _temp70,
    "juv-bnb.svg": _temp71,
    "lina-busd.svg": _temp72,
    "link-bnb.svg": _temp73,
    "link.svg": _temp74,
    "lit-bnb.svg": _temp75,
    "ltc-bnb.svg": _temp76,
    "ltc.svg": _temp77,
    "mamzn-ust.svg": _temp78,
    "matic.svg": _temp79,
    "mdx.png": _temp80,
    "mgoogl-ust.svg": _temp81,
    "mir-ust.svg": _temp82,
    "mnflx-ust.svg": _temp83,
    "mtsla-ust.svg": _temp84,
    "nar-cake.svg": _temp85,
    "nya-cake.svg": _temp86,
    "og-bnb.svg": _temp87,
    "pbtc-bnb.svg": _temp88,
    "psg-bnb.svg": _temp89,
    "reef-bnb.svg": _temp90,
    "sfp-bnb.svg": _temp91,
    "sote-bnb.svg": _temp92,
    "stax-cake.svg": _temp93,
    "sushi-eth.svg": _temp94,
    "swgb-bnb.svg": _temp95,
    "swingby-bnb.svg": _temp96,
    "sxp-bnb.svg": _temp97,
    "sxp.svg": _temp98,
    "ten-bnb.svg": _temp99,
    "tko-bnb.svg": _temp100,
    "tlm-bnb.svg": _temp101,
    "tpt-busd.svg": _temp102,
    "tusd-busd.svg": _temp103,
    "tusd.svg": _temp104,
    "twt-bnb.svg": _temp105,
    "unfi-bnb.svg": _temp106,
    "unfi.png": _temp107,
    "uni-bnb.svg": _temp108,
    "usdc-bnb.svg": _temp109,
    "usdc-busd.svg": _temp110,
    "usdc.svg": _temp111,
    "usdt-bnb.svg": _temp112,
    "usdt-busd.svg": _temp113,
    "usdt.svg": _temp114,
    "usdx-bnb.svg": _temp115,
    "ust-busd.svg": _temp116,
    "vai-busd.svg": _temp117,
    "vai.svg": _temp118,
    "vrt-bnb.svg": _temp119,
    "watch-bnb.svg": _temp120,
    "wav.png": _temp121,
    "wsote-bnb.svg": _temp122,
    "xrp-bnb.svg": _temp123,
    "xrp.svg": _temp124,
    "xtz-bnb.svg": _temp125,
    "xvs-bnb.svg": _temp126,
    "xvs.svg": _temp127,
    "yfi-bnb.svg": _temp128,
    "yfii-bnb.svg": _temp129,
    "zee-bnb.svg": _temp130,
    "zil-bnb.svg": _temp131
  }
}