'use strict'

import { connect } from './connect.js'
import { router } from './router.js'

import showContracts from './acryptos-contracts.js'
import { initVaultWorkers } from './acryptos-vaults.js'
import { VaultsController } from './vaults-controller.js'
import { StableSwapController } from './stable-swap-controller.js'
import { i18n } from './i18n.js'

import { createApp } from 'vue'
import ConnectButton from '../src/components/buttons/ConnectButton'

window.addEventListener('DOMContentLoaded', init)

async function init () {
  createApp(ConnectButton).mount('#vue-connect-button')

  initPage()

  router
    .on({
      '/contracts': async function () {
        showContracts()
      },
      '/stableswap/deposit': async function () {
        await initConnect()
        StableSwapController.resolve('deposit', null, connect)
      },
      '/stableswap/deposit/:id': async function (params) {
        await initConnect()
        StableSwapController.resolve('deposit', params.id, connect)
      },
      '/stableswap/withdraw': async function () {
        await initConnect()
        StableSwapController.resolve('withdraw', null, connect)
      },
      '/stableswap/withdraw/:id': async function (params) {
        await initConnect()
        StableSwapController.resolve('withdraw', params.id, connect)
      },
      '/stableswap': async function () {
        await initConnect()
        StableSwapController.resolve('swap', null, connect)
      },
      '/stableswap/:id': async function (params) {
        await initConnect()
        StableSwapController.resolve('swap', params.id, connect)
      },
      '/worker': async function () {
        await initConnect()
        initVaultWorkers(connect)
      },
      '/deprecated': async function (params) {
        await initConnect()
        VaultsController.resolve('all', connect, { deprecated: true })
      },
      '/:filter': async function (params) {
        await initConnect()
        VaultsController.resolve(params.filter, connect)
      },
      '/': async function () {
        await initConnect()
        VaultsController.resolve('all', connect)
      }
    })
    .resolve()
}

function initPage () {
  initColorScheme()
  initHamburger()
  initI18n()

  setTimeout(() => clearLoadingScreen(), 888)
}

function initColorScheme () {
  if (JSON.parse(localStorage.getItem('toggle-color-scheme'))) document.body.classList.toggle('toggle-color-scheme')
  document.getElementById('color-scheme').onclick = () => {
    document.body.classList.toggle('toggle-color-scheme')
    localStorage.setItem('toggle-color-scheme', !JSON.parse(localStorage.getItem('toggle-color-scheme')))
    return false
  }
}

function initHamburger () {
  const _toggles = document.querySelectorAll('[data-toggle-main-nav]')
  _toggles.forEach(i => i.onclick = () => {
    _toggles.forEach(j => j.classList.toggle('is-active'))
    document.querySelector('#main-nav').classList.toggle('active')
    return false
  })
}

function initI18n () {
  if (!localStorage.getItem('language')) {
    try {
      const userLocale = navigator.languages[0].substring(0, 2)
      const supportedLanguages = i18n.getAvailableLanguages()

      if (supportedLanguages.includes(userLocale)) {
        localStorage.setItem('language', userLocale)
      } else {
        // falback
        localStorage.setItem('language', 'en')
      }
    } catch (err) {
      console.log(err)
    }
  }
  document.getElementById('en-translate').onclick = () => {
    localStorage.setItem('language', 'en')
    i18n.translate()
  }
  document.getElementById('cn-translate').onclick = () => {
    localStorage.setItem('language', 'cn')
    i18n.translate()
  }
  document.getElementById('vn-translate').onclick = () => {
    localStorage.setItem('language', 'vn')
    i18n.translate()
  }
  document.getElementById('es-translate').onclick = () => {
    localStorage.setItem('language', 'es')
    i18n.translate()
  }
  document.getElementById('de-translate').onclick = () => {
    localStorage.setItem('language', 'de')
    i18n.translate()
  }
  document.getElementById('tr-translate').onclick = () => {
    localStorage.setItem('language', 'tr')
    i18n.translate()
  }
  document.getElementById('it-translate').onclick = () => {
    localStorage.setItem('language', 'it')
    i18n.translate()
  }
  document.getElementById('pt-translate').onclick = () => {
    localStorage.setItem('language', 'pt')
    i18n.translate()
  }
  document.getElementById('fr-translate').onclick = () => {
    localStorage.setItem('language', 'fr')
    i18n.translate()
  }
}

async function initConnect () {
  initConnect = () => { } // run only once
  await connect.init()
}

function clearLoadingScreen () {
  document.getElementById('loading-screen').classList.add('done')
}
