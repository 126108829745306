import { quoter } from './quoter'
import { Config } from './config'

import { bn, fw } from './bn.js'

const wbnb = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'

class Blp {
  static blps={}

  constructor (config) {
    Blp.blps[config.address] = this

    this.config = config

    this.tokens = {}
    // this.agoTokens = {}
    this.config.tokens.forEach((token, index) => {
      this.tokens[token] = {
        weight: this.config.weights ? bn(this.config.weights[index]) : bn(1e18)
      }
      // this.agoTokens[token] = {
      //   weight: bn(this.config.weights[index])
      // }
    })

    const abi = this.config.type === 'stable' ? Config.abis.stablePool : Config.abis.weightedPool
    this.contract = new this.config.web3.eth.Contract(abi, this.config.address)

    this.vaultContract = new this.config.web3.eth.Contract(Config.abis.bVault, this.config.vault)

    this.config.poller.add({
      target: this.config.address,
      method: () => this.contract.methods.totalSupply(),
      cb: (i, b) => {
        this.currentBlock = b
        this.totalSupply = bn(i)
      }
    })
    if (this.config.type === 'stable') {
      this.config.poller.add({
        target: this.config.address,
        method: () => this.contract.methods.getRate(),
        cb: i => { this.virtualPrice = bn(i) }
      })
    } else {
      this.config.poller.add({
        target: this.config.address,
        method: () => this.contract.methods.getInvariant(),
        cb: i => { this.invariant = bn(i) }
      })
    }
    this.config.poller.add({
      target: this.config.vault,
      method: () => this.vaultContract.methods.getPoolTokens(this.config.poolId),
      cb: i => {
        this.handleGetPoolTokens(i[0], i[1])
        this._updateApy()
      }
    })

    if (!this.config.fromBlock || this.config.fromBlock < this.config.agoPoller.options.blockNumber) {
      // this.config.agoPoller.add({
      //   target: this.config.vault,
      //   method: () => this.vaultContract.methods.getPoolTokens(this.config.poolId),
      //   cb: i => this.handleAgoGetPoolTokens(i[0], i[1])
      // })
      this.config.agoPoller.add({
        target: this.config.address,
        method: () => this.contract.methods.totalSupply(),
        cb: (i, b) => {
          this.agoBlock = b
          this.agoTotalSupply = bn(i)
        }
      })
      if (this.config.type === 'stable') {
        this.config.agoPoller.add({
          target: this.config.address,
          method: () => this.contract.methods.getRate(),
          cb: i => { this.agoVirtualPrice = bn(i) }
        })
      } else {
        this.config.agoPoller.add({
          target: this.config.address,
          method: () => this.contract.methods.getInvariant(),
          cb: i => { this.agoInvariant = bn(i) }
        })
      }
    }

    this._addQuotes()
  }

  _addQuotes () {
    const quotes = []
    this.config.tokens.forEach(token0 => {
      this.config.tokens.slice(1).forEach(token1 => {
        quotes.push({
          path: [token0, token1],
          quote: () => this.getSpotPrice(token0, token1),
          liquidity: () => this.getLiquidity(token0, token1),
          pools: [this]
        })
        quotes.push({
          path: [token1, token0],
          quote: () => this.getSpotPrice(token1, token0),
          liquidity: () => this.getLiquidity(token1, token0),
          pools: [this]
        })
      })
    })
    quoter.add([
      ...quotes,
      {
        path: [this.config.address, wbnb],
        quote: () => {
          const r = this._reservesBnb()
          return r && r.mul(bn(1e18)).div(this.totalSupply)
        },
        liquidity: () => this._reservesBnb(),
        pools: [this]
      }
    ])
  }

  handleGetPoolTokens (tokens, balances) {
    tokens.forEach((token, index) => {
      this.tokens[token].balance = bn(balances[index])
    })
  }

  _updateApy () {
    if (this.agoVirtualPrice) {
      const daysAgo = (this.currentBlock - this.agoBlock) / 28800
      this.roiDay = (parseFloat(fw(this.virtualPrice)) / parseFloat(fw(this.agoVirtualPrice))) ** (1 / daysAgo) - 1
      this.apyDay = (this.roiDay + 1) ** 365 - 1
      this.aprDay = this.roiDay * 365
    } else if (this.agoInvariant) {
      // console.log({
      //   invariant: parseFloat(fw(this.invariant))/(parseFloat(fw(this.totalSupply))**this.config.tokens.length),
      //   agoInvariant: parseFloat(fw(this.agoInvariant))/(parseFloat(fw(this.agoTotalSupply))**this.config.tokens.length)
      // })
      const calc = (invariant, supply) => {
        invariant = parseFloat(fw(invariant))
        supply = parseFloat(fw(supply))
        // return (invariant / supply) ** (1 / this.config.tokens.length)
        // no need to inverse exponent by number of tokens, or when dividing by supply either - balancer invariant is different from Uniswap's - sum of exponents (normalized weights) = 1
        return (invariant / supply)
      }
      const num = calc(this.invariant, this.totalSupply)
      const den = calc(this.agoInvariant, this.agoTotalSupply)
      const daysAgo = (this.currentBlock - this.agoBlock) / 28800
      this.roiDay = (num / den) ** (1 / daysAgo) - 1
      this.apyDay = (this.roiDay + 1) ** 365 - 1
      this.aprDay = this.roiDay * 365
      // console.log({ num, den, apy: this.apyDay })
    }
  }

  // handleAgoGetPoolTokens (tokens, balances) {
  //   tokens.forEach((token, index) => {
  //     this.agoTokens[token].balance = bn(balances[index])
  //   })
  // }

  _reservesBnb () {
    const _bnb = bn(0)
    for (const token in this.tokens) {
      const q = quoter.q(token, wbnb)
      if (!q) return
      _bnb.iadd(this.tokens[token].balance.mul(q).div(bn(1e18)))
    }
    return _bnb
  }

  getSpotPrice (tokenIn, tokenOut) {
    if (this.config.type === 'stable') { // TODO: HACK
      const decimalsIn = Config.tokens[tokenIn]?.decimals ?? 18
      const decimalsOut = Config.tokens[tokenOut]?.decimals ?? 18
      // console.log(decimalsIn, decimalsOut, fw(bn(1e18).mul(bn(10 ** decimalsOut)).div(bn(10 ** decimalsIn))))
      return bn(1e18).mul(bn(10 ** decimalsOut)).div(bn(10 ** decimalsIn))
    }

    return this.tokens[tokenOut].balance.mul(this.tokens[tokenIn].weight)
      .div(this.tokens[tokenIn].balance).mul(bn(1e18)).div(this.tokens[tokenOut].weight)
  }

  // liquidity in equivalent amount of tokenOut, in the smaller weight
  getLiquidity (tokenIn, tokenOut) {
    return this.tokens[tokenIn].weight.gt(this.tokens[tokenOut].weight) ? this.tokens[tokenOut].balance : this.tokens[tokenOut].balance.mul(this.tokens[tokenIn].weight).div(this.tokens[tokenOut].weight)
  }
}

export { Blp }
