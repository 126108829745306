'use strict'

import { Config } from './config.js'
import { quoter } from './quoter'

import { bn, min, fw, tw, twf, fwp, np } from './bn.js'

import { unrektApi } from './unrekt-api'

const maxuint = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

class VaultBase extends EventTarget {
  constructor (i, connect) {
    super()

    this.connect = connect

    for (const k in i) {
      this[k] = i[k]
    }
    this.stats = {}

    this.contract = new this.connect.web3.eth.Contract(Config.abis.vault, this.address)
    this.tokenContract = new this.connect.web3.eth.Contract(Config.abis.erc20, this.token)
    this.strategyContract = new this.connect.web3.eth.Contract(Config.abis.strategy, this.strategy)
  }
}

class Vault extends VaultBase {
  // static async init(connect) {
  //   let initBlockNumber = parseInt(await connect.web3.eth.getBlockNumber())
  //   this.agoPollerDays = 3
  //   this.agoPollerBlockNumber = initBlockNumber - this.agoPollerDays*28800
  //   this.agoPoller = new Poller(connect,{blockNumber: this.agoPollerBlockNumber})
  // }

  constructor (i, connect) {
    super(i, connect)

    this.connect.initPoller.add({
      target: this.address,
      method: () => this.contract.methods.balance(),
      cb: i => {
        if (bn(i).gtn(0)) { // only check this if vault is not empty
          this.stats.hasBalance = true
          this.connect.poller.add({
            target: this.address,
            method: () => this.contract.methods.getPricePerFullShare(),
            cb: (i, b) => {
              this.currentBlock = b
              this.stats.pricePerFullShare = bn(i)
            }
          })
        } else {
          this.stats.pricePerFullShare = bn(1)
        }
      }
    })
    this.connect.initPoller.addEventListener('poll', () => this.init(), { once: true })

    this.connect.poller.addEventListener('poll', () => this.update())
  }

  init () {
    this.connect.poller.add({
      target: this.address,
      method: () => this.contract.methods.balance(),
      cb: i => this.stats.vaultBalance = bn(i)
    })
    this.connect.poller.add({
      target: this.address,
      method: () => this.connect.account && this.contract.methods.balanceOf(this.connect.account),
      cb: i => this.stats.userBalanceVault = bn(i)
    })
    if (this.isBnbVault || this.isNativeVault) {
      this.connect.poller.add({
        target: this.connect.poller.multicall._address,
        method: () => this.connect.account && this.connect.poller.multicall.methods.getEthBalance(this.connect.account),
        cb: i => this.stats.userBalance = bn(i)
      })
    } else {
      this.connect.poller.add({
        target: this.token,
        method: () => this.connect.account && this.tokenContract.methods.balanceOf(this.connect.account),
        cb: i => this.stats.userBalance = bn(i)
      })
    }

    this.agoPoller = this.agoPollerShort ? this.connect.agoPollerShort : this.connect.agoPoller

    if ((!this.fromBlock || this.fromBlock < this.agoPoller.options.blockNumber) && (!this.firstDepositBlock || this.firstDepositBlock < this.agoPoller.options.blockNumber) && !this.deprecated && this.stats.hasBalance) {
      // console.log(this.tokenSymbol, this.agoPoller.options.blockNumber, this.fromBlock)
      this.agoPoller.add({
        target: this.address,
        method: () => this.contract.methods.getPricePerFullShare(),
        cb: (i, b) => {
          this.agoBlock = b
          this.stats.pricePerFullShareAgo = bn(i)
        }
      })
    }
  }

  async update () {
    // rationalize divide by 0
    if (this.stats.pricePerFullShare.lten(0) || this.stats.pricePerFullShare.gt(bn(1e18).pow(bn(2)))) this.stats.pricePerFullShare = bn(1e18)
    // if(this.stats.pricePerFullShareAgo.lten(0) || this.stats.pricePerFullShareAgo.gt(bn(1e18).pow(bn(2)))) this.stats.pricePerFullShareAgo = bn(1e18)
    // if(this.stats.pricePerFullShareWeekAgo.lten(0) || this.stats.pricePerFullShareWeekAgo.gt(this.stats.pricePerFullShare.mul(bn(1e18)))) this.stats.pricePerFullShareWeekAgo = bn(1e18)

    const tokenToUsd = quoter.q(this.token, Config.busd)
    const tokenToBnb = quoter.q(this.token, Config.wbnb)

    if (this.connect.account) {
      this.stats.userBalanceNormalized = this.applyTokenDecimals(this.stats.userBalance)
      this.stats.userBalanceVaultInToken = this.stats.userBalanceVault.mul(this.stats.pricePerFullShare).div(bn(1e18))
      this.stats.userBalanceVaultInTokenNormalized = this.applyTokenDecimals(this.stats.userBalanceVaultInToken)

      if (tokenToUsd) {
        this.stats.userBalanceUsd = this.stats.userBalance.mul(tokenToUsd).div(bn(1e18))
        this.stats.userBalanceVaultUsd = this.stats.userBalanceVaultInToken.mul(tokenToUsd).div(bn(1e18))
      }
    }
    if (tokenToBnb) {
      this.stats.vaultBalanceBnb = this.stats.vaultBalance.mul(tokenToBnb).div(bn(1e18))
    }
    if (tokenToUsd) {
      this.stats.vaultBalanceUsd = this.stats.vaultBalance.mul(tokenToUsd).div(bn(1e18))
    }

    const data = (await unrektApi).assets[56][this.address.toLowerCase()]
    if (data && data.apyvault != null) {
      this.stats.apyDay = parseFloat(data.apyvault) / 100
      this.stats.roiDay = (1 + this.stats.apyDay) ** (1 / 365) - 1
      this.stats.aprDay = this.stats.roiDay * 365
      // console.log(data.apyswap, this.stats, data)
    }

    // if (this.stats.pricePerFullShareAgo) {
    //   const daysAgo = (this.currentBlock - this.agoBlock) / 28800
    //   this.stats.roiDay = parseFloat(fw(this.stats.pricePerFullShare.mul(bn(1e18)).div(this.stats.pricePerFullShareAgo))) ** (1 / daysAgo) - 1
    // } else if (this.stats.pricePerFullShare && this.firstDepositBlock && this.currentBlock > this.firstDepositBlock + 8 * 60 * 60 / 3) {
    //   const daysAgo = (this.currentBlock - this.firstDepositBlock) / 28800
    //   this.stats.roiDay = parseFloat(fw(this.stats.pricePerFullShare)) ** (1 / daysAgo) - 1
    // } else {
    //   this.stats.roiDay = this.defaultRoiDay
    // }
    // this.stats.apyDay = (this.stats.roiDay + 1) ** 365 - 1
    // this.stats.aprDay = this.stats.roiDay * 365

    this.dispatchEvent(new CustomEvent('update'))
  }

  async deposit (amount) {
    let tx
    if (this.isNativeVault) {
      tx = this.connect.send(this.contract.methods.deposit(0), { value: amount })
    } else if (this.isBnbVault) {
      tx = this.connect.send(this.contract.methods.depositETH(), { value: amount })
    } else {
      const allowance = bn(await this.tokenContract.methods.allowance(this.connect.account, this.address).call())
      if (allowance.lt(amount)) {
        await this.connect.send(this.tokenContract.methods.approve(this.address, maxuint), {}, true)
      }
      tx = this.connect.send(this.contract.methods.deposit(amount))
    }
    return tx.then(receipt => {
      // ANALYTICS
      const data = {
        // affiliation: "Google online store",
        // coupon: "SUMMER_DISCOUNT",
        // currency: "USD",
        // shipping: 5.55,
        // tax: 3.33,
        transaction_id: receipt.transactionHash,
        // value: 28.86,
        items: [
          {
            // id: "P12346",
            name: this.tokenSymbol + ' Vault'
            // coupon: "P12346_coupon",
            // list_name: "Search Results",
            // brand: "MyBrand",
            // category: "Apparel/T-Shirts",
            // variant: "Red",
            // list_position: 5,
            // quantity: 1,
            // price: 9.99
          }
        ]
      }
      const tokenToUsd = quoter.q(this.token, Config.busd)
      if (tokenToUsd) {
        data.currency = 'USD'
        data.value = parseFloat(fw(amount.mul(tokenToUsd).div(bn(1e18))))
        data.items[0].quantity = parseFloat(fw(amount))
        data.items[0].price = parseFloat(fw(tokenToUsd))
      }
      // console.log(data)

      // google analytics
      gtag('event', 'purchase', data)

      // twitter conversion tracking
      // twq('track','Purchase', {
      //   //required parameters
      //   name: data.items[0].name,
      //   value: data.value,
      //   currency: 'USD',
      //   num_items: data.items[0].quantity,
      // });

      return receipt
    })
  }

  async withdrawInToken (amountInToken) {
    const amount =
      amountInToken.eq(this.stats.userBalanceVaultInToken)
        ? this.stats.userBalanceVault
        : amountInToken.mul(bn(1e18)).div(this.stats.pricePerFullShare)

    // console.log(amountInToken.eq(this.stats.userBalanceVaultInToken), amount)

    return this.withdraw(amount)
  }

  async withdraw (amount) {
    if (this.isNativeVault) {
      return this.connect.send(this.contract.methods.withdrawNative(amount))
    } else if (this.isBnbVault) {
      return this.connect.send(this.contract.methods.withdrawETH(amount))
    } else {
      return this.connect.send(this.contract.methods.withdraw(amount))
    }
  }

  applyTokenDecimals (amount) {
    if (!this.tokenDecimals) return amount
    const o = bn(amount).mul(bn(10 ** (18 - this.tokenDecimals)))
    return typeof amount === 'string' ? o.toString() : o
  }

  unapplyTokenDecimals (amount) {
    if (!this.tokenDecimals) return amount
    const o = bn(amount).div(bn(10 ** (18 - this.tokenDecimals)))
    return typeof amount === 'string' ? o.toString() : o
  }
}

class VaultWorker extends VaultBase {
  async update () {
    if (!this.connect.account) return

    console.log('updateVault', this.tokenSymbol)
    // console.log(this.tokenSymbol)
    await Promise.all([
      this.strategyContract.methods.harvest().call({ from: this.connect.account }).then(i => this.stats.harvestReward = bn(i)).catch(e => e),
      this.showBorrowLimit && this.strategyContract.methods.borrowLimit().call({ from: this.connect.account }).then(i => this.stats.borrowLimit = bn(i)).catch(e => e)
    ])

    this.dispatchEvent(new CustomEvent('update', this))
  }

  async harvest () {
    return this.connect.send(this.strategyContract.methods.harvest())
  }
}

export { Vault, VaultWorker }
